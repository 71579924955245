import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DataTrackingService {

  
  recordCount: number;

  constructor(private http: HttpClient) {
    this.recordCount = 0;
  }

  getFile(filterObj) {
    
    return this.http.post(environment.baseUrl + 'api/DataTracking/GetDataTrackingErrors', filterObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDataForGrid(filterObj) {
    
    /* let timer$ = timer(1, 60000)
      .pipe(switchMap(() => {
      }));
      return new Promise((resolve, reject) => {
        timer$.subscribe(res => resolve(res),
        err => reject(err));
      }); */
    // return timer(1, 15*60000)
      // .pipe(switchMap(() => {
        return this.http.post(environment.baseUrl + 'api/DataTracking/GetDataTrackingErrorsForGrid', filterObj)
      // }));
  }

  getEventIdDetails(eventId) {
    
    return this.http.get(environment.baseUrl + 'api/DataTracking/GetDataTrackingErrorsDetails/' + eventId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  lockRecords(eventObject) {
    
    return this.http.post(environment.baseUrl + 'api/DataTracking/LockRecord', eventObject)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  UnlockRecord(unlockObj) {
    let unlockRecordJson = {
      "EventIds": unlockObj
    }
    
    return this.http.post(environment.baseUrl + 'api/DataTracking/UnlockRecord', unlockRecordJson)
    .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  updateColumnId(postObject) {
    
    return this.http.post(environment.baseUrl + 'api/DataTracking/Update', postObject)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  updateEventId(recordData) {
    
    return this.http.post(environment.baseUrl + 'api/DataTracking/UpdateByID ', recordData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportDataToExcel(filterObj) {
    return this.http.post(environment.baseUrl + 'api/DataTracking/ExportToExcel', filterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDataFilterMenu(navFilter) {
    //let serviceUrl = environment.baseUrl;  environment.baseUrl + 'api/DataTracking/GetErrorCategoryDetails', filterObj
    return this.http.post(environment.baseUrl + 'api/DataTracking/GetErrorCategoryDetails', navFilter)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });

  }

  uploadFile(file) {
    let serviceUrl = environment.baseUrl + 'api/DataTracking/UploadFileForProcessing';

    return this.http.post(serviceUrl, file)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  deleteDataTrack(questionObj) {
    return this.http.post(environment.baseUrl + 'api/DataTracking/DeleteEventId', questionObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDropdownList(dataobj) {
    return this.http.post(environment.baseUrl + 'api/DataTracking/GetDropDownValues', dataobj).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      })
  }

  getModifiedColumn() {

    return this.http.get(environment.baseUrl + 'api/DataTracking/GetDataTrackingModifycolumnDetails/Datatracking').
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    }) 

  }

  saveModifiedColumn(selectedColumns){
    
    const data =  JSON.stringify (selectedColumns);
    return this.http.post(environment.baseUrl + 'api/DataTracking/SaveDataTrackingModifyColumnfilter', {Gridfilter: data,screen : 'Datatracking' }).
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    })
  }

  autoCorrectRecords(reqBody) {
    return this.http.post(environment.baseUrl + 'api/DataTracking/CorrectMarketSubSectorErrors', reqBody);
  }
}
