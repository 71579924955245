import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLSearchParams } from '@angular/http'
import { SharedService } from 'src/app/shared/services/shared.service';
import { LostbusinessCustomerService } from '../service/lostbusiness-customer.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { LbcDrilldownNavigation, LbcLostCntDrilldownNavigation, LbcWinCntDrilldownNavigation, TrendDrilldownNavigation } from '../model/lostBusiness-drilldownState.config.class';
import * as Rx from "rxjs";
import { LostBusinessStateModel } from '../model/lost-business-lbcdrilldown';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class LostBusinessDrilldownService {

  /** State navigation for drilldown 1 */
  public ddNav1 = new LbcDrilldownNavigation();
  public ddNav2 = new LbcLostCntDrilldownNavigation();
  public ddNav3 = new LbcWinCntDrilldownNavigation();
  public trendStateNav: TrendDrilldownNavigation = new TrendDrilldownNavigation();
  transCountSubject = new Rx.BehaviorSubject<any>(null);
  Total1stddNavSubject = new Rx.BehaviorSubject<any>(null);


  public CPStartDate: any;
  public CPEndDate: any;
  constructor(private http: HttpClient
    , private lostbusinessCustomerService: LostbusinessCustomerService
    , private filterService: FilterService) { }
  

  /**
   * Go to Next state of TotalCount Drilldown
   * @param data for relvant set
   */
  ddNav1Next(data: Object | number | string | boolean) {
    this.ddNav1.goToNextComp(data);
  }
  /**
   * Go to previous state of TotalCount Drilldown
   */
  ddNav1Prev(data: Object | number | string | boolean) {
    this.ddNav1.goToPrevComp(data);
  }
  /**
   * Get data for active state of TotalCount Drilldown
   */
  ddNav1GetData(): LostBusinessStateModel {
    return this.ddNav1.getDataActiveState();
  }
   /**
   * Reset active state of TotalCount Drilldown
   */
  ddNav1ResetStates() : LostBusinessStateModel {
    this.ddNav1 = new LbcDrilldownNavigation();
    return this.ddNav1.getDataActiveState();
  }


 /**
   * Go to Next state of LostCount Drilldown
   * @param data for relvant set
   */
  ddNav2Next(data: Object | number | string | boolean) {
    this.ddNav2.goToNextComp(data);
  }
  /**
   * Go to previous state of LostCount Drilldown
   */
  ddNav2Prev(data: Object | number | string | boolean) {
    this.ddNav2.goToPrevComp(data);
  }
  /**
   * Get data for active state of LostCount Drilldown
   */
  ddNav2GetData(): LostBusinessStateModel {
    return this.ddNav2.getDataActiveState();
  }
   /**
   * Reset active state of LostCount Drilldown
   */
  ddNav2ResetStates() : LostBusinessStateModel {
    this.ddNav2 = new LbcLostCntDrilldownNavigation();
    return this.ddNav2.getDataActiveState();
  }


/**
   * Go to Next state of WinCount Drilldown
   * @param data for relvant set
   */
  ddNav3Next(data: Object | number | string | boolean) {
    this.ddNav3.goToNextComp(data);
  }
  /**
   * Go to previous state of WinCount Drilldown
   */
  ddNav3Prev(data: Object | number | string | boolean) {
    this.ddNav3.goToPrevComp(data);
  }
  /**
   * Get data for active state of WinCount Drilldown
   */
  ddNav3GetData(): LostBusinessStateModel {
    return this.ddNav3.getDataActiveState();
  }
   /**
   * Reset active state of WinCount Drilldown
   */
  ddNav3ResetStates() : LostBusinessStateModel {
    this.ddNav3 = new LbcWinCntDrilldownNavigation();
    return this.ddNav3.getDataActiveState();
  }

  trendSetNextPath(isOnWinPath: boolean, data: Object | number | string | boolean) {
    this.trendResetStates();

    if (this.lostbusinessCustomerService.filterObj1['ViewedBy'] !== null && this.lostbusinessCustomerService.filterObj1['ViewedBy'].toLowerCase() === 'contact') {
      this.trendStateNav.baseView.Next = isOnWinPath ? 'contact_win' : 'contact_lose';
      console.log('contacts win/lose');
    } else if (this.lostbusinessCustomerService.filterObj1['ViewedBy'] === null || this.lostbusinessCustomerService.filterObj1['ViewedBy'].toLowerCase() === 'customer') {
      this.trendStateNav.baseView.Next = isOnWinPath ? 'win_1' : 'lost_1';
      console.log('customers win/lose');
    }
    this.trendNext(data);
  }
  /**
   * Go to Next state
   * @param data for relvant set
   */
  trendNext(data: Object | number | string | boolean): LostBusinessStateModel {
    return this.trendStateNav.goToNextComp(data);
  }
  /**
   * Go to previous state
   */
  trendPrev(data: Object | number | string | boolean): LostBusinessStateModel {
    return this.trendStateNav.goToPrevComp(data);
  }
  /**
   * Get data for active state
   */
  trendGetData(): LostBusinessStateModel {
    return this.trendStateNav.getDataActiveState();
  }
  trendResetStates() : LostBusinessStateModel {
    this.trendStateNav = new TrendDrilldownNavigation();
    return this.trendStateNav.getDataActiveState();
  }

  //Excel date 
  public getLBCExcelDate(){
    let lostFilterObjClone = _.cloneDeep(this.lostbusinessCustomerService.filterObj1);
    if (lostFilterObjClone.PeriodType != null && lostFilterObjClone.PeriodType != '') {
      // Change comparasion CompPeriodStartDate and CompPeriodEndDate
      this.calculateStartAndEndDate(lostFilterObjClone.PeriodType);
    } else {
      this.CPStartDate = lostFilterObjClone.CompPeriodStartDate;
      this.CPEndDate = lostFilterObjClone.CompPeriodEndDate;
    }
  }
    // for date period selection
    calculateStartAndEndDate(key) {
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = date;
  
      if (key == 'Q') {
        var quarter = Math.floor((date.getMonth() + 3) / 3);
        if (quarter == 1) {
          firstDay = new Date(y, 0, 1);
          lastDay = new Date(y, 2, 31);
        } else if (quarter == 2) {
          firstDay = new Date(y, 3, 1);
          lastDay = new Date(y, 5, 30);
        }
        else if (quarter == 3) {
          firstDay = new Date(y, 6, 1);
          lastDay = new Date(y, 8, 30);
        }
        else if (quarter == 4) {
          firstDay = new Date(y, 9, 1);
          lastDay = new Date(y, 11, 31);
        }
      }
      if (key == 'H') {
        var hy = Math.floor((date.getMonth() + 6) / 6);
        if (hy == 1) {
          var firstDay = new Date(y, 0, 1);
          var lastDay = new Date(y, 5, 30);
        }
        else if (hy == 2) {
          var firstDay = new Date(y, 6, 1);
          var lastDay = new Date(y, 11, 31);
        }
  
      }
      if (key == 'Y') {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 11, 31);
      }
      this.CPStartDate = this.changeDateFormate(firstDay);
      this.CPEndDate = this.changeDateFormate(lastDay);
    }
  
    public changeDateFormate(date) {
      // Try and parse the passed value.
      var momentDate = moment(date);
      return momentDate.format('DD-MMM-YYYY');
    }

  getTotalTransactionList(pageNo, pageSize, sortField, sortType,searchString, searchBy) {
    var lostFilterObj = {
      'StartDate': null,
      'EndDate': null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/Getlbctotaltransdrilldown?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType +"&SearchString=" + encodeURIComponent(searchString) + "&SearchBy=" + searchBy, lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getTotalDispositionList(pageNo, pageSize, sortField, sortType, accountNo, customerName,FAMID,BAID) {
    var lostFilterObj = {
      'StartDate': null,
      'EndDate': null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
      return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetlbcTotalCustomerdisposition?PageNo=" + 1 + "&PageSize=" + 50 + "&SortField=" + null + "&SortType=" + null + "&AccountNumber=" + accountNo + "&CustomerName=" + encodeURIComponent(customerName) + "&FAMID=" +FAMID + "&BAID=" + BAID, lostFilterObj)
        .toPromise()
        .then((res: any) => {
          return res;
        });
      }

  getTotalCustomerList(pageNo, pageSize, sortField, sortType, accountNumber, customerName, disposition, searchString, searchBy,FAMID,BAID) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetlbcTotalCustomerlist?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType + "&AccountNumber=" + accountNumber + "&CustomerName=" + encodeURIComponent(customerName) + "&Disposition=" + disposition + "&SearchString=" + encodeURIComponent(searchString) + "&SearchBy=" + searchBy + "&FAMID=" + FAMID + "&BAID=" + BAID, lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }

  getlostCustomerList(pageNo, pageSize, sortField, sortType, flag) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    if (this.lostbusinessCustomerService.filterObj1.ViewedBy == 'Contact') {
      return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetWinLostContactlist?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType + "&flag=" + 'l', lostFilterObj)
        .toPromise()
        .then((res: any) => {
          return res;
        });
    }
    else {
      return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetWinLostCustomerlist?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType + "&flag=" + 'l', lostFilterObj)
        .toPromise()
        .then((res: any) => {
          return res;
        });
    }
  }

  getWinCustomerList(queryParams: object, isDrilldown: boolean) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    //let qParams = new URLSearchParams();
    let qParams ='';
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
      //  qParams.set(key, queryParams[key]);
      if(key=='SearchString'){
        qParams= qParams + key+'='+encodeURIComponent(queryParams[key])+'&';
      }else{
        qParams= qParams + key+'='+queryParams[key]+'&';
      }
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/LostCustomer/TrendChart/GetLBCustWinDrilldown?" : environment.baseUrlDashboard + "/Dashboard/GetWinCustomerDrilldown1?";
      return this.http.post(uri + qParams, lostFilterObj)
        .toPromise()
        .then((res: any) => {
          return res;
        });
  }
  exportData(pageNo, pageSize, sortField, sortType, accountNumber, customerName, disposition, searchString, searchBy,FAMID,FAMCode,flag,BAID,compSTdate,compEDdate) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/TotalcustListExport?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType + "&AccountNumber=" + accountNumber + "&CustomerName=" + encodeURIComponent(customerName) + "&Disposition=" + disposition + "&SearchString=" + encodeURIComponent(searchString) + "&SearchBy=" + searchBy + "&FAMID=" +FAMID + "&FAMCode=" + FAMCode +"&flag="+ flag +"&BAID=" +BAID + "&compSTdate=" + compSTdate +"&compEDdate=" + compEDdate, lostFilterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }
  getLostcustomerd1List(queryParams: object, isDrilldown: boolean) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
   // let qParams = new URLSearchParams();
    let qParams='';
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        //qParams.set(key, queryParams[key]);
        if(key=='SearchString'){
          qParams= qParams + key+'='+encodeURIComponent(queryParams[key])+'&';
        }else{
          qParams= qParams + key+'='+queryParams[key]+'&';
        }
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/LostCustomer/TrendChart/GetLBCustLossDrilldown?" : environment.baseUrlDashboard + "/Dashboard/Getlbclostdrilldown1?";
    return this.http.post(uri + qParams, lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getLostcustomerd2List(queryParams: object, isDrilldown){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    // let qParams = new URLSearchParams();
    let qParams = '';
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        //qParams.set(key, queryParams[key]);
        if(key=='SearchString' || key == 'CustomerName'){
          qParams= qParams + key+'='+encodeURIComponent(queryParams[key])+'&';
        }else{
          qParams= qParams + key+'='+queryParams[key]+'&';
        }
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/LostCustomer/TrendChart/GetLBTrendChartCustLostDrilldown" : environment.baseUrlDashboard + "/Dashboard/Getlbclostdrilldown2";
    return this.http.post(uri + "?" + qParams, lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  exportlostData(queryParams: object, isDrilldown: boolean){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
   // let qParams = new URLSearchParams();
   let qParams = '';
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        //qParams.set(key, queryParams[key]);
        if(key=='SearchString' || key == 'CustomerName'){
          qParams= qParams + key+'='+encodeURIComponent(queryParams[key])+'&';
        }else{
          qParams= qParams + key+'='+queryParams[key]+'&';
        }
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/ExportTrendChartCustLossDrillDown?" : environment.baseUrlDashboard + "/Dashboard/LostcustListExport?";
    return this.http.post(uri + qParams, lostFilterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }
  getWincustomerd2List(queryParams: object, isDrilldown: boolean){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
   // let qParams = new URLSearchParams();
   let qParams ='';
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
       // qParams.set(key, queryParams[key]);
       if(key=='SearchString' || key == 'CustomerName'){
        qParams= qParams + key+'='+encodeURIComponent(queryParams[key])+'&';
      }else{
        qParams= qParams + key+'='+queryParams[key]+'&';
      }
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/LostCustomer/TrendChart/GetLBTrendChartCustWinDrilldown" : environment.baseUrlDashboard + "/Dashboard/GetWinCustomerDrilldown2";
    return this.http.post(uri + "?" + qParams, lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  exportWinData(queryParams: object, isDrilldown: boolean){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    //let qParams = new URLSearchParams();
    let qParams ='';
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
       // qParams.set(key, queryParams[key]);
       if(key=='SearchString' || key == 'CustomerName'){
        qParams= qParams + key+'='+encodeURIComponent(queryParams[key])+'&';
      }else{
        qParams= qParams + key+'='+queryParams[key]+'&';
      }
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/ExportTrendChartCustWinDrillDown?" : environment.baseUrlDashboard + "/Dashboard/WincustListExport?";
    return this.http.post(uri + qParams, lostFilterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }

  getTotalContactList(pageNo, pageSize, sortField, sortType,searchString, searchBy) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetTotalContactlistdrilldown?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType +"&SearchString=" + searchString + "&SearchBy=" + searchBy, lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  exportTotalContactData(pageNo,pageSize,sortField,sortType,flag, searchString,searchBy,compSTdate,compEDdate){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/TotalContactExcel?PageNo=" + pageNo + "&PageSize=" + pageSize + "&SortField=" + sortField + "&SortType=" + sortType +"&flag=" + flag +"&SearchString=" + searchString + "&SearchBy=" + searchBy+ "&compSTdate=" + compSTdate +"&compEDdate=" + compEDdate, lostFilterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }
  exportLostcntContactData(queryParams: object, isDrilldown: boolean){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    let qParams = new URLSearchParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        qParams.set(key, queryParams[key]);
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/ExportTrendChartContLossDrillDown?" : environment.baseUrlDashboard + "/Dashboard/LostcntContactExcel?";
    return this.http.post(uri + qParams.toString(), lostFilterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }
  getLostcntContactList(queryParams: object, isDrilldown: boolean) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    let qParams = new URLSearchParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        qParams.set(key, queryParams[key]);
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/LostCustomer/TrendChart/GetLBTrendChartContLossDrilldown?" : environment.baseUrlDashboard + "/Dashboard/GetLostcntContactlistdrilldown?";
    return this.http.post(uri + qParams.toString(), lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  exportWincntContactData(queryParams: object, isDrilldown: boolean){
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    }
    let qParams = new URLSearchParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        qParams.set(key, queryParams[key]);
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/ExportTrendChartContWinDrillDown?" : environment.baseUrlDashboard + "/Dashboard/WincontactListExport?";
    return this.http.post(uri + qParams.toString(), lostFilterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res;
      });

  }
  getWincntContactList(queryParams: object, isDrilldown: boolean) {
    var lostFilterObj = {
      'StartDate':null,
      'EndDate':null,
      'PeriodType': this.lostbusinessCustomerService.filterObj1.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': this.lostbusinessCustomerService.filterObj1.startBasePeriod,
      'endBasePeriod': this.lostbusinessCustomerService.filterObj1.endBasePeriod,
      'CompPeriodStartDate': this.lostbusinessCustomerService.filterObj1.CompPeriodStartDate,
      'CompPeriodEndDate': this.lostbusinessCustomerService.filterObj1.CompPeriodEndDate,
      'FilterBy': this.lostbusinessCustomerService.filterObj1.FilterBy,
      'FilterString': this.lostbusinessCustomerService.filterObj1.FilterString,
      'AccountNo': this.lostbusinessCustomerService.filterObj1.AccountNo,
      'ViewedBy': this.lostbusinessCustomerService.filterObj1.ViewedBy,
      'ProjectManager': this.filterService.filterObj.ProjectManager,
    };
    let qParams = new URLSearchParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        qParams.set(key, queryParams[key]);
      }
    }
    let uri = isDrilldown ? environment.baseUrlDashboard + "/Dashboard/LostCustomer/TrendChart/GetLBTrendChartContWinDrilldown?" : environment.baseUrlDashboard + "/Dashboard/GetWincntContactlistdrilldown?";
    return this.http.post(uri + qParams.toString(), lostFilterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
}
