import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CtlsRuleSetService {
  toggleRulePopover = new BehaviorSubject(false);

  filterObjForTransactional = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: false,
    BLCode: false,
    Country: true,
    BrandName: false,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'CTLS_RULESET_LISTING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_TRANS_RULESET_LISTING',
    savedFilterSessionStorageName: 'CTLS_TRANS_RULESET_LISTING_SAVED_FILTER',
    CustomerName: false,
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'Status',
      'NextStep',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'IsFirstSave',
      'SurveyResponseID',
      'InfluenceLevel',
    ],
  };
  filterObjForRelational = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: false,
    BLCode: false,
    Country: true,
    BrandName: false,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_RULESET_LISTING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_RULESET_LISTING',
    savedFilterSessionStorageName: 'CTLS_DIST_RULESET_LISTING_SAVED_FILTER',
    CustomerName: false,
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    //selceted field to search => remove fields form the filterObjForGrid, Then only display in grig select box
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'Status',
      'NextStep',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'IsFirstSave',
      'InfluenceLevel',
      'DistributorSegment',
    ],
  };

  constructor(private http: HttpClient, private sharedService: SharedService) { }



  /**Service to get Transactional Rule list
   * @param objFilter: objFilter
  */
  GetRuleList(value, objFilter): Observable<any> {
    if (value == 1) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/RuleSetup/GetTransactionalRules`,
        objFilter
      );
    } else if (value == 2) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/RuleSetup/GetRelationshipRules`,
        objFilter
      );
    }

  }



  /**Service to get Transactional Rule list
   * @param objFilter: objFilter
  */
  GetRuleSetList(value, objFilter): Observable<any> {
    if (value == 1) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/RuleSet/GetTransactionalRuleSet`,
        objFilter
      );
    } else if (value == 2) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/RuleSet/GetRelationshipRuleSet`,
        objFilter
      );
    }

  }


  /**Service for get modified columns
  * @param dataObj
  */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }

  /**Service for save  modified columns
  * @param dataObj
  */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }


  /**
* Delete Rule from Rule Listing page
* @param cTLSRuleIDs: Delete Rule Array
* @returns: Observable
*/
  deleteCTLSRule(cTLSRuleIDs): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/RuleSetup/DeleteRuleDetails`,
      cTLSRuleIDs
    );
  }


  /**
* Delete Rule from Rule Listing page
* @param RuleSetIDs: Delete Rule Array
* @returns: Observable
*/
  deleteCTLSRuleSet(RuleSetIDs): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/RuleSet/DeleteRuleSet`,
      RuleSetIDs
    );
  }


  /**Service for saving status change
  * @param dataObj
  */
  saveRuleStatus(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/RuleSetup/SetRuleStatus`,
      {
        params: {
          CTLSRuleID: dataObj.CTLSRuleID,
          status: dataObj.status,
        },
      }
    );
  }

  /**Service for saving status change
 * @param dataObj
 */
  activateRuleSet(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/RuleSet/ActivateRuleSet?RuleSetID=${dataObj.RuleSetID}&IsEnable=${dataObj.IsEnable}`,
      null
    );
  }



  /**Service for fetching BA list
  * @param SurveyIDs
  */
  getBADetails(SurveyIDs): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/RuleSet/GetBADetails`,
      {
        params: {
          SurveyIDs: SurveyIDs,
        }
      }
    );
  }


  /**Service for fetching FAM list
  * @param BAIDs
  */
  getFAMDetails(BAIDs,surveyIDs?): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/RuleSet/GetFAMDetails`,
      {
        params: {
          BAIDs: BAIDs,
          surveyIDs:surveyIDs,
        },
      }
    );
  }


  /**Service for fetching Division list
  * @param reqObj
  */
  getDivisions(reqObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/RuleSet/GetDivisions`,
      reqObj
    );
  }

  /**Service for saving status change
 * @param dataObj
 */
  getRuleCriteriaPopup(CTLSRuleID): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/RuleSetup/GetRuleCriteriaPopup`,
      {
        params: {
          CTLSRuleID: CTLSRuleID
        },
      }
    );
  }

  /**Service for saving mapping
    * @param reqObj
    */
  saveFAMBAMapping(reqObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/RuleSetup/SaveFAMBAMapping`,
      reqObj
    );
  }



  /**
 * set
 * @param flag
 */
  setOnClickOfRule(flag: boolean) {
    this.toggleRulePopover.next(flag);
  }

  /**
   * get current popover status
   */
  getRulePopoverStatus() {
    // var data = false;
    // this.toggleRulePopover.subscribe((value) => {
    //   data = value;
    // });
    return this.toggleRulePopover.getValue();
  }

  


}
