import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService {
  
  private readonly featureUrl: string = 'api/UserFeedback/';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService) { }

  public saveUserFeedback = (param: any): Observable<APIResponses<any>> => {
    return this.http.post<APIResponses<any>>(environment.baseUrl + this.featureUrl + 'Save', param);
  }

}

interface APIResponses<T> {
  Errors: any[];
  Response: T;
  Status: string;
}
