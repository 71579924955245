import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsSurveyConfigurationService {
  filterObjForTransactional = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: true,
    Influencer: false,
    ServiceTechnician: false,
    Continent: true,
    Region: true,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'CTLS_SURVEY_CONFIGURATION', //SELECTED SCREEN
    sessionStorageName: 'CTLS_TRANS_SURVEY_CONFIGURATION',
    savedFilterSessionStorageName: 'CTLS_TRANS_SURVEY_CONFIGURATION_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Status',
    ],
  };
  filterObjForRelational = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: true,
    ServiceTechnician: false,
    Continent: true,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_SURVEY_CONFIGURATION', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_SURVEY_CONFIGURATION',
    savedFilterSessionStorageName: 'CTLS_DIST_SURVEY_CONFIGURATION_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'SurveyID',
      'IsFirstSave',
      'Status',
    ],
  };
  recordCount: number;
  /** Form wizard state */
  surveyID: number = 0;
  markforsurvey: string = '';

  instanceAccordionStatus: any = {};

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.recordCount = 0;
  }

  deleteSurvey(surveyID) {
    return this.http
      .post(
        environment.baseUrl + 'api/RelationshipSurveys/' + surveyID + '/Delete',
        {}
      )
      .toPromise()
      .then((res: any) => res)
      .then((data) => {
        return data;
      });
  }

  pinUnpin(SurveyId, IsPinned) {
    if (IsPinned) {
      return this.http
        .post(
          environment.baseUrl +
          'api/RelationshipSurveys/' +
          SurveyId +
          '/PinSurvey',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    } else {
      return this.http
        .post(
          environment.baseUrl +
          'api/RelationshipSurveys/' +
          SurveyId +
          '/unPinSurvey',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    }
  }

  /** Helpers */
  public getDateinPreferredFormat = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  /**Service for activate and deactivate the survey
   * @param SurveyId
   * @param IsEnable
   */

  activateSurvey(SurveyId, IsEnable) {
    if (IsEnable) {
      return this.http
        .post(
          environment.baseUrl + 'api/RelationshipSurveys/' + SurveyId + '/Disable',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    } else {
      return this.http
        .post(
          environment.baseUrl + 'api/RelationshipSurveys/' + SurveyId + '/Enable',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    }
  }

  /**Service for load grid data in ctls configuration
   * @param filterObj
   * @param value
   */

  getSurveyConfiguration(value, filterObj): Observable<any> {
    if (value === 1) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/CTLSConfiguration/GetAllCTLSConfigurationTransactional`,
        filterObj
      );
    } else if (value === 2) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/CTLSConfiguration/GetAllCTLSConfigurationRelationship`,
        filterObj
      );
    }
  }

  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get  modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }

    /**Service for get  modified columns
   * @param dataObj
   */
     GetModifyColumnsNonSubCat(dataObj) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
        {
          params: {
            GridName: dataObj.GridName,
          },
        }
      );
    }

  /**Service for save  status
   * @param dataObj
   */
  SaveStatus(dataObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/CTLSConfiguration/SaveCTLSStatus`,
      dataObj
    );
  }

  /**
   * Get All Comments => to display on comment trails
   * @param dataObj
   */
  GetAllComments(dataObj): Observable<any> {
    return this.http.post(
      `${environment.baseUrlCTLS}/CTLSConfiguration/GetCommentHistory`,
      dataObj
    );
  }
}
