import { Injectable } from "@angular/core";



import { Subject } from "rxjs";


import { SessionStorageService } from "./components/surveys/services/session-storage.service";
import { SurveyService } from "./components/surveys/services/survey.service";
@Injectable({
  providedIn: "root"
})
export class PermissionsService {
  
  public permissionSubject = new Subject();

  constructor(
    private sessionStorageService: SessionStorageService,
    private surveyService: SurveyService
  ) { }

  getPermissionValue(permissionName: string) {
    // only for testing done commented code.
    // if (permissionName == 'VIEW_DASHBOARD' || permissionName == 'VIEW_FILE_TRACKING_DASHBOARD'
    //   || permissionName == ''
    //   || permissionName == 'VIEW_SURVEY_TEMPLATES1') {
    //   return false;
    // }
    const permissions = this.sessionStorageService.getSessionData('Permissions');
    if (this.CheckPermission(permissions, permissionName)) {
      return true;
    } else {
      return false;
    }
  }

  CheckUserPermissionByKey(permissionName) :Promise<boolean>{
    return new Promise((resolve, reject) => {

      let permissions = this.sessionStorageService.getSessionData('Permissions');
      if (!permissions) {
        this.surveyService
          .UserAccessGetDetails()
          .then(rawData => {
            let rawResponse: any = rawData;
            if (rawResponse.Status === "Success") {
              this.sessionStorageService.setSessionData('Permissions', rawData.Response[0].Permissions);
              permissions = rawData.Response[0].Permissions;
              if (
                this.CheckPermission(
                  permissions,
                  permissionName
                )
              )
                return resolve(true);
              else return reject(false);
            } else return reject(false);
          })
          .catch(e => {
            return reject(false);
          });
      } else {
        if (this.CheckPermission(permissions, permissionName))
          return resolve(true);
        else return reject(false);
      }
    });
  }
  CheckUserPermissionByMultipleKeys(permissionNames) :Promise<boolean>{
    return new Promise((resolve, reject) => {

      let permissions = this.sessionStorageService.getSessionData('Permissions');
      var allPs = permissionNames.split(",");
      if (!permissions) {
        this.surveyService
          .UserAccessGetDetails()
          .then(rawData => {
            let rawResponse: any = rawData;
            if (rawResponse.Status === "Success") {
              this.sessionStorageService.setSessionData('Permissions', rawData.Response[0].Permissions);
              permissions = rawData.Response[0].Permissions;
              for (var i = 0; i < allPs.length; i++) {
                if (
                  this.CheckPermission(
                    permissions,
                    allPs[i]
                  )
                )
                  return resolve(true);
                else continue;
              }
              return resolve(false);
            } else return reject(false);
          })
          .catch(e => {
            return reject(false);
          });
      }
      else {
        for (var i = 0; i < allPs.length; i++) {
          if (this.CheckPermission(permissions, allPs[i]))
            return resolve(true);
          else continue;
        }
        return reject(false);
      }
    });
  }

  CheckMultiplePermissionByMultipleKeys(permissionNames) {

    return new Promise((resolve, reject) => {
      let permissions = this.sessionStorageService.getSessionData('Permissions');
      var allPs = permissionNames.split(",");

      if (!permissions) {
        this.surveyService
          .UserAccessGetDetails()
          .then(rawData => {
            permissions = rawData.Response[0].Permissions
            this.sessionStorageService.setSessionData('Permissions', permissions);
            let methodResponse = {};
            this.permissionSubject.next({
              GetDetails: [ ...permissions ]
            });
            for (var i = 0; i < allPs.length; i++) {
              if (
                this.CheckPermission(
                  permissions,
                  allPs[i]
                )
              ) {
                methodResponse[allPs[i]] = true;
              } else {
                methodResponse[allPs[i]] = false;
              }
            }
            return resolve(methodResponse);
          })
          .catch(e => { return reject({}) });
      }
      else {
        let methodResponse = {};
        for (var i = 0; i < allPs.length; i++) {
          if (this.CheckPermission(permissions, allPs[i])) {
            methodResponse[allPs[i]] = true;
          } else {
            methodResponse[allPs[i]] = false;
          }
        }
        return resolve(methodResponse);
      }
    });
  }
  CheckPermission(permissions, permissionName) {
    //let permissions = JSON.parse(window.localStorage.getItem("PermissionsObj")) || {};
    if(permissionName != 'CTLS_CUSTOMER_360' && permissionName != 'CTLS_DISTRIBUTER_360' && permissionName != 'CTLS_DISTRIBUTOR_360' && permissionName != 'CTLS_360')
      // return (
      //   permissions[permissionName] || permissions["FULL_ACCESS"]
      // );
      return permissions.includes("FULL_ACCESS")||permissions.includes(permissionName);
    else
      // return (
      //   permissions[permissionName]
      // );
      return permissions.includes(permissionName);
  }
  UserPermissionsDetails() {
    return new Promise((resolve, reject) => {
      let permissions = this.sessionStorageService.getSessionData('Permissions');
      if (!permissions) {
        this.surveyService.UserAccessGetDetails().then(rawData => {
          if (rawData.Status == "Success") {
            if (rawData.Response[0].Permissions.length > 0) {
              this.sessionStorageService.setSessionData('Permissions', rawData.Response[0].Permissions);
              resolve(permissions);
            }
          }
        }).catch(e => {
          reject(e);
        });
      } else {
        resolve(permissions);
      }
    });
  }
}
