import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CtlsDashboardFilterService {
  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  categoryOriginal = [];
  OpCountryContiRegionOriginal = [];
  brandOriginal = [];
  engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  LoyalityShift = [];
  customerNameOriginal = []; //11
  customerAccountNumberOriginal = [];
  distributorsOriginal = []; //33
  customerOriginal = [];
  distributorSegmentOriginal = []; //44
  distributorAccountNumberOriginal = []; //55
  industrySegmentOriginal = [];
  satisfactionQuestionsDataOriginal = [];
  satisfactionQuestionsAnsDataOriginal = [];
  CurrencyDataOriginal = [];
  TaskCategoryDetailsOriginal = [];

  resetGlobalFilterData() {
    this.dataOriginal = [];
    this.zoneBranchOriginal = [];
    this.countryContinentOriginal = [];
    this.categoryOriginal = [];
    this.OpCountryContiRegionOriginal = [];
    this.brandOriginal = [];
    this.engineerAndTechnicianOriginal = [];
    this.touchPointsOriginal = [];
    this.influencersOriginal = [];
    this.customerNameOriginal = []; //11
    this.customerAccountNumberOriginal = [];
    this.distributorsOriginal = []; //33
    this.customerOriginal = [];
    this.distributorSegmentOriginal = []; //44
    this.distributorAccountNumberOriginal = []; //55
    this.TaskCategoryDetailsOriginal = [];
  }

  constructor(
    private http: HttpClient,
  ) { }

  // saveUserFilter(filterObject) {
  //   console.log('filterObject in service', filterObject)
  //   return of({ Response: "Success" })
  // }

  saveUserFilter(filterObject) {
    filterObject.FilterFields.Count = filterObject.FilterFields.Count == "" ? 0 : filterObject.FilterFields.Count;
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/CTLSDashboard/SaveCTLSDashboardScenario`, filterObject);
  }

  updateUserFilter(filterObject) {
    filterObject.FilterFields.Count = filterObject.FilterFields.Count == "" ? 0 : filterObject.FilterFields.Count;
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/CTLSDashboard/UpdateCTLSDashboardScenario`, filterObject);
  }

  deleteUserFilter(filterObject) {
    filterObject.FilterFields.Count = filterObject.FilterFields.Count == "" ? 0 : filterObject.FilterFields.Count;
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/CTLSDashboard/DeleteCTLSDashboardScenario`, filterObject);
  }

  reorderScenario(filterObject) {
    filterObject.FilterFields.Count = filterObject.FilterFields.Count == "" ? 0 : filterObject.FilterFields.Count;
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/CTLSDashboard/ReorderCTLSDashboardScenario`, filterObject);
  }

  saveCTLSFilter(filterObject) {
    return this.http
      .post(
        environment.baseUrlCTLS + `/CTLSDashboard/SaveCTLSDateFilter`,
        filterObject
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getCTLSFilter(): Observable<any> {
    return this.http.get<any>(
      environment.baseUrlCTLS +
      `/CTLSDashboard/GetCTLSDateFilter`
    );
  }
}
