import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CurrencyMasterService {

  constructor(private httpClient: HttpClient) { }

  uploadFile(file) {
    let serviceUrl = environment.baseUrlMDM + '/CurrencyMaster/UploadFileForProcessingForCurrencyMaster';

    return this.httpClient.post(serviceUrl, file)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  downloadTemplate(filepath) {
    filepath = 'CurrencyMaster-Add.xlsx';
    return this.httpClient.get(environment.baseUrlMDM + '/CurrencyMaster/DownloadCurrencyMasterTemplateFileFromBlob/' + filepath, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getGridData(parameters) {
    return this.httpClient.post(environment.baseUrlMDM + '/CurrencyMaster/GetCurrencyMasterList',parameters)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportDataToExcel(parameters) {
    return this.httpClient.post(environment.baseUrlMDM + '/CurrencyMaster/GetCurrencyMasterListForExport', parameters, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => {
        return res
      })
      .then(data => {
        return data;
      });
  }
}
