import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'primeng/api';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  recordCount: number;

  constructor(private http: HttpClient, public dialogService: DialogService) { }

  getTemplateList(filterObj) {

    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/GetAllSurveyTemplates', filterObj)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });

  }
  getAllTemplatesWithCategory(templateCategoryId, languageId, currentTemplateId) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetTemplateNameByCategory/' + templateCategoryId + "/" + languageId + "/" + currentTemplateId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getCategoryList() {
    return this.http.get(environment.baseUrlMDM + '/MDM/Templates/Categories')
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getChannelList() {

    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/Channels')
       .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getTypeList() {
    return this.http.get(environment.baseUrlMDM + '/MDM/Templates/Types')
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getLanguageList() {
    return this.http.get(environment.baseUrlMDM + '/MDM/Languages')
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }
  getTouchPointList() {
    return this.http.get(environment.baseUrlMDM + '/MDM/TouchPoints')
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  createTemplate(templateObj) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/AddSurveyTemplate', templateObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  updateTemplate(templateObj) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/UpdateSurveyTemplate', templateObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getTemplateDtaById(templateId) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetSurveyTemplateById/' + templateId + "?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getAllQuestionsFromQuestionBank(qCategory, qText, lId) {
    var qutext = encodeURIComponent(qText);
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/PickQuestionFromQuestionBank/' + qCategory + '/' + qutext + '/' + lId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }


  getAllQuestionsFromTemplates(qCategory, tId, qText, lId, ctId) {
    var qutext = encodeURIComponent(qText);
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/PickQuestionFromTemplate/' + qCategory + '/' + tId + '/' + qutext + '/' + lId + '/' + ctId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getQuestionCategoryList() {
    return this.http.get(environment.baseUrlMDM + '/MDM/Questions/Categories')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }


  createSurvey(surveyObject) {
    return this.http.post(environment.baseUrl + 'api/Survey/AddSurvey', surveyObject)
      .toPromise()
      .then((res: any) => { return res });
  }

  savequestionsToTemplate(questionListObj) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/AddQuestionsForSurveyTemplate', questionListObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  savequestionsToTemplateAsDraft(questionListObj) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/ReOrderQuestionsForSurveyTemplate', questionListObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  publishTemplate(questionListObj) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/PublishSurveyTemplate', questionListObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  GetTemplateTranslatedLanguages(TemplateId) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetTemplateTranslatedLanguages/' + TemplateId)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  GetTemplateDetailsInApprovedLanguage(TemplateId, LanguageId) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetTemplateDetailsInApprovedLanguage/' + TemplateId + "/" + LanguageId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  UpdateUserPreferenceCreateTemplate(isSkip) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/UpdateUserPreferenceCreateTemplate/' + isSkip)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  UserAccessGetDetails() {
    return this.http.get(environment.baseUrlMDM + '/UserAccess/GetDetails')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  GetTemplateTranslation(TemplateId) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetTemplateTranslation/' + TemplateId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  deleteQuestionFromTemplate(questionObj) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/DeleteQuestionsFromSurveyTemplate', questionObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  GetImpactedFAMList(templateId) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetImpactedFAMOnEditTemplate/' + templateId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  openModalPopup(componentName) {
    const ref = this.dialogService.open(componentName, {
      // data: {
      //   id: '51gF3'
      // },s
      header: 'Survey Template Details',
      width: '70%',
      height: '70%'
    });
  }

  // for signature grid
  getSignatureList(urlParams) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetSignatures' + urlParams + '&buster=' + new Date().getTime() )
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  // For Getting all data for survey

  getAllFamdata() {
    return this.http.get(environment.baseUrlMDM + '/MDM/BusinessAreas')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getTouchpoints() {
    return this.http.get(environment.baseUrlMDM + '/MDM/TouchPoints')
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  getSurveyDetailsById(surveyid) {
    return this.http.get(environment.baseUrl + 'api/survey/GetSurveyById/' + surveyid)
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  // setStorage(url){
  //   var storedObj = localStorage.getItem('urlData');
  //   if(storedObj){
  //     var jsonData = JSON.parse(storedObj);
  //     jsonData.push(url);
  //     localStorage.setItem('urlData', JSON.stringify(jsonData));
  //   }else{
  //     var temp = [];
  //     temp.push(url);
  //     localStorage.setItem('urlData', JSON.stringify(temp));
  //   }
  // }


  getSignatureDetail(urlParams) {
    return this.http.get(environment.baseUrl + 'api/SurveyTemplate/GetSignatureByID' + urlParams + '/' + new Date().getTime() )
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  // to save signature details
  saveSignatureDetail(objSignatureModel) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/SaveSignature?buster=' + new Date().getTime(), objSignatureModel)
      .toPromise()
      .then((res: any) => res);
  }

  // to submit signature details
  submitSignature(objSignatureModel) {
    return this.http.post(environment.baseUrl + 'api/SurveyTemplate/PostSubmitSignature?buster=' + new Date().getTime(), objSignatureModel)
      .toPromise()
      .then((res: any) => res);
  }

}
