import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerFeedbackService {
  private serviceUrl = environment.baseUrl + 'api/CustomerFeedback/';
  private apiUrl: string;
  constructor(private http: HttpClient, private sharedService: SharedService) { }

  // For Survey Type
  getSurveyType() {
    return this.http.get(environment.baseUrl + 'GetCustomerFeedbackSurveyType')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDispositionStatus() {
    return this.http.get(environment.baseUrl + 'GetCustomerFeedbackDisposition')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getCustomerFeedbackSummary(data) {
    if (data.DispositionStatus.length > 0) {
      data.DispositionStatus = data.DispositionStatus.map(e => e.value).join(',');
    } else {
      data.DispositionStatus = '';
    }
    return this.http.post(environment.baseUrl + 'GetCustomerFeedbackSummary', data)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  downloadCustomerFeedbackExcel(data) {
    return this.http.post(environment.baseUrl + 'ExportCustomerFeedbackDetails', data, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getFeedbackHeaderById(surveyId, unAssignedRTIId, isRTI, type) {
    this.apiUrl = 'GetCustomerFeedbackHeaderInfoById';
    if (type != 'TRANS') {
      this.apiUrl = 'GetDistributorFeedbackHeaderInfoById';
    }
    return this.http.get(environment.baseUrl + this.apiUrl + '/' + surveyId + '/' + unAssignedRTIId + '/' + isRTI)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  saveUnassignedRTISurveyId(unAssignedRTIId, surveyInstanceId) {
    return this.http.post(environment.baseUrl + 'SaveUnassignedRTISurveyId/' + unAssignedRTIId + '/' + surveyInstanceId, surveyInstanceId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  saveResendSurveyDetails(data) {
    return this.http.post(environment.baseUrl + 'SaveResendSurveyDetails', data)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getCustomerFeedbackQuestionnaire(data) {
    return this.http.post(environment.baseUrl + 'GetCustomerFeedbackQuestionnaire', data)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getCustomerFeedbackEmailDetails(data) {
    return this.http.post(environment.baseUrl + 'GetCustomerFeedbackEmailDetails', data)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  translateWithGoogle(data, LangId) {
    const transData = {
      'LangId': LangId,
      'TranslatedQuestion': data
    };
    return this.http.post(environment.baseUrl + 'TranslateWithGoogle', transData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  deleteCustomerFeedbackRTIUnassigned(unAssignedRTIId) {
    return this.http.post(environment.baseUrl + 'DeleteCustomerFeedbackRTIUnassigned/' + unAssignedRTIId, null)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  saveTranslatedDetails(data) {
    return this.http.post(environment.baseUrl + 'SaveTranslatedDetails', data)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
}
