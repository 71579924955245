import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SortEvent } from '../../surveys/model/sortevent';
import { Paginator } from 'primeng/paginator';
import { DashboardWidgetService } from '../service/dashboard-widget.service';
import * as moment from 'moment/moment.js';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import { DistDashbaordService } from 'src/app/Modules/Distributor_Survey/Services/dist-dashbaord.service';
import { CtlsDashboardCustomerService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-loyalty-shift-grid',
  templateUrl: './loyalty-shift-grid.component.html',
  styleUrls: ['./loyalty-shift-grid.component.scss']
})
export class LoyaltyShiftGridComponent implements OnInit {
  private imagepath = environment.imagepath;
  isLoading: boolean = true;
  loyaltyResponseData: any;
  loyaltyShiftContactDetailsResponse: any;
  dataNotFoundMessage;
  @Input() fromPND;
  @Input() toPND;
  @Input() LoyaltyShiftVal;
  @Input() LoyaltyShiftText;
  @Input() index;
  @Input() closeGrid;
  @Input() surveyTypeInput;
  @Input('screenCode') screenCode: any;

  DrillDownContactText;
  ResultCnt;

  noData: boolean;

  @ViewChild(Paginator) dt: Paginator;
  contactDetails: any;
  cols: any[];
  totalRecords: number;
  pageNo = 0;
  pageSize = 0;
  configRow: number[] = [10, 20, 30, 40, 50];
  sortObj: any = {
    'sortType': '',
    'sortField': ''
  };

  paginatorFirstSelector: any;
  paginatorLaststSelector: any;
  paginatorNextSelector: any;
  paginatorPrevSelector: any;

  loading: boolean = false;
  constructor(
    private dashboardWidgetService: DashboardWidgetService,
    private distDashbaordService: DistDashbaordService,
    private _360DashboardService: CtlsDashboardCustomerService) { }

  ngOnChanges() {
    this.fromPND = this.fromPND;
    this.toPND = this.toPND;
    this.LoyaltyShiftVal = this.LoyaltyShiftVal;
    this.LoyaltyShiftText = this.LoyaltyShiftText;
    // this.onClickPrevious();
    // this.onClickNext();
    this.index = this.index;
    console.log('Grid Index', this.index);


    this.cols = [
      { field: 'ContactName', header: 'Contact Name' },
      { field: 'CustomerName', header: this.surveyTypeInput === 'DIST' || this.surveyTypeInput === 'OEM' ? 'Company Name' : 'Customer Name' },
      { field: 'Email', header: 'Email' },
      { field: 'Brand', header: 'Brand' },
      { field: 'PreviousBA', header: 'BA' },
      { field: 'PreviousFAM', header: 'FAM' },
      { field: 'PrevSurveyId', header: 'Survey Id' },
      { field: 'PrevtDate', header: 'Date' },
      { field: 'PreviousNPS', header: 'NPS' },
      { field: 'CurrentBA', header: 'BA' },
      { field: 'CurrentFAM', header: 'FAM' },
      { field: 'CurrentSurveyId', header: 'Survey Id' },
      { field: 'CurrentDate', header: 'Date' },
      { field: 'CurrentNPS', header: 'NPS' }
    ]

    this.pageNo = 1;
    this.pageSize = 10;
    this.totalRecords = 0;
    this.paginationData(this.pageNo, this.pageSize, '', '', this.fromPND, this.toPND);

  }

  ngOnInit() {

  }

  paginationData(pageNo, pageSize, sortType = '', sortField = '', pndFrom, pndTo) {
    this.loading = true;
    // this.isLoading =true;
    // let pndFrom = this.fromPND;
    // let pndTo = this.toPND;
    if (this.screenCode == this._360DashboardService.filterObjForDashboardCustomer.screenCode ||this.screenCode == this._360DashboardService.filterObjForDashboardDistributor.screenCode) {
      this._360DashboardService.getLoyaltyShiftContactDetails(sortField, sortType, pndFrom, pndTo, pageNo, pageSize).then(res => {
        this.loadContacts(res);
      }).catch(e => {

      });
    } else {
      if (this.surveyTypeInput == surveyType.Distributor ||this.surveyTypeInput == 'OEM' ) {
        this.distDashbaordService.getLoyaltyShiftContactDetails(sortField, sortType, pndFrom, pndTo, pageNo, pageSize).then(res => {
          this.loadContacts(res);
        }).catch(e => {

        });
      }
      else {
        this.dashboardWidgetService.getLoyaltyShiftContactDetails(sortField, sortType, pndFrom, pndTo, pageNo, pageSize).then(res => {
          this.loadContacts(res);
        }).catch(e => {

        });
      }

    }


  }



  loadContacts(res) {
    if (res.Status == "Success") {
      this.loyaltyShiftContactDetailsResponse = res.Response;
      this.DrillDownContactText = this.loyaltyShiftContactDetailsResponse.DrillDownContactText;
      this.ResultCnt = this.loyaltyShiftContactDetailsResponse.ResultCnt;
      this.totalRecords = this.loyaltyShiftContactDetailsResponse.ResultCnt;
      this.loading = false;
      this.isLoading = false;
      if (this.loyaltyShiftContactDetailsResponse.LoyaltyShiftContactDetails != null) {
        this.noData = false;
        let data = this.loyaltyShiftContactDetailsResponse.LoyaltyShiftContactDetails
        for (let i in data) {
          if (data[i].CurrentDate != null)
            data[i].CurrentDate = moment(data[i].CurrentDate).format("DD-MMM-YY");
          if (data[i].PrevtDate != null)
            data[i].PrevtDate = moment(data[i].PrevtDate).format("DD-MMM-YY");
        }
        this.contactDetails = data;
        this.pageNo = this.loyaltyShiftContactDetailsResponse.CurrentPageNo;
        this.pageSize = this.loyaltyShiftContactDetailsResponse.PageSize;

        //Fix Pagination tooltip issue
        //JIRA Id- 356
        setTimeout(() => {
          this.pageTooltip();
        }, 2000);
      }
      else if (this.loyaltyShiftContactDetailsResponse.LoyaltyShiftContactDetails == null && res.Status == "Success") {
        this.contactDetails = null;
        this.noData = true;
        this.dataNotFoundMessage = "No data available";
      }

    } else if (res.Response == null && res.Status == "Failure") {
      this.contactDetails = null;
      this.noData = true;
      this.loading = false;
      this.isLoading = false;
      this.dataNotFoundMessage = "Server error occurred";
    }
  }

  pageTooltip() {
    this.paginatorFirstSelector = <HTMLElement>(
      document.querySelector('button.p-paginator-first')
    );
    this.paginatorFirstSelector.title = 'First';
 
    this.paginatorLaststSelector = <HTMLElement>(
      document.querySelector('button.p-paginator-last')
    );
    this.paginatorLaststSelector.title = 'Last';
 
    this.paginatorNextSelector = <HTMLElement>(
      document.querySelector('button.p-paginator-next')
    );
    this.paginatorNextSelector.title = 'Next';
 
    this.paginatorPrevSelector = <HTMLElement>(
      document.querySelector('button.p-paginator-prev')
    );
    this.paginatorPrevSelector.title = 'Previous';
  }

  customSort(event: any) {
    if (event.sortField) {

      event.sortOrder = event.sortOrder == 1 ? 'asc' : 'dsc';
      this.paginationData(this.pageNo, this.pageSize, event.sortOrder, event.sortField, this.fromPND, this.toPND);
      this.sortObj.sortType = event.sortOrder;
      this.sortObj.sortField = event.sortField;
      this.dt.first = 0;
    }
  }
  customSort1(event: SortEvent) {


    let result = null;
    let sortType;
    let sortObj: any;
    event.data.sort((data1, data2) => {

      let value1 = data1[event.field];
      let value2 = data2[event.field];

      // let result = null;
      // let sortType;
      // let sortObj: any;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      if (event.order === 1) {
        sortObj = {
          'sortType': 'asc',
          'sortData': event.data,
          'sortField': event.field
        }
      } else {
        sortObj = {
          'sortType': 'dsc',
          'sortData': event.data,
          'sortField': event.field
        }
      };
      //API
      //return sortObj;
      this.paginationData(this.pageNo, this.pageSize, sortObj.sortType, sortObj.sortField, this.fromPND, this.toPND);
      //UI
      return (event.order * result);
    });
    //this.paginationData(this.pageNo, this.pageSize,sortObj.sortType,sortObj.sortField);
  }

  //Onclick of pagination we get page size and page No
  paginationNavigationDetails(event) {
    if (event.page >= this.pageNo || (event.page + 2) <= this.pageNo || this.pageSize != event.rows) {
      this.pageNo = event.page + 1;
      this.pageSize = event.rows;
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortType, this.sortObj.sortField, this.fromPND, this.toPND);
    }
  }

  @Output() closeGridEvent = new EventEmitter<boolean>();

  onCloseLoyaltyShiftGrid() {
    this.closeGridEvent.emit(false);
  }

  fromArr = ["P", "P", "P", "N", "N", "N", "D", "D", "D", "X", "X", "X"];
  toArr = ["P", "N", "D", "P", "N", "D", "P", "N", "D", "P", "N", "D"];
  onClickPrevious() {
    if (this.index > 0 && this.index <= 11) {
      this.index--;
      this.pageNo = 1;
      this.pageSize = 10;
      this.fromPND = this.fromArr[this.index];
      this.toPND = this.toArr[this.index];
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortType, this.sortObj.sortField, this.fromPND, this.toPND)
    }

  }

  onClickNext() {
    if (this.index >= 0 && this.index < 11) {
      this.index++;
      this.pageNo = 1;
      this.pageSize = 10;
      this.fromPND = this.fromArr[this.index];
      this.toPND = this.toArr[this.index];
      this.paginationData(this.pageNo, this.pageSize, this.sortObj.sortType, this.sortObj.sortField, this.fromPND, this.toPND);
    }

  }

}
