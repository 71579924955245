import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsTaskEditScreenService {
  private editWidth$ = new Subject<any>();
  private drag$ = new Subject<any>();
  constructor(private http: HttpClient, private sharedService: SharedService) { }
  setWidth(data) {
    this.editWidth$.next(data);
  }
  getWidth(): Observable<any> {
    return this.editWidth$.asObservable();
  }
  setDrag(data) {
    this.drag$.next(data);
  }
  getDrag(): Observable<any> {
    return this.drag$.asObservable();
  }
  /**
   * Get designations dropdowns
   */
  getDesignations(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetDesignations`,
      params
    );
  }
  /**
   * Get division dropdowns
   */
  getDivision(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetDivision`,
      params
    );
  }

  /**
   * Get assignee dropdowns
   */
  GetAssignee(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAssignee`,
      params
    );
  }
  /**
   * Get new assignee dropdowns
   */
  GetFAMBAUsers(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrlCTLS}/EditTask/GetFAMBAUsers`);
  }
  /**
   * resend email for closure
   */
  resend(data): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/ResendClosureTemplate`,
      {
        params: {
          cTLSEditTaskClosureTemplateID: data.cTLSEditTaskClosureTemplateID,
          brandID: data.brandID,
        },
      }
    );
  }
  /**
   * Get brand dropdowns
   */
  GetBrand(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetBrand`,
      params
    );
  }
  /**
   * Get CommentHistory
   */
  GetCommentHistory(data): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/GetCommentHistory?CTLSTaskListID=${data.CTLSTaskListID}&&skipCount=${data.skipCount}&&takeCount=${data.takeCount}&&IsCommentEditRequired=${data.IsCommentEditRequired}`,
      {}
    );
  }
  /**
   * Get Category dropdowns
   */
  GetCategory(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrlCTLS}/TaskListing/GetCategory`);
  }
  /** Translate data
   * @param dataObj
   */
  getTranslateWithGoogle(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Translate/TranslateWithGoogle`,
      dataObj
    );
  }
  /**Service for save edit task
   * @param dataObj
   */
  SaveTaskManagementDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/SaveTaskManagementDetails`,
      dataObj
    );
  }
  /**Service for save edit transfer task
   * @param dataObj
   */
  saveTransferTaskDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/SendMail`,
      dataObj
    );
  }
 /**
   * Resend TaskDetails for save
   */
  getResendTransferTaskDetails(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/ReSendMail?CTLSTaskListID=${dataObj.CTLSTaskListID}&CTLSTaskManagmentID=${dataObj.CTLSTaskManagmentID}`,
      dataObj
    );
  }
  /**
   * Get TaskDetails fetch after each save
   */
  GetTaskDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/GetTaskDetails?CTLSTaskListID=${dataObj.CTLSTaskListID}`,
      dataObj
    );
  }
  /**Service for getting all enabled assignees
   * @param dataObj
   */
  GetAllEnabledAssignees(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetAllEnabledAssignees`,
      {
        params: {
          FAMID: dataObj.FAMID,
          BAID: dataObj.BAID,
          AssigneeID: dataObj.AssigneeID,
        },
      }
    );
  }
  /**Service for getting templates name for dropdown
   * @param dataObj
   */
  GetTemplateNames(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetTemplateNames`,
      {
        params: {
          BAID: params.BAID,
          FAMID: params.FAMID,
          brandID: params.BrandID,
          subCategory: params.SurveyType,
        },
      }
    );
  }
  /**Service for closure template data
   * @param dataObj
   */
  GetClosureTemplate(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetClosureTemplateWithValues`,
      {
        params: {
          cTLSTaskClosureTemplateID: params.cTLSTaskClosureTemplateID,
          taskID: params.taskID,
          isRefreshed: params.isRefreshed
        },
      }
    );
  }
  /**Service for getting template for preview by object
   * @param dataObj
   */
  GetTemplatePreview(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/ClosureTemplateEmailPreview`,
      dataObj
    );
  }
  /**Service for saving and sending closure template
   * @param dataObj
   */
  SaveTemplate(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/SaveClosureTemplate`,
      dataObj
    );
  }
  /**Service for getting template for preview by id
   * @param cTLSEditTaskClosureTemplateID
   */
  ClosureTemplateSentEmailPreview(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/ClosureTemplateSentEmailPreview`,
      {
        params: {
          cTLSEditTaskClosureTemplateID: id,
        },
      }
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetTaskDetailsByTaskID(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetTaskDetailsByTaskID?CTLSTaskListID=${id}&&CTLSMailTransactionID=`
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetImgUrl(id): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/DownloadAttachment?cTLSTaskFilesListID=${id}`,
      {}
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetAttachments(id): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/GetAttachments?cTLSTaskManagementID=${id}`,
      {}
    );
  }

  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetFileUrl(id): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/DownloadAttachment?cTLSTaskFilesListID=${id}`,
      {}
    );
  }

  /**Service for update  edit task last entere comment for the login user
  * @param dataObj
  */
  getFiles(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/GetFilesforEditing?cTLSTaskManagementID=${dataObj.cTLSTaskManagementID}`,
      {},
    );
  }

  /**Service for update  edit task last entere comment for the login user
* @param dataObj
*/
  UpdateComment(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/UpdateComment`,
      dataObj
    );
  }

  /**Service for getting recommended action as er selected priority
 * @param dataObj
 */
  getRecommendedAction(CTLSConfigurationId, CTLSPriorityID, CTLSTransType): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetSDARecommendedAction?CTLSConfigurationID=${CTLSConfigurationId}&&CTLSSDAPriorityID=${CTLSPriorityID}&&CTLSTransType=${CTLSTransType}`
    );
  }

  
  /**Service for upload audio to GCP
   * @param data
   */
  uploadAudioToGCP(data) {
    let url = `${environment.baseUrl}api/Translation/UploadToCloud`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { 
        return data;
      }).catch(error => {
        return error;
      });
  }

  /**Service for upload audio to Azure
   * @param data
   */
  uploadAudioToAzure(data) {
    let url = `${environment.baseUrl}api/Translation/UploadAudioToBlob`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { return data;
      }).catch(error => {
        return error;
      });
  }

  /**Service for audio to audio translation
   * @param data
   */
    translateAudioToAudio(data) {
      let url = `${environment.baseUrl}api/Translation/SpeechTranslation`;
      return this.http.post(url, data).
        toPromise()
        .then((res: any) => res)
        .then(data => {
          return data;
        }).catch(error => {
          return error;
        });
    }

  /**Service for audio to text translation
   * @param data
   */
  translateAudioToText(data) {
    let url = `${environment.baseUrl}api/Translation/SpeechToText`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { 
        return data;
      }).catch(error => {
        return error;
      });
  }

  /**Service for text to audio translation
   * @param data
   */
  translateTextToAudio(data) {
    let url = `${environment.baseUrl}api/Translation/TextToSpeech`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { 
        return data;
      }).catch(error => {
        return error;
      });
  }

    /**Service for audio to text translation
   * @param data
   */
    translateTextToText(data) {
      let url = `${environment.baseUrl}api/Translation/TextTranslate`;
      return this.http.post(url, data).
        toPromise()
        .then((res: any) => res)
        .then(data => { 
          return data;
        }).catch(error => {
          return error;
        });
    }
}
