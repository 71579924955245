import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsSurveyOptionsService {
  recordCount: number;
  /** Form wizard state */
  surveyID: number = 0;
  markforsurvey: string = '';

  instanceAccordionStatus: any = {};

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.recordCount = 0;
  }

  GetOptions(value): Observable<any> {
    if (value === 1) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/CTLSConfiguration/GetTransactionalSurveyDetails`
      );
    } else if (value === 2) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/CTLSConfiguration/GetRelationshipSurveyDetails`
      );
    }
  }
  getUserFAM(): Observable<any> {
    return this.http
      .get<any>(`${environment.baseUrlCTLS}/CTLSConfiguration/GetFAMDetails`);
  }
}
