import { Component, OnInit, EventEmitter, Output, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Message, SelectItem } from 'primeng/components/common/api';
import * as moment from 'moment';
import { ScrollPanel } from 'primeng/scrollpanel';
import { SharedService } from 'src/app/shared/services/shared.service';
import { surveyType } from '../../constants/shared.constants';
import { RelationshipFilterService } from '../../services/filter-relationship.service';
import { SurveySubCategory } from '../../constants/survey.sub.category';
import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { DistDashbaordService } from 'src/app/Modules/Distributor_Survey/Services/dist-dashbaord.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'global-filter-distributor',
  templateUrl: './global-filter-distributor.html',
  styleUrls: ['./global-filter-distributor.scss']
})
export class GlobalFilterDistributorComponent implements OnInit, OnChanges {
  private imagepath = environment.imagepath;
  @Input() surveySubCategory: string = SurveySubCategory.Distributor;
  @Input() surveyType: string;
  showBusyIndicator: boolean = false;
  //Master data properties
  data = [];
  zoneBranch = [];
  countryContinent = [];
  brand = [];
  influencers = [];
  operationalCountryContinent = [];

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  influencersOriginal = [];
  distributorSegmentOriginal = [];
  operationalCountryContinentOriginal = [];
  operationalCountryOriginal = [];
  distributorOriginal = [];
  distributorManagerSalesOriginal = [];
  distributorManagerServiceOriginal = [];
  industrySegmentOriginal = [];
  famData = [];
  baData = [];
  divData = [];
  countryData = [];
  brandData = [];
  zoneData = [];
  branchData = [];
  operationalCountryData = [];

  distributorData = [];
  distributorSegmentData = [];
  industrySegmentData = [];
  influencerData = [];
  distributorManagerSalesData = [];
  distributorManagerServiceData = [];
  countinentData = [];
  msgs: Message[] = [];
  isShowAlert: boolean = false;
  successMsg: boolean = false;
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;

  //selected filter main object
  filterMapping: any = {
    FAMId: null,
    BAId: null,
    DIVId: null,
    CountryId: null,
    BrandId: null,
    ZoneId: null,
    BranchId: null,
    InfluencerId: null,
    DistributorManagerSalesId: null,
    DistributorManagerServiceId: null,
    ContinentId: null,
    OperationalCountryId: null,
    distributorType: null,
    distributor: null,
    industrySegment: null
  };

  //for bar pin field start
  pinnedFields = {
    baPinned: true,
    divPinned: true,
    famPinned: true,
    countryPinned: true,
    brandPinned: false,
    zonePinned: false,
    branchPinned: false,
    influencerPinned: false,
    continentPinned: false,
    distributorTypePinned: false,
    distributorPinned: false,
    distributorSegmentPinned: false,
    industrySegmentPinned: false,
    operationalCountryPinned: false,
    distributorManagerSalesPinned: false,
    distributorManagerServicePinned: false
  }

  pinnedFieldValuess = {
    baPinnedValue: "All",
    divPinnedValue: "All",
    famPinnedValue: "All",
    countryPinnedValue: "All",
    operationalCountryPinnedValue: "All",
    brandPinnedValue: "All",
    zonePinnedValue: "All",
    branchPinnedValue: "All",
    influencerPinnedValue: "All",
    continentPinnedValue: "All",
    distributorTypePinnedValue: "All",
    distributorPinnedValue: "All",
    distributorSegmentPinnedValue: "All",
    industrySegmentPinnedValue: "All",
    distributorManagerSalesPinnedValue: "All",
    distributorManagerServicePinnedValue: "All"
  }

  pinnedFieldValuesArray = {
    baPinnedValue: [],
    divPinnedValue: [],
    famPinnedValue: [],
    countryPinnedValue: [],
    operationalCountryPinnedValue: [],
    brandPinnedValue: [],
    zonePinnedValue: [],
    branchPinnedValue: [],
    influencerPinnedValue: [],
    continentPinnedValue: [],
    distributorTypePinnedValue: [],
    distributorPinnedValue: [],
    distributorSegmentPinnedValue: [],
    industrySegmentPinnedValue: [],
    distributorManagerSalesPinnedValue: [],
    distributorManagerServicePinnedValue: []
  }

  inlineFilter = {
    baInline: false,
    famInline: false,
    divInline: false,
    countryInline: false,
    operationalCountryInline: false,
    brandInline: false,
    zoneInline: false,
    branchInline: false,
    influencerInline: false,
    continentInline: false,
    distributorTypeInline: false,
    distributorInline: false,
    industrySegmentInline: false,
    distributorSegmentInline: false,
    distributorManagerSalesInline: false,
    distributorManagerServiceInline: false
  }
  //for bar pin field ends

  //selected count for dropdowns in filter start
  countOfAllData = {
    baCount: 0,
    divCount: 0,
    famCuunt: 0,
    countryCount: 0,
    operationalCountryCount: 0,
    brandCount: 0,
    zoneCount: 0,
    branchCount: 0,
    influencerCount: 0,
    continentCount: 0,
    distributorTypeCount: 0,
    distributorCount: 0,
    distributorSegmentCount: 0,
    industrySegmentCount: 0,
    distributorManagerSalesCount: 0,
    distributorManagerServiceCount: 0,
  }

  selectedCount = {
    baCount: [],
    divCount: [],
    famCount: [],
    countryCount: [],
    operationalCountryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    influencerCount: [],
    continentCount: [],
    distributorTypeCount: [],
    distributorCount: [],
    distributorSegmentCount: [],
    industrySegmentCount: [],
    distributorManagerSalesCount: [],
    distributorManagerServiceCount: [],
  }

  //selected count for dropdowns in filter ends

  // for  period selection start and end date calendor
  periodType = {
    M: true,
    Q: false,
    H: false,
    Y: false,
  }

  delimiter = "|";
  filterToggle: boolean = false;
  filterDateToggle: boolean = false;
  pinFilterSecVisible: boolean = false;
  dateTime = new Date();
  defaultDate = new Date();
  isPeriodType: boolean = false;
  isDateRange: boolean = true;
  StartDate = null;
  EndDate = null;
  startDateCal = "";
  endDateCal = "";
  savedFilterObject: any = {};
  savedFilterObjectFromDB: any = {};


  @Input('npsWidgetStatus') npsWidgetStatus;  // 
  @Input('selectedSurveyDate') selectedSurveyDate
  @Input('feedbackList') feedbackList
  @Output('submitted') submitted: EventEmitter<any> = new EventEmitter<any>(); // this is used for emiting fillter change
  @Output('saved') saved: EventEmitter<string> = new EventEmitter<string>();
  @Output('pinChanged') pinChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPinnedValueChange') onPinnedValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('reset') reset: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('famDDL') famDDL;
  @ViewChild('baDDL') baDDL;
  @ViewChild('divDDL') divDDL;
  @ViewChild('countryDDL') countryDDL;
  @ViewChild('operationalCountryDDL') operationalCountryDDL;
  @ViewChild('distributorManagerSalesDDL') distributorManagerSalesDDL;
  @ViewChild('distributorManagerServiceDDL') distributorManagerServiceDDL;
  @ViewChild('brandDDL') brandDDL;
  @ViewChild('zoneDDL') zoneDDL;
  @ViewChild('branchDDL') branchDDL;
  @ViewChild('continentDDL') continentDDL;
  @ViewChild('distributorDDL') distributorDDL;
  @ViewChild('distributorSegmentDDL') distributorSegmentDDL;
  @ViewChild('industrySegmentDDL') industrySegmentDDL;
  @ViewChild('infDDL') infDDL;


  @ViewChild('famDDLPinned') famDDLPinned;
  @ViewChild('baDDLPinned') baDDLPinned;
  @ViewChild('divDDLPinned') divDDLPinned;
  @ViewChild('countryDDLPinned') countryDDLPinned;
  @ViewChild('operationalCountryDDLPinned') operationalCountryDDLPinned;

  @ViewChild('distributorManagerSalesDDLPinned') distributorManagerSalesDDLPinned;
  @ViewChild('distributorManagerServiceDDLPinned') distributorManagerServiceDDLPinned;

  @ViewChild('brandDDLPinned') brandDDLPinned;
  @ViewChild('zoneDDLPinned') zoneDDLPinned;
  @ViewChild('branchDDLPinned') branchDDLPinned;
  @ViewChild('continentDDLPinned') continentDDLPinned;
  @ViewChild('distributorDDLPinned') distributorDDLPinned;
  @ViewChild('distributorSegmentDDLPinned') distributorSegmentDDLPinned;
  @ViewChild('industrySegmentDDLPinned') industrySegmentDDLPinned;
  @ViewChild('infDDLPinned') infDDLPinned;
  survey: string;
  wholeDistributorData= [];

  constructor(private filterService: RelationshipFilterService, private sharedService: SharedService, private hideOtherOpenDrilldownsService: HideOtherOpenDrilldownsService) { }

  ngOnInit() {
    if (this.selectedSurveyDate) {
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let surveyDate = this.selectedSurveyDate.substr(0, 2) + '-' +
        month[(+this.selectedSurveyDate.substr(2, 2)) - 1] + '-' + this.selectedSurveyDate.substr(4);
      this.startDateCal = surveyDate;
      this.endDateCal = surveyDate;
      this.submitDatefilter();
      this.submitted.emit(this.filterService.filterObj);
    } else {
      this.showBusyIndicator = true;
      let dataFilter = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"));

      this.filterService.filterObj.SubCategory = dataFilter ? dataFilter.SubCategory: 'DIST';
      // this.survey = this.filterService.filterObj.SubCategory;
      let subCat =this.filterService.filterObj.SubCategory ? this.filterService.filterObj.SubCategory : 'DIST';
      if(!window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
        subCat = this.survey;
      } 
      this.filterService.getUserFilter(subCat, this.feedbackList,true).then(res => {
        
        this.savedFilterObject.EndDate = this.filterService.filterObj.EndDate;
        this.savedFilterObject.SubCategory= this.filterService.filterObj.SubCategory;
        this.savedFilterObject.StartDate= this.filterService.filterObj.StartDate;
        this.savedFilterObject.periodType= this.filterService.filterObj.PeriodType;
        this.savedFilterObject.Distributor= res.Response.Distributor;
        this.filterService.filterObj.Distributor= res.Response.Distributor;
        this.filterService.filterObj.DistributorSegment= res.Response.DistributorSegment;
        this.savedFilterObject.Distributor= res.Response.Distributor;
        localStorage.setItem("globalFilterObjectRelationship",JSON.stringify(this.filterService.filterObj))
        // this.savedFilterObject = this.filterService.filterObj;
        this.convertToArray();
        if (this.filterService.dataOriginal.length > 0) {
          this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
          this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
            this.showBusyIndicator = false;
          });

        } else {

          this.getViewData();
        }
      }).catch(error => {
        this.savedFilterObject.EndDate = this.filterService.filterObj.EndDate;
        this.savedFilterObject.SubCategory= this.filterService.filterObj.SubCategory;
        this.savedFilterObject.StartDate= this.filterService.filterObj.StartDate;
        this.savedFilterObject.periodType= this.filterService.filterObj.PeriodType;
        this.convertToArray();
        if (this.filterService.dataOriginal.length > 0) {
          this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });

          this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
            this.showBusyIndicator = false;
          });
        } else {

          this.getViewData();
        }
      });
      const today = new Date();
      this.hideOtherOpenDrilldownsService.getDrillDownClickEvent().subscribe(val => {
        if (val !== 'global_fillter') {
          this.filterToggle = this.filterDateToggle = this.pinFilterSecVisible = false;
        }
      })
    }
  }
  getSelectedDate(event, dateType) {
    var dateToSelect = 1;
    if (dateType === "endDate") {
      try {
        if (this.endDateCal !== null) {
          dateToSelect = parseInt(this.endDateCal.split("-")[0]);
        }
      } catch{ }
    }
    if (dateType === "startDate") {
      try {
        if (this.startDateCal !== null) {
          dateToSelect = parseInt(this.startDateCal.split("-")[0]);
        }
      } catch{ }
    }
    var lastDay = new Date(event.year, event.month, 0).getDate();
    if (lastDay < dateToSelect) {
      dateToSelect = lastDay
    }
    return dateToSelect;
  }

  onMonthChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }

  onYearChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }

  resetDatefilter() {
    this.isDateRange = true;
    this.isPeriodType = true;
    this.periodType.Q = false;
    this.periodType.H = false;
    this.periodType.Y = false;
    this.periodType.M = true;

    const today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    this.calculateStartAndEndDate('M');
  }

  calculateStartAndEndDate(key) {

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == "Q") {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      }
      else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      }
      else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == "H") {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      }
      else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }

    }

    if (key == "Y") {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }

    this.StartDate = this.changeDateFormate(firstDay);
    this.EndDate = this.changeDateFormate(lastDay);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
  }

  onDateSelection(selectedDate, dateType) {
    this.isPeriodType = false;
    this.isDateRange = true;
    if (selectedDate !== null && dateType == 'startDate') {
      this.startDateCal = this.changeDateFormate(new Date(selectedDate));
      var endDateFormat = this.changeDateFormat(this.EndDate);
      var endDate = new Date(endDateFormat);

      if (moment(selectedDate) <= moment(endDate)) {
        this.StartDate = this.changeDateFormate(new Date(selectedDate));
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    } else if (selectedDate !== null && dateType == 'endDate') {
      this.endDateCal = this.changeDateFormate(new Date(selectedDate));
      var startDateFormat = this.changeDateFormat(this.StartDate);
      var startDate = new Date(startDateFormat);
      if (selectedDate >= moment(startDate)) {
        this.EndDate = this.changeDateFormate(new Date(selectedDate));
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');

      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    }
  }

  updatePeriodType(periodType) {
    var today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    for (var key in this.periodType) {
      if (this.periodType.hasOwnProperty(key)) {
        if (key == periodType) {
          this.periodType[key] = true;
          this.calculateStartAndEndDate(key);
        } else {
          this.periodType[key] = false;
        }
      }
    }
    this.isDateRange = false;
    this.isPeriodType = true;
  }

  ngOnChanges() {
    let _this= this;
    this.filterService.advancedSearchDateFilterSubject.subscribe(val => {
      if (val == true && val !== null) {
        let dataFilter = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"));

      this.filterService.filterObj.SubCategory = dataFilter ? dataFilter.SubCategory: 'DIST';
      this.filterService.filterObj =  dataFilter ? dataFilter: this.filterService.filterObj;
        _this.survey = this.surveySubCategory ==='OEM'  ? 'OEM' : 'DIST'; 
        if(!window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
          localStorage.removeItem('subcat');
        } 
        var filterObject = {
          "StartDate": this.filterService.StartDate ? this.filterService.StartDate : this.filterService.filterObj && this.filterService.filterObj.StartDate ? this.filterService.filterObj.StartDate: this.StartDate,
          "EndDate": this.filterService.EndDate ? this.filterService.EndDate: this.filterService.filterObj && this.filterService.filterObj.EndDate ? this.filterService.filterObj.EndDate: this.EndDate,
          "PeriodType": this.filterService.PeriodType,
        }
        localStorage.setItem("periodFilterObjectRelationship", JSON.stringify(filterObject));
        // this.filterService.updateFilterObject();
        this.StartDate = this.changeDateFormate(this.filterService.filterObj.StartDate);
        this.EndDate = this.changeDateFormate(this.filterService.filterObj.EndDate);
        this.startDateCal = this.StartDate
        this.endDateCal = this.EndDate;
        this.savedFilterObject = this.filterService.filterObj;  
        this.getViewData();
        this.convertToArray();
        this.setPinnedFilterValue();
      } else {
        let dataFilter = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"));
        _this.filterService.filterObj.SubCategory = this.surveySubCategory ==='OEM'  ? 'OEM' : 'DIST';
        _this.survey = this.surveySubCategory ==='OEM'  ? 'OEM' : 'DIST';
      }
    })
    if (this.npsWidgetStatus !== "") {
      this.filterToggle = false;
      this.filterDateToggle = false;
      this.pinFilterSecVisible = false;
    }
  }

  convertToArray() {
    var periodType = null;
    if (this.savedFilterObject !== null) {
      if (this.filterService.filterObj.StartDate !== null && this.filterService.filterObj.StartDate) {
        this.startDateCal = this.filterService.filterObj.StartDate
        this.StartDate = this.filterService.filterObj.StartDate;
        this.filterService.filterObj.PeriodType = null;
      }
      if (this.filterService.filterObj.EndDate !== null && this.filterService.filterObj.EndDate) {
        this.endDateCal = this.filterService.filterObj.EndDate;
        this.EndDate = this.filterService.filterObj.EndDate;
        this.filterService.filterObj.PeriodType = null;
      }

      if (this.filterService.filterObj.PeriodType !== null && this.filterService.filterObj.PeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (key == this.filterService.filterObj.PeriodType) {
              this.periodType[key] = true;
              periodType = key
              this.calculateStartAndEndDate(key);
            } else {
              this.periodType[key] = false;
            }
          }
        }
      }
      else {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            this.periodType[key] = false;
          }
        }
      }

      this.filterMapping.BAId = (this.savedFilterObject.BACode && this.savedFilterObject.BACode !== null && this.savedFilterObject.BACode !== "") ? this.savedFilterObject.BACode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DIVId = (this.savedFilterObject.DivCode && this.savedFilterObject.DivCode !== null && this.savedFilterObject.DivCode !== "") ? this.savedFilterObject.DivCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.FAMId = (this.savedFilterObject.FAMCode && this.savedFilterObject.FAMCode !== null && this.savedFilterObject.FAMCode !== "") ? this.savedFilterObject.FAMCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.BLId = (this.savedFilterObject.BLCode && this.savedFilterObject.BLCode !== null && this.savedFilterObject.BLCode !== "") ? this.savedFilterObject.BLCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.CountryId = (this.savedFilterObject.Country && this.savedFilterObject.Country !== null && this.savedFilterObject.Country !== "") ? this.savedFilterObject.Country.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []

      this.filterMapping.OperationalCountryId = (this.savedFilterObject.OperationalCountry && this.savedFilterObject.OperationalCountry !== null && this.savedFilterObject.OperationalCountry !== "") ? this.savedFilterObject.OperationalCountry.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []

      this.filterMapping.BrandId = (this.savedFilterObject.BrandName && this.savedFilterObject.BrandName !== null && this.savedFilterObject.BrandName !== "") ? this.savedFilterObject.BrandName.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.ZoneId = (this.savedFilterObject.Zone && this.savedFilterObject.Zone !== null && this.savedFilterObject.Zone !== "") ? this.savedFilterObject.Zone.split(this.delimiter) : []
      this.filterMapping.BranchId = (this.savedFilterObject.Branch && this.savedFilterObject.Branch !== null && this.savedFilterObject.Branch !== "") ? this.savedFilterObject.Branch.split(this.delimiter) : []
      this.filterMapping.SalesEngId = (this.savedFilterObject.SalesEngineer && this.savedFilterObject.SalesEngineer !== null && this.savedFilterObject.SalesEngineer !== "") ? this.savedFilterObject.SalesEngineer.split(this.delimiter) : []
      this.filterMapping.ServiceTechId = (this.savedFilterObject.ServiceTechnician && this.savedFilterObject.ServiceTechnician !== null && this.savedFilterObject.ServiceTechnician !== "") ? this.savedFilterObject.ServiceTechnician.split(this.delimiter) : []
      this.filterMapping.TouchPointId = (this.savedFilterObject.Touchpoint && this.savedFilterObject.Touchpoint !== null && this.savedFilterObject.Touchpoint !== "") ? this.savedFilterObject.Touchpoint.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.InfluencerId = (this.savedFilterObject.Influencer && this.savedFilterObject.Influencer !== null && this.savedFilterObject.Influencer !== "") ? this.savedFilterObject.Influencer.split(this.delimiter) : []
      this.filterMapping.ContinentId = (this.savedFilterObject.Continent && this.savedFilterObject.Continent !== null && this.savedFilterObject.Continent !== "") ? this.savedFilterObject.Continent.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.regionId = (this.savedFilterObject.Region && this.savedFilterObject.Region !== null && this.savedFilterObject.Region !== "") ? this.savedFilterObject.Region.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DistributorId = (this.savedFilterObject.Distributor && this.savedFilterObject.Distributor !== null && this.savedFilterObject.Distributor !== "") ? this.savedFilterObject.Distributor.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DistributorSegmentId = (this.savedFilterObject.DistributorSegment && this.savedFilterObject.DistributorSegment !== null && this.savedFilterObject.DistributorSegment !== "") ? this.savedFilterObject.DistributorSegment.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.IndustrySegmentId = (this.savedFilterObject.IndustrySegment && this.savedFilterObject.IndustrySegment !== null && this.savedFilterObject.IndustrySegment !== "") ? this.savedFilterObject.IndustrySegment.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DistributorManagerSalesId = (this.savedFilterObject.DistributorManagerSales && this.savedFilterObject.DistributorManagerSales !== null && this.savedFilterObject.DistributorManagerSales !== "") ? this.savedFilterObject.DistributorManagerSales.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DistributorManagerServiceId = (this.savedFilterObject.DistributorManagerService && this.savedFilterObject.DistributorManagerService !== null && this.savedFilterObject.DistributorManagerService !== "") ? this.savedFilterObject.DistributorManagerService.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []


    }
    if (this.StartDate !== null && this.EndDate !== null) {
      this.isPeriodType = false;
      this.isDateRange = true;
    }
    if (periodType !== null) {
      this.isPeriodType = true;
      this.isDateRange = false;
    }
    if (this.filterService.filterObj.EndDate == null && this.filterService.filterObj.StartDate == null && this.periodType == null) {
      this.periodType.M = true;
      this.calculateStartAndEndDate('M');
    }
  }
  convertToDelimiterSeparated(del): any {

    return {
      "BACode": this.filterMapping.BAId !== null ? this.filterMapping.BAId.join(del) : "",
      "FAMCode": this.filterMapping.FAMId !== null ? this.filterMapping.FAMId.join(del) : "",
      "DivCode": this.filterMapping.DIVId !== null ? this.filterMapping.DIVId.join(del) : "",
      "BLCode": this.filterMapping.BLId !== null ? this.filterMapping.BLId.join(del) : "",
      "Country": this.filterMapping.CountryId !== null ? this.filterMapping.CountryId.join(del) : "",
      "OperationalCountry": this.filterMapping.OperationalCountryId !== null ? this.filterMapping.OperationalCountryId.join(del) : "",
      "BrandName": this.filterMapping.BrandId !== null ? this.filterMapping.BrandId.join(del) : "",
      "SalesEngineer": this.filterMapping.SalesEngId !== null ? this.filterMapping.SalesEngId.join(del) : "",
      "Touchpoint": this.filterMapping.TouchPointId !== null ? this.filterMapping.TouchPointId.join(del) : "",
      "Influencer": this.filterMapping.InfluencerId !== null ? this.filterMapping.InfluencerId.join(del) : "",
      "ServiceTechnician": this.filterMapping.ServiceTechId !== null ? this.filterMapping.ServiceTechId.join(del) : "",
      "Continent": this.filterMapping.ContinentId !== null ? this.filterMapping.ContinentId.join(del) : "",
      "Region": this.filterMapping.regionId !== null ? this.filterMapping.regionId.join(del) : "",
      "Zone": this.filterMapping.ZoneId !== null ? this.filterMapping.ZoneId.join(del) : "",
      "Branch": this.filterMapping.BranchId !== null ? this.filterMapping.BranchId.join(del) : "",
      "Distributor": this.filterMapping.DistributorId !== null ? this.filterMapping.DistributorId.join(del) : "",
      "DistributorSegment": this.filterMapping.DistributorSegmentId !== null ? this.filterMapping.DistributorSegmentId.join(del) : "",
      "IndustrySegment": this.filterMapping.IndustrySegmentId !== null ? this.filterMapping.IndustrySegmentId.join(del) : "",
      "DistributorManagerSales": this.filterMapping.DistributorManagerSalesId !== null ? this.filterMapping.DistributorManagerSalesId.join(del) : "",
      "DistributorManagerService": this.filterMapping.DistributorManagerServiceId !== null ? this.filterMapping.DistributorManagerServiceId.join(del) : "",
      "PinnedField": ""
    }
  }
  reloadData() {
    this.famData = this.data.map(x => { return { value: x.FAMID, label: x.FAMCode } });
    this.baData = this.data.map(x => { return { value: x.BAID, label: x.BACode } });
    this.divData = this.data.map(x => { return { value: x.DivisionID, label: x.DivisionCode } });
    this.countryData = this.countryContinent.map(x => { return { value: x.CountryID, label: x.CountryName } });
    //this.operationalCountryData = this.operationalCountryContinent.map(x => { return { value: x.CountryID, label: x.CountryName } });
    this.countinentData = this.countryContinent.map(x => { return { value: x.ContinentID, label: x.ContinentName } });
    this.zoneData = this.zoneBranch.map(x => { return { value: x.ZoneCode, label: x.ZoneCode } });
    this.branchData = this.zoneBranch.map(x => { return { value: x.BranchCode, label: x.BranchCode } });
    //Independent dropdowns
    this.influencerData = this.influencers.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
    this.brandData = this.brand.map(x => { return { value: x.BrandID, label: x.BrandName } });

    this.distributorSegmentData = this.filterService.distributorSegmentOriginal.map(x => { return { value: x.DistributorSegmentID, label: x.DistributorSegment } });
    this.distributorData = this.filterService.distributorOriginal.map(x => { return { value: x.DistributorID, label: x.DistributorName, number: x.AccountNumber,EntityType:x.EntityType  } });
    this.wholeDistributorData =  this.distributorData;
    let surveyType =  this.survey;
    if(surveyType === 'OEM' || surveyType === 'DIST') {
      this.distributorData =  this.distributorData.filter(data=> data.EntityType == surveyType);
    }
    this.industrySegmentData = this.filterService.industrySegmentOriginal.map(x => { return { value: x.IndustrySegmentID, label: x.IndustrySegment } });

    this.operationalCountryData = this.operationalCountryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });
    this.distributorManagerSalesData = this.filterService.distributorManagerSalesOriginal.map(x => { return { value: x.DistributorManagerSalesId, label: x.ManagerName } });
    this.distributorManagerServiceData = this.filterService.distributorManagerServiceOriginal.map(x => { return { value: x.DistributorManagerServiceId, label: x.ManagerName } });

    //Need casecading filter effect
    this.famData = this.getDistinct(this.famData, "value");
    this.baData = this.getDistinct(this.baData, "value");
    this.divData = this.getDistinct(this.divData, "value");
    this.countryData = this.getDistinct(this.countryData, "value");
    this.operationalCountryData = this.getDistinct(this.operationalCountryData, "value");
    this.countinentData = this.getDistinct(this.countinentData, "value");
    this.zoneData = this.getDistinct(this.zoneData, "label");
    this.branchData = this.getDistinct(this.branchData, "label");
    this.brandData = this.getDistinct(this.brandData, "value");
    this.distributorSegmentData = this.getDistinct(this.distributorSegmentData, "value");
    this.distributorData = this.getDistinct(this.distributorData, "value");
    surveyType =  this.survey;
    this.wholeDistributorData =  this.distributorData;
    if(surveyType === 'OEM' || surveyType === 'DIST') {
      this.distributorData =  this.distributorData.filter(data=> data.EntityType == surveyType);
    }
    this.industrySegmentData = this.getDistinct(this.industrySegmentData, "value");

    this.distributorManagerSalesData = this.getDistinct(this.distributorManagerSalesData, "value");
    this.distributorManagerServiceData = this.getDistinct(this.distributorManagerServiceData, "value");
    this.setPinnedFilterValue();
  }
  treeData = [];
  selectedFiles2 = [];


  //----------------------------Not used in relationship dashboard start-----------------------
  formatTouchPointData(list: Array<any>): Array<any> {
    let ids = [];
    let tpList = [];

    list.forEach(element => {
      if (ids.indexOf(element.TouchPointID) < 0) {
        var label = element.TouchPoint;
        if (element.ParentTouchPointId > 0) {
          let parent = list.filter(ele => { return (ele.TouchPointID == element.ParentTouchPointId) })
          label = parent.map(ele => {
            return ele.TouchPoint + "-" + label
          });
          if (label.length > 0) {
            label = label[0];
          }
        }
        tpList.push({ label: label, value: element.TouchPointID });
        ids.push(element.TouchPointID);
      }
    });
    tpList = tpList.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    return tpList;
  }
  //----------------------------Not used in relationship dashboard end-----------------------

  // Api calls first time to load master data
  getViewData() {
    this.showBusyIndicator = true;


    if (this.filterService.dataOriginal.length > 0) {

      this.famData = this.filterService.dataOriginal.map(x => { return { value: x.FAMID, label: x.FAMCode } });
      this.baData = this.filterService.dataOriginal.map(x => { return { value: x.BAID, label: x.BACode } });
      this.divData = this.filterService.dataOriginal.map(x => { return { value: x.DivisionID, label: x.DivisionCode } });
      this.countryData = this.filterService.countryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });

      //this.operationalCountryData = this.filterService.dataOriginal.map(x => { return { value: x.OperationalCountryId, label: x.operationalCountry } });

      this.countinentData = this.filterService.countryContinentOriginal.map(x => { return { value: x.ContinentID, label: x.ContinentName } });
      this.zoneData = this.filterService.zoneBranchOriginal.map(x => { return { value: x.ZoneCode, label: x.ZoneCode } });
      this.branchData = this.filterService.zoneBranchOriginal.map(x => { return { value: x.BranchCode, label: x.BranchCode } });
      //Independent dropdowns
      this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
      this.brandData = this.filterService.brandOriginal.map(x => { return { value: x.BrandID, label: x.BrandName } });

      this.operationalCountryData = this.filterService.operationalCountryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });
      this.distributorManagerSalesData = this.filterService.distributorManagerSalesOriginal.map(x => { return { value: x.DistributorManagerSalesId, label: x.ManagerName } });
      this.distributorManagerServiceData = this.filterService.distributorManagerServiceOriginal.map(x => { return { value: x.DistributorManagerServiceId, label: x.ManagerName } });

      //Need casecading filter effect
      this.famData = this.getDistinct(this.famData, "value");
      this.baData = this.getDistinct(this.baData, "value");
      this.divData = this.getDistinct(this.divData, "value");

      this.countryData = this.getDistinct(this.countryData, "value");

      this.operationalCountryData = this.getDistinct(this.operationalCountryData, "value");

      this.countinentData = this.getDistinct(this.countinentData, "value");

      this.zoneData = this.getDistinct(this.zoneData, "label");
      this.branchData = this.getDistinct(this.branchData, "label");

      this.brandData = this.getDistinct(this.brandData, "value");

      this.distributorManagerSalesData = this.getDistinct(this.distributorManagerSalesData, "value");
      this.distributorManagerServiceData = this.getDistinct(this.distributorManagerServiceData, "value");


      this.filterMapping.BAId = [];
      this.filterMapping.FAMId = [];
      this.filterMapping.DIVId = [];
      this.filterMapping.BLId = [];
      this.filterMapping.CountryId = [];
      this.filterMapping.OperationalCountryId = [];
      this.filterMapping.BrandId = [];
      this.filterMapping.ZoneId = [];
      this.filterMapping.BranchId = [];
      this.filterMapping.SalesEngId = [];
      this.filterMapping.ServiceTechId = [];
      this.filterMapping.TouchPointId = [];
      this.filterMapping.InfluencerId = [];
      this.filterMapping.ContinentId = [];
      this.filterMapping.regionId = [];
      this.filterMapping.DistributorSegmentId = [];
      this.filterMapping.DistributorId = [];

      this.filterMapping.DistributorManagerSalesId = [];
      this.filterMapping.DistributorManagerServiceId = [];

      //Clear selected meta tag
      for (var key in this.selectedCount) {
        if (this.selectedCount.hasOwnProperty(key)) {
          this.selectedCount[key] = [];
        }
      }
      this.showBusyIndicator = false;


    } else {


      this.filterService.getAllData(this.surveySubCategory).then(rawdata => {

        if (rawdata.Response !== null) {

          this.filterService.dataOriginal = Object.assign([], rawdata.Response.FamBADivBLFilterModel);
          this.filterService.zoneBranchOriginal = Object.assign([], rawdata.Response.FamBADivZoneBranchFilterModel);
          this.filterService.countryContinentOriginal = Object.assign([], rawdata.Response.FamCountryContiRegionFilterModel);
          this.filterService.operationalCountryContinentOriginal = Object.assign([], rawdata.Response.FamOperationalCountryContiRegionFilterDistModel);
          this.filterService.brandOriginal = Object.assign([], rawdata.Response.FamBABrandFilterModel);
          this.filterService.touchPointsOriginal = Object.assign([], rawdata.Response.TouchPointFilterModel);
          this.filterService.influencersOriginal = Object.assign([], rawdata.Response.InfluencerModel);
          this.filterService.distributorSegmentOriginal = Object.assign([], rawdata.Response.BABrandDistributorSegmentModel);
          this.filterService.distributorOriginal = Object.assign([], rawdata.Response.FamBADistributorFilterModel);
          this.filterService.industrySegmentOriginal = Object.assign([], rawdata.Response.IndustrySegmentModel);

          this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
          //this.filterService.operationalCountryContinentOriginal = Object.assign([], rawdata.Response.FamOperationalCountryContiRegionFilterDistModel);

          this.filterService.distributorManagerSalesOriginal = Object.assign([], rawdata.Response.DistributorManagerSalesModel);
          this.filterService.distributorManagerServiceOriginal = Object.assign([], rawdata.Response.DistributorManagerServiceModel);

          this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
            this.applyfilterOnLabel();
            this.showBusyIndicator = false;
          });
          this.setAllDataCount();

        }
        else {
          this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
            this.applyfilterOnLabel();
            this.showBusyIndicator = false;
          });
        }


      }).catch(error => {
        this.applyCasecadingOnList().then(ele => {
          this.setPinnedFilterValue();
          this.showBusyIndicator = false;
        });
      })
    }

  }

  setAllDataCount() {
    var famData = this.filterService.dataOriginal.map(x => { return { value: x.FAMID, label: x.FAMCode } });
    var baData = this.filterService.dataOriginal.map(x => { return { value: x.BAID, label: x.BACode } });
    var divData = this.filterService.dataOriginal.map(x => { return { value: x.DivisionID, label: x.DivisionCode } });
    var blData = this.filterService.dataOriginal.map(x => { return { value: x.BLID, label: x.BLName } });

    var countryData = this.filterService.countryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });
    var countinentData = this.filterService.countryContinentOriginal.map(x => { return { value: x.ContinentID, label: x.ContinentName } });

    var operationalCountryData = this.filterService.operationalCountryContinentOriginal.map(x => { return { value: x.CountryID, label: x.CountryName } });

    var regionData = this.filterService.countryContinentOriginal.map(x => { return { value: x.RegionID, label: x.RegionName } })

    var zoneData = this.filterService.zoneBranchOriginal.map(x => { return { value: x.ZoneCode, label: x.ZoneCode } });
    var branchData = this.filterService.zoneBranchOriginal.map(x => { return { value: x.BranchCode, label: x.BranchCode } });

    var distributorSegmentData = this.filterService.distributorSegmentOriginal.map(x => { return { value: x.DistributorSegmentID, label: x.DistributorSegment } });
    var distributorData = this.filterService.distributorOriginal.map(x => { return { value: x.DistributorID, label: x.DistributorName, number: x.AccountNumber , EntityType:x.EntityType} });
    let surveyType =  this.survey
    if(surveyType === 'OEM' || surveyType === 'DIST') {
      distributorData =  distributorData.filter(data=> data.EntityType == surveyType);
    }
    var industrySegmentData = this.filterService.industrySegmentOriginal.map(x => { return { value: x.IndustrySegmentID, label: x.IndustrySegment } });

    //Independent dropdowns
    var touchPointData = this.filterService.touchPointsOriginal.map(x => { return { value: x.TouchPointID, label: x.TouchPointCode } });
    var influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });

    var brandData = this.filterService.brandOriginal.map(x => { return { value: x.BrandID, label: x.BrandName } });

    var distributorManagerSalesData = this.filterService.distributorManagerSalesOriginal.map(x => { return { value: x.DistributorManagerSalesId, label: x.ManagerName } });
    var distributorManagerServiceData = this.filterService.distributorManagerServiceOriginal.map(x => { return { value: x.DistributorManagerServiceId, label: x.ManagerName } });

    //Need casecading filter effect
    this.countOfAllData.famCuunt = this.getDistinct(famData, "value").length;
    this.countOfAllData.baCount = this.getDistinct(baData, "value").length;
    this.countOfAllData.divCount = this.getDistinct(divData, "value").length;

    this.countOfAllData.countryCount = this.getDistinct(countryData, "value").length;
    this.countOfAllData.continentCount = this.getDistinct(countinentData, "value").length;

    this.countOfAllData.operationalCountryCount = this.getDistinct(operationalCountryData, "value").length;

    this.countOfAllData.zoneCount = this.getDistinct(zoneData, "label").length;
    this.countOfAllData.branchCount = this.getDistinct(branchData, "label").length;

    this.countOfAllData.brandCount = this.getDistinct(brandData, "value").length;

    this.countOfAllData.distributorCount = this.getDistinct(distributorData, "value").length;
    this.countOfAllData.distributorSegmentCount = this.getDistinct(distributorSegmentData, "value").length;
    this.countOfAllData.industrySegmentCount = this.getDistinct(industrySegmentData, "value").length;

    this.countOfAllData.distributorManagerSalesCount = this.getDistinct(distributorManagerSalesData, "value").length;
    this.countOfAllData.distributorManagerServiceCount = this.getDistinct(distributorManagerServiceData, "value").length;

  }

  filterByBA(list, baList): any {
    return list.filter(ele => {
      if (baList.indexOf(ele.BAID) >= 0) {
        return ele;
      }
    });
  };

  filterByBrand(list, brandList): any {
    return list.filter(ele => {
      if (brandList.indexOf(ele.BrandID) >= 0) {
        return ele;
      }
    });
  };

  filterByFAM(list, famList): any {
    return list.filter(ele => {
      if (famList.indexOf(ele.FAMID) >= 0) {
        return ele;
      }
    });
  };
  filterByDIV(list, divList): any {
    return list.filter(ele => {
      if (divList.indexOf(ele.DivisionID) >= 0) {
        return ele;
      }
    });
  };

  filterByDistributor(list, disList): any {
    return list.filter(ele => {
      if (disList.indexOf(ele.DistributorID) >= 0) {
        return ele;
      }
    });
  };

  filterByoperationalCountry(list, opcList): any {
    return list.filter(ele => {
      if (opcList.indexOf(ele.CountryID) >= 0) {
        return ele;
      }
    });
  };

  filterByDistributorManagerSales(list, distributorManagersalesList): any {
    return list.filter(ele => {
      if (distributorManagersalesList.indexOf(ele.DistributorManagerSalesId) >= 0) {
        return ele;
      }
    });
  };

  filterByDistributorManagerService(list, distributormanagerserviceList): any {
    return list.filter(ele => {
      if (distributormanagerserviceList.indexOf(ele.DistributorManagerServiceId) >= 0) {
        return ele;
      }
    });
  };


  // Filter based on FAM
  SelectedFams(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  removeFakeClass() {
    var element = <HTMLElement>document.getElementsByClassName('ui-multiselect-panel')[0];
    if (element) {
      element.classList.remove('remove-arrow');
    }
  }

  onOpenPanel() {
    var element = <HTMLElement>document.getElementsByClassName('ui-multiselect-panel')[0];
    element.className += " remove-arrow";
  }

  // Filter based on BA
  SelectedBas(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList('ba').then(res => {
      this.showBusyIndicator = false;
    });
  }

  // Filter based on DIV
  SelectedDivs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  // Filter based on BL
  SelectedBls(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedCountries(event) {
    this.showBusyIndicator = true;
    this.hideScroll = false;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedOperationalCountries(event) {
    this.showBusyIndicator = true;
    this.hideScroll = false;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedBrands(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedZones(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedBranchs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedSalesEngs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedServiceTechs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedContinents(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }
  SelectedRegions(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedTouchPoints(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }


  SelectedDistributorSegment(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }
  SelectedIndustrySegment(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }
  SelectedDistributor(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedDistributorManagerSales(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedDistributorManagerService(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }


  SelectedInfluncer(event) {
    setTimeout(() => {
      this.applyfilterOnLabel()
    }, 10);
  }

  resetFilter() {
    this.getViewData();
  }

  submit() {
    localStorage.setItem('dateSubmitted','true');
    this.reomveExtraIds();
    var delimiterSepratedObject = this.convertToDelimiterSeparated(this.delimiter);
    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }
    if(!delimiterSepratedObject.StartDate && !delimiterSepratedObject.EndDate && !delimiterSepratedObject.PeriodType) {
      let period = JSON.parse(localStorage.getItem("periodFilterObjectRelationship"))
      var filterObject = {
        "StartDate": period.StartDate ? period.StartDate: this.StartDate,
        "EndDate": period.EndDate ? period.EndDate : this.EndDate,
        "PeriodType": period.PeriodType,
      }
      
      delimiterSepratedObject = {...delimiterSepratedObject,...filterObject}
    }
    
    delimiterSepratedObject["PinnedField"] = piinedFieldsArray.join("|");
    let globalFilter = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"))
    if(window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
      var filterParams = {
        "SubCategory": globalFilter.SubCategory ? globalFilter.SubCategory: "DIST",
        "SurveyInstanceID": globalFilter.SurveyInstanceID ? globalFilter.SurveyInstanceID :null,
        "SurveyID": globalFilter.SurveyID ?globalFilter.SurveyID: null ,
      }
      delimiterSepratedObject = {...delimiterSepratedObject,...filterParams}
    }
    localStorage.setItem("globalFilterObjectRelationship", JSON.stringify(delimiterSepratedObject));
    this.filterService.updateFilterObject();
    if(window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
      let filterCopy = JSON.parse(localStorage.getItem("globalFilterObjectRelationshipCopy"))
      if(!this.filterService.filterObj.SubCategory || !this.filterService.filterObj.SurveyID ||this.filterService.filterObj.SurveyInstanceID) {
        this.filterService.filterObj.SubCategory = filterCopy.SubCategory;
        this.filterService.filterObj.SurveyID = filterCopy.SurveyID;
        this.filterService.filterObj.SurveyInstanceID = filterCopy.SurveyInstanceID;
      }
    } 
    this.setPinnedFilterValue();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.submitted.emit(delimiterSepratedObject);
  }

  submitDatefilter() {
    this.successMsg = false;
    var startDate = null;
    var endDate = null;
    var periodType = "";
    localStorage.setItem('dateSubmitted','true');
    if (this.isPeriodType) {
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          if (this.periodType[key] == true) {
            periodType = key;
          }
        }
      }
    }
    else {
      var startDateFormat = this.changeDateFormat(this.startDateCal);
      var startDateVal = new Date(startDateFormat);
      var endDateFormat = this.changeDateFormat(this.endDateCal);
      var endDateVal = new Date(endDateFormat);

      if (moment(startDateVal) <= moment(endDateVal)) {
        this.StartDate = this.startDateCal;
        startDate = this.startDateCal;
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
        return;
      }
      if (moment(endDateVal) >= moment(startDateVal)) {
        this.EndDate = this.endDateCal;
        endDate = this.endDateCal;
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be less than Start Date');
        return;
      }
    }
    //  {
    //   startDate = this.StartDate;
    //   endDate = this.EndDate;
    // }

    var filterObject = {
      "StartDate": startDate,
      "EndDate": endDate,
      "PeriodType": periodType,
    }
    localStorage.setItem("periodFilterObjectRelationship", JSON.stringify(filterObject));
    this.filterService.updateFilterObject();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    if(localStorage.getItem('subcat'))
      this.filterService.filterObj.SubCategory = localStorage.getItem('subcat');
    if(this.filterService.filterObj && this.filterService.filterObj.SubCategory== null) {
      this.filterService.filterObj.SubCategory ='DIST'
    }
    if(!window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
      this.filterService.filterObj = {...this.filterService.filterObj,...filterObject}
      localStorage.setItem("globalFilterObjectRelationship",JSON.stringify(this.filterService.filterObj))
    }
      
    this.submitted.emit(this.filterService.filterObj);
  }

  saveMasterFilter(isDate = false) {
    var filterObject = null;
    if (isDate) {
      var startDate = null;
      var endDate = null;
      var periodType = "";

      if (this.isPeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (this.periodType[key] == true) {
              periodType = key;
            }
          }
        }
      }
      else {
        var startDateFormat = this.changeDateFormat(this.startDateCal);
        var startDateVal = new Date(startDateFormat);
        var endDateFormat = this.changeDateFormat(this.endDateCal);
        var endDateVal = new Date(endDateFormat);

        if (moment(startDateVal) <= moment(endDateVal)) {
          this.StartDate = this.startDateCal;
          startDate = this.startDateCal;
        }
        else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
          return;
        }
        if (moment(endDateVal) >= moment(startDateVal)) {
          endDate = this.endDateCal;
          this.EndDate = this.endDateCal;
        }
        else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');
          return;
        }
      }

      filterObject = {
        "StartDate": startDate,
        "EndDate": endDate,
        "PeriodType": periodType,
      }
    }
    else {
      this.reomveExtraIds();
      filterObject = this.convertToDelimiterSeparated(this.delimiter);
      var piinedFieldsArray = [];
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          if (this.pinnedFields[key] == true) {
            piinedFieldsArray.push(key);
          }
        }
      }
      filterObject["PinnedField"] = piinedFieldsArray.join("|");
    }
    var filterObjectString = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"));
    if(filterObjectString.SubCategory ) {
      filterObject['SubCategory'] = filterObjectString.SubCategory
    }
    let selecetdSurvey = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"));
    let subCat =selecetdSurvey.SubCategory;
    if(!window.location.href.includes("Distributor_Survey/dashboard/distributor")) {
      subCat = this.survey;
    }  
    this.filterService.saveUserFilter(filterObject, isDate, subCat).then(res => {
      if (res !== null && res.Status == "Success") {
        if (isDate) {
          //filterObject = this.filterService.filterObj;
          localStorage.setItem("periodFilterObjectRelationship", JSON.stringify(filterObject));
          this.filterDateToggle = false;
        } else {
          localStorage.setItem("globalFilterObjectRelationship", JSON.stringify(filterObject));
          this.filterToggle = false;
        }
        this.filterService.updateFilterObject();
        this.setPinnedFilterValue();
        this.submitted.emit(this.filterService.filterObj);
        this.pinFilterSecVisible = false;
        this.saved.emit("Success");
      }
      else {

        this.saved.emit("Failure");
      }
    }).catch(e => {

      this.saved.emit("ApiError");
    });
  }

  clearUserFilter() {
    let selecetdSurvey = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"));
    this.filterService.clearUserFilter(selecetdSurvey.SubCategory).then(res => {
      if (res && res.Status == "Success" && res.Response === true) {
        this.getViewData();
        this.setPinnedFilterValue();

        var date = new Date();
        var fromDate = this.changeDateFormate(new Date(date.getFullYear(), date.getMonth(), 1));
        var toDate = this.changeDateFormate(date);

        this.isDateRange = true;
        this.isPeriodType = true;
        this.periodType.Q = false;
        this.periodType.H = false;
        this.periodType.Y = false;
        this.periodType.M = false;


        this.StartDate = fromDate;
        this.EndDate = toDate;
        this.startDateCal = this.StartDate;
        this.endDateCal = this.EndDate;

        for (var key in this.filterService.filterObj) {
          if (this.filterService.filterObj.hasOwnProperty(key)) {
            this.filterService.filterObj[key] = ""
          }
        }

        this.filterService.filterObj.StartDate = fromDate;
        this.filterService.filterObj.EndDate = toDate;

        this.filterService.filterObj
        var filterObjMain = {
          "BACode": "",
          "DivCode": "",
          "FAMCode": "",
          "BrandName": "",
          "Country": "",
          "OperationalCountry": "",
          "Zone": "",
          "Branch": "",
          "Influencer": "",
          "Continent": "",
          "Distributor": "",
          "DistributorSegment": "",
          "DistributorManagerSales": "",
          "DistributorManagerService": "",
          "PinnedField": "",
          "StartDate": fromDate,
          "EndDate": toDate,
          "PeriodType": ""
        }
        var filterObjDate = {
          "StartDate": fromDate,
          "EndDate": toDate,
          "PeriodType": ""
        }
        this.filterService.filterObj.StartDate = fromDate;
        this.filterService.filterObj.EndDate = toDate;
        localStorage.setItem("globalFilterObjectRelationship", JSON.stringify(filterObjMain));
        localStorage.setItem("periodFilterObjectRelationship", JSON.stringify(filterObjDate));

        this.callErrorMsg('success', '', 'Saved filter cleared successfully.');
      }
      else {
        this.callErrorMsg('error', '', 'Unable to clear saved filter.');
      }
    });
  }

  setPinnedFilterValue() {
    this.pinnedFieldValuesArray.famPinnedValue = [];
    this.pinnedFieldValuesArray.baPinnedValue = [];
    this.pinnedFieldValuesArray.divPinnedValue = [];
    this.pinnedFieldValuesArray.countryPinnedValue = [];
    this.pinnedFieldValuesArray.brandPinnedValue = [];
    this.pinnedFieldValuesArray.zonePinnedValue = [];
    this.pinnedFieldValuesArray.branchPinnedValue = [];
    this.pinnedFieldValuesArray.influencerPinnedValue = [];
    this.pinnedFieldValuesArray.continentPinnedValue = [];

    this.pinnedFieldValuesArray.operationalCountryPinnedValue = [];
    this.pinnedFieldValuesArray.distributorSegmentPinnedValue = [];
    this.pinnedFieldValuesArray.distributorPinnedValue = [];
    this.pinnedFieldValuesArray.industrySegmentPinnedValue = [];
    this.pinnedFieldValuesArray.distributorManagerSalesPinnedValue = [];
    this.pinnedFieldValuesArray.distributorManagerServicePinnedValue = [];

    if (this.filterMapping.FAMId !== null && this.filterMapping.FAMId.length > 0) {
      var codes = this.famData.filter(ele => {
        if (this.filterMapping.FAMId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.famPinnedValue = this.filterMapping.FAMId.length == 1 ? codes[0].toString() : (this.filterMapping.FAMId.length == this.countOfAllData.famCuunt ? "All" : codes.length);
      this.pinnedFieldValuesArray.famPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.famPinnedValue = "All";
    }

    if (this.filterMapping.BAId !== null && this.filterMapping.BAId.length > 0) {
      var codes = this.baData.filter(ele => {
        if (this.filterMapping.BAId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.baPinnedValue = this.filterMapping.BAId.length == 1 ? codes[0].toString() : (this.filterMapping.BAId.length == this.countOfAllData.baCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.baPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.baPinnedValue = "All";
    }

    if (this.filterMapping.DIVId !== null && this.filterMapping.DIVId.length > 0) {
      var codes = this.divData.filter(ele => {
        if (this.filterMapping.DIVId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.divPinnedValue = this.filterMapping.DIVId.length == 1 ? codes[0].toString() : (this.filterMapping.DIVId.length == this.countOfAllData.divCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.divPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.divPinnedValue = "All";
    }

    if (this.filterMapping.CountryId !== null && this.filterMapping.CountryId.length > 0) {
      var codes = this.countryData.filter(ele => {
        if (this.filterMapping.CountryId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.countryPinnedValue = this.filterMapping.CountryId.length == 1 ? codes[0].toString() : (this.filterMapping.CountryId.length == this.countOfAllData.countryCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.countryPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.countryPinnedValue = "All";
    }

    if (this.filterMapping.OperationalCountryId !== null && this.filterMapping.OperationalCountryId.length > 0) {
      var codes = this.operationalCountryData.filter(ele => {
        if (this.filterMapping.OperationalCountryId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.operationalCountryPinnedValue = this.filterMapping.OperationalCountryId.length == 1 ? codes[0].toString() : (this.filterMapping.OperationalCountryId.length == this.countOfAllData.operationalCountryCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.operationalCountryPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.operationalCountryPinnedValue = "All";
    }

    if (this.filterMapping.BrandId !== null && this.filterMapping.BrandId.length > 0) {
      var codes = this.brandData.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.brandPinnedValue = this.filterMapping.BrandId.length == 1 ? codes[0].toString() : (this.filterMapping.BrandId.length == this.countOfAllData.brandCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.brandPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.brandPinnedValue = "All";
    }

    if (this.filterMapping.ZoneId !== null && this.filterMapping.ZoneId.length > 0) {
      var codes = this.zoneData.filter(ele => {
        if (this.filterMapping.ZoneId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.zonePinnedValue = this.filterMapping.ZoneId.length == 1 ? codes[0].toString() : (this.filterMapping.ZoneId.length == this.countOfAllData.zoneCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.zonePinnedValue = codes;
    } else {
      this.pinnedFieldValuess.zonePinnedValue = "All";
    }

    if (this.filterMapping.BranchId !== null && this.filterMapping.BranchId.length > 0) {
      var codes = this.branchData.filter(ele => {
        if (this.filterMapping.BranchId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.branchPinnedValue = this.filterMapping.BranchId.length == 1 ? codes[0].toString() : (this.filterMapping.BranchId.length == this.countOfAllData.branchCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.branchPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.branchPinnedValue = "All";
    }

    if (this.filterMapping.InfluencerId !== null && this.filterMapping.InfluencerId.length > 0) {
      var codes = this.influencerData.filter(ele => {
        if (this.filterMapping.InfluencerId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.influencerPinnedValue = this.filterMapping.InfluencerId.length == 1 ? codes[0].toString() : (this.filterMapping.InfluencerId.length == this.countOfAllData.influencerCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.influencerPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.influencerPinnedValue = "All";
    }

    if (this.filterMapping.ContinentId !== null && this.filterMapping.ContinentId.length > 0) {
      var codes = this.countinentData.filter(ele => {
        if (this.filterMapping.ContinentId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.continentPinnedValue = this.filterMapping.ContinentId.length == 1 ? codes[0].toString() : (this.filterMapping.ContinentId.length == this.countOfAllData.continentCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.continentPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.continentPinnedValue = "All";
    }


    if (this.filterMapping.DistributorId !== null && this.filterMapping.DistributorId.length > 0) {
      var codes = this.wholeDistributorData.filter(ele => {
        if (this.filterMapping.DistributorId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.distributorPinnedValue = this.filterMapping.DistributorId.length == 1 ? codes[0].toString() : (this.filterMapping.ContinentId.length == this.countOfAllData.continentCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.distributorPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.distributorPinnedValue = "All";
    }

    if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length > 0) {
      var codes = this.industrySegmentData.filter(ele => {
        if (this.filterMapping.IndustrySegmentId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.industrySegmentPinnedValue = this.filterMapping.IndustrySegmentId.length == 1 ? codes[0].toString() : (this.filterMapping.ContinentId.length == this.countOfAllData.continentCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.industrySegmentPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.industrySegmentPinnedValue = "All";
    }

    if (this.filterMapping.DistributorSegmentId !== null && this.filterMapping.DistributorSegmentId.length > 0) {
      var codes = this.distributorSegmentData.filter(ele => {
        if (this.filterMapping.DistributorSegmentId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.distributorSegmentPinnedValue = this.filterMapping.DistributorSegmentId.length == 1 ? codes[0].toString() : (this.filterMapping.ContinentId.length == this.countOfAllData.continentCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.distributorSegmentPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.distributorSegmentPinnedValue = "All";
    }

    if (this.filterMapping.DistributorManagerSalesId !== null && this.filterMapping.DistributorManagerSalesId.length > 0) {
      var codes = this.distributorManagerSalesData.filter(ele => {
        if (this.filterMapping.DistributorManagerSalesId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.distributorManagerSalesPinnedValue = this.filterMapping.DistributorManagerSalesId.length == 1 ? codes[0].toString() : (this.filterMapping.DistributorManagerSalesId.length == this.countOfAllData.distributorManagerSalesCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.distributorManagerSalesPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.distributorManagerSalesPinnedValue = "All";
    }

    if (this.filterMapping.DistributorManagerServiceId !== null && this.filterMapping.DistributorManagerServiceId.length > 0) {
      var codes = this.distributorManagerServiceData.filter(ele => {
        if (this.filterMapping.DistributorManagerServiceId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.distributorManagerServicePinnedValue = this.filterMapping.DistributorManagerServiceId.length == 1 ? codes[0].toString() : (this.filterMapping.DistributorManagerServiceId.length == this.countOfAllData.distributorManagerServiceCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.distributorManagerServicePinnedValue = codes;
    } else {
      this.pinnedFieldValuess.distributorManagerServicePinnedValue = "All";
    }

    if (this.filterService.filterObj.PinnedField !== null && this.filterService.filterObj.PinnedField !== "") {
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          this.pinnedFields[key] = false;
          if (this.filterService.filterObj.PinnedField.split(this.delimiter).indexOf(key) >= 0) {
            this.pinnedFields[key] = true;
          }
        }
      }
    }


  }

  reomveExtraIds(displayOnly = false) {

    var famids = [];
    var baids = [];
    var divids = [];
    var blids = [];
    var countryids = [];
    var operationalcountryids = [];
    var brandids = [];
    var zoneids = [];
    var branchids = [];
    var salesids = [];
    var serviceids = [];
    var continentids = [];
    var regionids = [];
    var distributormanagersalesids = [];
    var distributormanagerserviceids = [];

    var distributorids = [];
    var distributorSegmentids = [];
    var industrySegmentids = [];

    if (this.filterMapping.FAMId !== null && this.filterMapping.FAMId.length > 0) {
      this.filterMapping.FAMId.forEach(element => {
        if (this.famData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          famids.push(element);
        }
      });
    }

    if (this.filterMapping.BAId !== null && this.filterMapping.BAId.length > 0) {
      this.filterMapping.BAId.forEach(element => {
        if (this.baData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          baids.push(element);
        }
      });
    }

    if (this.filterMapping.DIVId !== null && this.filterMapping.DIVId.length > 0) {
      this.filterMapping.DIVId.forEach(element => {
        if (this.divData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          divids.push(element);
        }
      });
    }

    if (this.filterMapping.BrandId !== null && this.filterMapping.BrandId.length > 0) {
      this.filterMapping.BrandId.forEach(element => {
        if (this.brandData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          brandids.push(element);
        }
      });
    }

    if (this.filterMapping.CountryId !== null && this.filterMapping.CountryId.length > 0) {
      this.filterMapping.CountryId.forEach(element => {
        if (this.countryData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          countryids.push(element);
        }
      });

    }

    if (this.filterMapping.OperationalCountryId !== null && this.filterMapping.OperationalCountryId.length > 0) {
      this.filterMapping.OperationalCountryId.forEach(element => {
        if (this.operationalCountryData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          operationalcountryids.push(element);
        }
      });

    }

    if (this.filterMapping.ContinentId !== null && this.filterMapping.ContinentId.length > 0) {
      this.filterMapping.ContinentId.forEach(element => {
        if (this.countinentData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          continentids.push(element);
        }
      });

    }

    if (this.filterMapping.ZoneId !== null && this.filterMapping.ZoneId.length > 0) {
      this.filterMapping.ZoneId.forEach(element => {
        if (this.zoneData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          zoneids.push(element);
        }
      });
    }

    if (this.filterMapping.BranchId !== null && this.filterMapping.BranchId.length > 0) {
      this.filterMapping.BranchId.forEach(element => {
        if (this.branchData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          branchids.push(element);
        }
      });
    }


    if (this.filterMapping.DistributorId !== null && this.filterMapping.DistributorId.length > 0) {
      this.filterMapping.DistributorId.forEach(element => {
        if (this.distributorData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          distributorids.push(element);
        }
      });
    }
    if (this.filterMapping.DistributorSegmentId !== null && this.filterMapping.DistributorSegmentId.length > 0) {
      this.filterMapping.DistributorSegmentId.forEach(element => {
        if (this.distributorSegmentData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          distributorSegmentids.push(element);
        }
      });
    }

    if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length > 0) {
      this.filterMapping.IndustrySegmentId.forEach(element => {
        if (this.industrySegmentData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          industrySegmentids.push(element);
        }
      });
    }

    if (this.filterMapping.DistributorManagerSalesId !== null && this.filterMapping.DistributorManagerSalesId.length > 0) {
      this.filterMapping.DistributorManagerSalesId.forEach(element => {
        if (this.distributorManagerSalesData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          distributormanagersalesids.push(element);
        }
      });
    }

    if (this.filterMapping.DistributorManagerServiceId !== null && this.filterMapping.DistributorManagerServiceId.length > 0) {
      this.filterMapping.DistributorManagerServiceId.forEach(element => {
        if (this.distributorManagerServiceData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          distributormanagerserviceids.push(element);
        }
      });
    }

    if (displayOnly) {

      this.selectedCount.famCount = this.famData.filter(ele => { if (famids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.baCount = this.baData.filter(ele => { if (baids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.divCount = this.divData.filter(ele => { if (divids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.countryCount = this.countryData.filter(ele => { if (countryids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.operationalCountryCount = this.operationalCountryData.filter(ele => { if (operationalcountryids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.brandCount = this.brandData.filter(ele => { if (brandids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.zoneCount = this.zoneData.filter(ele => { if (zoneids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.branchCount = this.branchData.filter(ele => { if (branchids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.continentCount = this.countinentData.filter(ele => { if (continentids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.distributorCount = this.distributorData.filter(ele => { if (distributorids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.distributorSegmentCount = this.distributorSegmentData.filter(ele => { if (distributorSegmentids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.industrySegmentCount = this.industrySegmentData.filter(ele => { if (industrySegmentids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.influencerCount = this.influencerData.filter(ele => { if (this.filterMapping.InfluencerId.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.distributorManagerSalesCount = this.distributorManagerSalesData.filter(ele => { if (distributormanagersalesids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.distributorManagerServiceCount = this.distributorManagerServiceData.filter(ele => { if (distributormanagerserviceids.indexOf(ele.value) >= 0) { return ele } });
    } else {
      this.filterMapping.FAMId = famids;
      this.filterMapping.BAId = baids;
      this.filterMapping.DIVId = divids;
      this.filterMapping.BLId = blids;
      this.filterMapping.CountryId = countryids;
      this.filterMapping.OperationalCountryId = operationalcountryids;
      this.filterMapping.BrandId = brandids;
      this.filterMapping.ZoneId = zoneids;
      this.filterMapping.BranchId = branchids;
      this.filterMapping.SalesEngId = salesids;
      this.filterMapping.ServiceTechId = serviceids;
      this.filterMapping.ContinentId = continentids;
      this.filterMapping.regionId = regionids;
      this.filterMapping.DistributorId = distributorids;
      this.filterMapping.DistributorSegmentId = distributorSegmentids;
      this.filterMapping.IndustrySegmentId = industrySegmentids;
      this.filterMapping.DistributorManagerSalesId = distributormanagersalesids;
      this.filterMapping.DistributorManagerServiceId = distributormanagerserviceids;
      // this.filterMapping.IndustrySegmentId = industrySegmentids;
    }

  }

  togglePin(fieldName) {
    var pinnedCount = 0;

    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          pinnedCount++;
        }
      }
    }

    if (pinnedCount <= 3) {
      switch (fieldName) {
        case "fam": {
          this.pinnedFields.famPinned = !this.pinnedFields.famPinned;
          break;
        }
        case "ba": {
          this.pinnedFields.baPinned = !this.pinnedFields.baPinned;
          break;
        }
        case "div": {
          this.pinnedFields.divPinned = !this.pinnedFields.divPinned;
          break;
        }
        case "country": {
          this.pinnedFields.countryPinned = !this.pinnedFields.countryPinned;
          break;
        }
        case "operationalcountry": {
          this.pinnedFields.operationalCountryPinned = !this.pinnedFields.operationalCountryPinned;
          break;
        }
        case "brand": {
          this.pinnedFields.brandPinned = !this.pinnedFields.brandPinned;
          break;
        }
        case "zone": {
          this.pinnedFields.zonePinned = !this.pinnedFields.zonePinned;
          break;
        }
        case "branch": {
          this.pinnedFields.branchPinned = !this.pinnedFields.branchPinned;
          break;
        }
        case "influencer": {
          this.pinnedFields.influencerPinned = !this.pinnedFields.influencerPinned;
          break;
        }
        case "continent": {
          this.pinnedFields.continentPinned = !this.pinnedFields.continentPinned;
          break;
        }

        case "distributor": {
          this.pinnedFields.distributorPinned = !this.pinnedFields.distributorPinned;
          break;
        }
        case "industrysegment": {
          this.pinnedFields.industrySegmentPinned = !this.pinnedFields.industrySegmentPinned;
          break;
        }
        case "distributorsegment": {
          this.pinnedFields.distributorSegmentPinned = !this.pinnedFields.distributorSegmentPinned;
          break;
        }

        case "distributormanagersales": {
          this.pinnedFields.distributorManagerSalesPinned = !this.pinnedFields.distributorManagerSalesPinned;
          break;
        }
        case "distributormanagerservice": {
          this.pinnedFields.distributorManagerServicePinned = !this.pinnedFields.distributorManagerServicePinned;
          break;
        }
      }
    }
    else {

      switch (fieldName) {
        case "fam": {
          this.isShowAlert = !this.pinnedFields.famPinned;
          this.pinnedFields.famPinned = this.pinnedFields.famPinned ? !this.pinnedFields.famPinned : this.pinnedFields.famPinned;
          break;
        }
        case "ba": {
          this.isShowAlert = !this.pinnedFields.baPinned;
          this.pinnedFields.baPinned = this.pinnedFields.baPinned ? !this.pinnedFields.baPinned : this.pinnedFields.baPinned;
          break;
        }
        case "div": {
          this.isShowAlert = !this.pinnedFields.divPinned;
          this.pinnedFields.divPinned = this.pinnedFields.divPinned ? !this.pinnedFields.divPinned : this.pinnedFields.divPinned;
          break;
        }
        case "country": {
          this.isShowAlert = !this.pinnedFields.countryPinned;
          this.pinnedFields.countryPinned = this.pinnedFields.countryPinned ? !this.pinnedFields.countryPinned : this.pinnedFields.countryPinned;
          break;
        }
        case "operationalCountry": {
          this.isShowAlert = !this.pinnedFields.operationalCountryPinned;
          this.pinnedFields.operationalCountryPinned = this.pinnedFields.operationalCountryPinned ? !this.pinnedFields.operationalCountryPinned : this.pinnedFields.operationalCountryPinned;
          break;
        }
        case "brand": {
          this.isShowAlert = !this.pinnedFields.brandPinned;
          this.pinnedFields.brandPinned = this.pinnedFields.brandPinned ? !this.pinnedFields.brandPinned : this.pinnedFields.brandPinned;
          break;
        }
        case "zone": {
          this.isShowAlert = !this.pinnedFields.zonePinned;
          this.pinnedFields.zonePinned = this.pinnedFields.zonePinned ? !this.pinnedFields.zonePinned : this.pinnedFields.zonePinned;
          break;
        }
        case "branch": {
          this.isShowAlert = !this.pinnedFields.branchPinned;
          this.pinnedFields.branchPinned = this.pinnedFields.branchPinned ? !this.pinnedFields.branchPinned : this.pinnedFields.branchPinned;
          break;
        }
        case "influencer": {
          this.isShowAlert = !this.pinnedFields.influencerPinned;
          this.pinnedFields.influencerPinned = this.pinnedFields.influencerPinned ? !this.pinnedFields.influencerPinned : this.pinnedFields.influencerPinned;
          break;
        }
        case "continent": {
          this.isShowAlert = !this.pinnedFields.continentPinned;
          this.pinnedFields.continentPinned = this.pinnedFields.continentPinned ? !this.pinnedFields.continentPinned : this.pinnedFields.continentPinned;
          break;
        }
        case "distributor": {
          this.isShowAlert = !this.pinnedFields.distributorPinned;
          this.pinnedFields.distributorPinned = this.pinnedFields.distributorPinned ? !this.pinnedFields.distributorPinned : this.pinnedFields.distributorPinned;
          break;
        }
        case "industrysegment": {
          this.isShowAlert = !this.pinnedFields.industrySegmentPinned;
          this.pinnedFields.industrySegmentPinned = this.pinnedFields.industrySegmentPinned ? !this.pinnedFields.industrySegmentPinned : this.pinnedFields.industrySegmentPinned;
          break;
        }
        case "distributorsegment": {
          this.isShowAlert = !this.pinnedFields.distributorSegmentPinned;
          this.pinnedFields.distributorSegmentPinned = this.pinnedFields.distributorSegmentPinned ? !this.pinnedFields.distributorSegmentPinned : this.pinnedFields.distributorSegmentPinned;
          break;
        }
        case "distributorsegment": {
          this.isShowAlert = !this.pinnedFields.distributorSegmentPinned;
          this.pinnedFields.distributorSegmentPinned = this.pinnedFields.distributorSegmentPinned ? !this.pinnedFields.distributorSegmentPinned : this.pinnedFields.distributorSegmentPinned;
          break;
        }
        case "distributormanagersales": {
          this.isShowAlert = !this.pinnedFields.distributorManagerSalesPinned;
          this.pinnedFields.distributorManagerSalesPinned = this.pinnedFields.distributorManagerSalesPinned ? !this.pinnedFields.distributorManagerSalesPinned : this.pinnedFields.distributorManagerSalesPinned;
          break;
        }

        case "distributormanagerservice": {
          this.isShowAlert = !this.pinnedFields.distributorManagerServicePinned;
          this.pinnedFields.distributorManagerServicePinned = this.pinnedFields.distributorManagerServicePinned ? !this.pinnedFields.distributorManagerServicePinned : this.pinnedFields.distributorManagerServicePinned;
          break;
        }
      }


      if (this.isShowAlert) {
        this.successMsg = true;
        this.callErrorMsg('info', '', 'You can select only 4 quick filters.');
      }

    }


    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }

    var valueFromSession = localStorage.getItem("globalFilterObjectRelationship");
    var delimiterSepratedObject = this.filterService.filterObj
    if (valueFromSession !== null) {
      delimiterSepratedObject = JSON.parse(localStorage.getItem("globalFilterObjectRelationship"))
    }
    delimiterSepratedObject["PinnedField"] = piinedFieldsArray.join("|");

    // localStorage.setItem("globalFilterObjectRelationship", JSON.stringify(delimiterSepratedObject));
    this.filterService.updateFilterObject();

    this.pinChanged.emit(this.pinnedFields);
  }

  toggleFilter() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('global_fillter')
    this.clearMessage();
    this.filterToggle = !this.filterToggle;

    if (this.filterToggle) {
      this.convertToArray();
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then(res => {
        this.showBusyIndicator = false;
      });
    }
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
  }

  toggleDateFilter() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('global_fillter')
    this.filterDateToggle = !this.filterDateToggle;
    if (this.filterDateToggle) {
      this.convertToArray();
    }
    this.filterToggle = false;
    this.pinFilterSecVisible = false;
  }

  closeInlineFilter() {
    this.pinFilterSecVisible = false;
  }

  toggleInlineFilter(fieldName) {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('global_fillter')
    this.pinFilterSecVisible = !this.pinFilterSecVisible

    if (this.pinFilterSecVisible) {
      this.convertToArray();

      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then(res => {
        this.showBusyIndicator = false;
      });
    }

    for (var key in this.inlineFilter) {
      if (this.inlineFilter.hasOwnProperty(key)) {
        this.inlineFilter[key] = false;
        if (key == fieldName) {
          this.inlineFilter[key] = true;
        }
      }
    }

    this.filterDateToggle = false;
    this.filterToggle = false;
  }
  applyfilterOnLabel() {
    this.reomveExtraIds(true);
    if (this.filterMapping.FAMId !== null && this.filterMapping.FAMId.length >= 4) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.length + " items selected";
      }
      else if (this.famDDLPinned) {
        this.famDDLPinned.valuesAsString = this.selectedCount.famCount.length + " items selected";
      }

    }
    else if (this.filterMapping.FAMId !== null && this.filterMapping.FAMId.length < 4 && this.filterMapping.FAMId.length >= 1) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.famDDLPinned) {
        this.famDDLPinned.valuesAsString = this.selectedCount.famCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BAId !== null && this.filterMapping.BAId.length >= 4) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.length + " items selected";
      }
      else if (this.baDDLPinned) {
        this.baDDLPinned.valuesAsString = this.selectedCount.baCount.length + " items selected";
      }

    }
    else if (this.filterMapping.BAId !== null && this.filterMapping.BAId.length < 4 && this.filterMapping.BAId.length >= 1) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.baDDLPinned) {
        this.baDDLPinned.valuesAsString = this.selectedCount.baCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.DIVId !== null && this.filterMapping.DIVId.length >= 4) {
      if (this.divDDL) {
        this.divDDL.valuesAsString = this.selectedCount.divCount.length + " items selected";
      }
      else if (this.divDDLPinned) {
        this.divDDLPinned.valuesAsString = this.selectedCount.divCount.length + " items selected";
      }

    }
    else if (this.filterMapping.DIVId !== null && this.filterMapping.DIVId.length < 4 && this.filterMapping.DIVId.length >= 1) {
      if (this.divDDL) {
        this.divDDL.valuesAsString = this.selectedCount.divCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.divDDLPinned) {
        this.divDDLPinned.valuesAsString = this.selectedCount.divCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.CountryId !== null && this.filterMapping.CountryId.length >= 4) {
      if (this.countryDDL) {
        this.countryDDL.valuesAsString = this.selectedCount.countryCount.length + " items selected";
      }
      else if (this.countryDDLPinned) {
        this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount.length + " items selected";
      }

    }
    else if (this.filterMapping.CountryId !== null && this.filterMapping.CountryId.length < 4 && this.filterMapping.CountryId.length >= 1) {
      if (this.countryDDL) {
        this.countryDDL.valuesAsString = this.selectedCount.countryCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.countryDDLPinned) {
        this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.OperationalCountryId !== null && this.filterMapping.OperationalCountryId.length >= 4) {
      if (this.operationalCountryDDL) {
        this.operationalCountryDDL.valuesAsString = this.selectedCount.operationalCountryCount.length + " items selected";
      }
      else if (this.operationalCountryDDLPinned) {
        this.operationalCountryDDLPinned.valuesAsString = this.selectedCount.operationalCountryCount.length + " items selected";
      }

    }
    else if (this.filterMapping.OperationalCountryId !== null && this.filterMapping.OperationalCountryId.length < 4 && this.filterMapping.OperationalCountryId.length >= 1) {
      if (this.operationalCountryDDL) {
        this.operationalCountryDDL.valuesAsString = this.selectedCount.operationalCountryCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.operationalCountryDDLPinned) {
        this.operationalCountryDDLPinned.valuesAsString = this.selectedCount.operationalCountryCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BrandId !== null && this.filterMapping.BrandId.length >= 4) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.length + " items selected";
      } else if (this.brandDDLPinned) {
        this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount.length + " items selected";
      }
    }
    else if (this.filterMapping.BrandId !== null && this.filterMapping.BrandId.length < 4 && this.filterMapping.BrandId.length >= 1) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.map(ele => { return ele.label }).join(", ");
      } else if (this.brandDDLPinned) {
        this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount.map(ele => { return ele.label }).join(", ")
      }
    }

    if (this.filterMapping.ZoneId !== null && this.filterMapping.ZoneId.length >= 4) {
      if (this.zoneDDL) {
        this.zoneDDL.valuesAsString = this.selectedCount.zoneCount.length + " items selected";
      }
      else if (this.zoneDDLPinned) {
        this.zoneDDLPinned.valuesAsString = this.selectedCount.zoneCount.length + " items selected";
      }

    }
    else if (this.filterMapping.ZoneId !== null && this.filterMapping.ZoneId.length < 4 && this.filterMapping.ZoneId.length >= 1) {
      if (this.zoneDDL) {
        this.zoneDDL.valuesAsString = this.selectedCount.zoneCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.zoneDDLPinned) {
        this.zoneDDLPinned.valuesAsString = this.selectedCount.zoneCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BranchId !== null && this.filterMapping.BranchId.length >= 4) {
      if (this.branchDDL) {
        this.branchDDL.valuesAsString = this.selectedCount.branchCount.length + " items selected";
      }
      else if (this.branchDDLPinned) {
        this.branchDDLPinned.valuesAsString = this.selectedCount.branchCount.length + " items selected";
      }

    }
    else if (this.filterMapping.BranchId !== null && this.filterMapping.BranchId.length < 4 && this.filterMapping.BranchId.length >= 1) {
      if (this.branchDDL) {
        this.branchDDL.valuesAsString = this.selectedCount.branchCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.branchDDLPinned) {
        this.branchDDLPinned.valuesAsString = this.selectedCount.branchCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.ContinentId !== null && this.filterMapping.ContinentId.length >= 4) {
      if (this.continentDDL) {
        this.continentDDL.valuesAsString = this.selectedCount.continentCount.length + " items selected";
      }
      else if (this.continentDDLPinned) {
        this.continentDDLPinned.valuesAsString = this.selectedCount.continentCount.length + " items selected";
      }

    }
    else if (this.filterMapping.ContinentId !== null && this.filterMapping.ContinentId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.continentDDL) {
        this.continentDDL.valuesAsString = this.selectedCount.continentCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.continentDDLPinned) {
        this.continentDDLPinned.valuesAsString = this.selectedCount.continentCount.map(ele => { return ele.label }).join(", ");
      }
    }



    if (this.filterMapping.DistributorId !== null && this.filterMapping.DistributorId.length >= 4) {
      if (this.distributorDDL) {
        this.distributorDDL.valuesAsString = this.selectedCount.distributorCount.length + " items selected";
      } else if (this.distributorDDLPinned) {
        this.distributorDDLPinned.valuesAsString = this.selectedCount.distributorCount.length + " items selected";
      }

    } else if (this.filterMapping.DistributorId !== null && this.filterMapping.DistributorId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.distributorDDL) {
        this.distributorDDL.valuesAsString = this.selectedCount.distributorCount.map(ele => { return ele.label }).join(", ");
      } else if (this.distributorDDLPinned) {
        this.distributorDDLPinned.valuesAsString = this.selectedCount.distributorCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.DistributorSegmentId !== null && this.filterMapping.DistributorSegmentId.length >= 4) {
      if (this.distributorSegmentDDL) {
        this.distributorSegmentDDL.valuesAsString = this.selectedCount.distributorSegmentCount.length + " items selected";
      } else if (this.distributorSegmentDDLPinned) {
        this.distributorSegmentDDLPinned.valuesAsString = this.selectedCount.distributorSegmentCount.length + " items selected";
      }

    } else if (this.filterMapping.DistributorSegmentId !== null && this.filterMapping.DistributorSegmentId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.distributorSegmentDDL) {
        this.distributorSegmentDDL.valuesAsString = this.selectedCount.distributorSegmentCount.map(ele => { return ele.label }).join(", ");
      } else if (this.distributorSegmentDDLPinned) {
        this.distributorSegmentDDLPinned.valuesAsString = this.selectedCount.distributorSegmentCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length >= 4) {
      if (this.industrySegmentDDL) {
        this.industrySegmentDDL.valuesAsString = this.selectedCount.industrySegmentCount.length + " items selected";
      } else if (this.industrySegmentDDLPinned) {
        this.industrySegmentDDLPinned.valuesAsString = this.selectedCount.industrySegmentCount.length + " items selected";
      }

    } else if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.industrySegmentDDL) {
        this.industrySegmentDDL.valuesAsString = this.selectedCount.industrySegmentCount.map(ele => { return ele.label }).join(", ");
      } else if (this.industrySegmentDDLPinned) {
        this.industrySegmentDDLPinned.valuesAsString = this.selectedCount.industrySegmentCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length >= 4) {
      if (this.industrySegmentDDL) {
        this.industrySegmentDDL.valuesAsString = this.selectedCount.industrySegmentCount.length + " items selected";
      } else if (this.industrySegmentDDLPinned) {
        this.industrySegmentDDLPinned.valuesAsString = this.selectedCount.industrySegmentCount.length + " items selected";
      }

    } else if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.industrySegmentDDL) {
        this.industrySegmentDDL.valuesAsString = this.selectedCount.industrySegmentCount.map(ele => { return ele.label }).join(", ");
      } else if (this.industrySegmentDDLPinned) {
        this.industrySegmentDDLPinned.valuesAsString = this.selectedCount.industrySegmentCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length >= 4) {
      if (this.industrySegmentDDL) {
        this.industrySegmentDDL.valuesAsString = this.selectedCount.industrySegmentCount.length + " items selected";
      } else if (this.industrySegmentDDLPinned) {
        this.industrySegmentDDLPinned.valuesAsString = this.selectedCount.industrySegmentCount.length + " items selected";
      }

    } else if (this.filterMapping.IndustrySegmentId !== null && this.filterMapping.IndustrySegmentId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.industrySegmentDDL) {
        this.industrySegmentDDL.valuesAsString = this.selectedCount.industrySegmentCount.map(ele => { return ele.label }).join(", ");
      } else if (this.industrySegmentDDLPinned) {
        this.industrySegmentDDLPinned.valuesAsString = this.selectedCount.industrySegmentCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.DistributorManagerSalesId !== null && this.filterMapping.DistributorManagerSalesId.length >= 4) {
      if (this.distributorManagerSalesDDL) {
        this.distributorManagerSalesDDL.valuesAsString = this.selectedCount.distributorManagerSalesCount.length + " items selected";
      } else if (this.distributorManagerSalesDDLPinned) {
        this.distributorManagerSalesDDLPinned.valuesAsString = this.selectedCount.distributorManagerSalesCount.length + " items selected";
      }

    } else if (this.filterMapping.DistributorManagerSalesId !== null && this.filterMapping.DistributorManagerSalesId.length < 4 && this.filterMapping.DistributorManagerSalesId.length >= 1) {
      if (this.distributorManagerSalesDDL) {
        this.distributorManagerSalesDDL.valuesAsString = this.selectedCount.distributorManagerSalesCount.map(ele => { return ele.label }).join(", ");
      } else if (this.distributorManagerSalesDDLPinned) {
        this.distributorManagerSalesDDLPinned.valuesAsString = this.selectedCount.distributorManagerSalesCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.DistributorManagerServiceId !== null && this.filterMapping.DistributorManagerServiceId.length >= 4) {
      if (this.distributorManagerServiceDDL) {
        this.distributorManagerServiceDDL.valuesAsString = this.selectedCount.distributorManagerServiceCount.length + " items selected";
      } else if (this.distributorManagerServiceDDLPinned) {
        this.distributorManagerServiceDDLPinned.valuesAsString = this.selectedCount.distributorManagerServiceCount.length + " items selected";
      }

    } else if (this.filterMapping.DistributorManagerServiceId !== null && this.filterMapping.DistributorManagerServiceId.length < 4 && this.filterMapping.DistributorManagerServiceId.length >= 1) {
      if (this.distributorManagerServiceDDL) {
        this.distributorManagerServiceDDL.valuesAsString = this.selectedCount.distributorManagerServiceCount.map(ele => { return ele.label }).join(", ");
      } else if (this.distributorManagerServiceDDLPinned) {
        this.distributorManagerServiceDDLPinned.valuesAsString = this.selectedCount.distributorManagerServiceCount.map(ele => { return ele.label }).join(", ");
      }
    }


  }

  removeSelectedValue(id, fieldName) {
    switch (fieldName) {
      case "fam": {
        var index = this.filterMapping.FAMId.indexOf(id);
        if (index > -1) {
          this.filterMapping.FAMId.splice(index, 1);
          this.SelectedFams(true);
          if (this.famDDL) {
            this.famDDL.updateLabel();
          } else if (this.famDDLPinned) {
            this.famDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "ba": {
        var index = this.filterMapping.BAId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BAId.splice(index, 1);
          this.SelectedBas(true);
          if (this.baDDL) {
            this.baDDL.updateLabel();
          } else if (this.baDDLPinned) {
            this.baDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "div": {
        var index = this.filterMapping.DIVId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DIVId.splice(index, 1);
          this.SelectedDivs(true);
          if (this.divDDL) {
            this.divDDL.updateLabel();
          } else if (this.divDDLPinned) {
            this.divDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "country": {
        var index = this.filterMapping.CountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.CountryId.splice(index, 1);
          this.SelectedCountries(true);
          if (this.countryDDL) {
            this.countryDDL.updateLabel();
          } else if (this.countryDDLPinned) {
            this.countryDDLPinned.updateLabel();
          }
        }
        break;
      }

      case "operationalCountry": {
        var index = this.filterMapping.OperationalCountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.OperationalCountryId.splice(index, 1);
          this.SelectedOperationalCountries(true);
          if (this.operationalCountryDDL) {
            this.operationalCountryDDL.updateLabel();
          } else if (this.operationalCountryDDLPinned) {
            this.operationalCountryDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "brand": {
        var index = this.filterMapping.BrandId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BrandId.splice(index, 1);
          this.SelectedBrands(true);
          if (this.brandDDL) {
            this.brandDDL.updateLabel();
          } else if (this.brandDDLPinned) {
            this.brandDDLPinned.updateLabel();
          }

        }
        break;
      }
      case "zone": {
        var index = this.filterMapping.ZoneId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ZoneId.splice(index, 1);
          this.SelectedZones(true);
          if (this.zoneDDL) {
            this.zoneDDL.updateLabel();
          } else if (this.zoneDDLPinned) {
            this.zoneDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "branch": {
        var index = this.filterMapping.BranchId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BranchId.splice(index, 1);
          this.SelectedBranchs(true);
          if (this.branchDDL) {
            this.branchDDL.updateLabel();
          } else if (this.branchDDLPinned) {
            this.branchDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "influencer": {
        var index = this.filterMapping.InfluencerId.indexOf(id);
        if (index > -1) {
          this.filterMapping.InfluencerId.splice(index, 1);
          this.reomveExtraIds(true);
          if (this.infDDL) {
            this.infDDL.updateLabel();
          } else if (this.infDDLPinned) {
            this.infDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "continent": {
        var index = this.filterMapping.ContinentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ContinentId.splice(index, 1);
          this.SelectedContinents(true);
          if (this.continentDDL) {
            this.continentDDL.updateLabel();
          } else if (this.continentDDLPinned) {
            this.continentDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "distributorsegment": {
        var index = this.filterMapping.DistributorSegmentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DistributorSegmentId.splice(index, 1);
          this.SelectedDistributorSegment(true);
          if (this.distributorSegmentDDL) {
            this.distributorSegmentDDL.updateLabel();
          } else if (this.distributorSegmentDDLPinned) {
            this.distributorSegmentDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "industrysegment": {
        var index = this.filterMapping.IndustrySegmentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.IndustrySegmentId.splice(index, 1);
          this.SelectedIndustrySegment(true);
          if (this.industrySegmentDDL) {
            this.industrySegmentDDL.updateLabel();
          } else if (this.industrySegmentDDLPinned) {
            this.industrySegmentDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "distributor": {
        var index = this.filterMapping.DistributorId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DistributorId.splice(index, 1);
          this.SelectedDistributor(true);
          if (this.distributorDDL) {
            this.distributorDDL.updateLabel();
          } else if (this.distributorDDLPinned) {
            this.distributorDDLPinned.updateLabel();
          }
        }
        break;
      }

      case "distributormanagersales": {
        var index = this.filterMapping.DistributorManagerSalesId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DistributorManagerSalesId.splice(index, 1);
          this.SelectedDistributorManagerSales(true);
          if (this.distributorManagerSalesDDL) {
            this.distributorManagerSalesDDL.updateLabel();
          } else if (this.distributorManagerSalesDDLPinned) {
            this.distributorManagerSalesDDLPinned.updateLabel();
          }
        }
        break;
      }

      case "distributormanagerservice": {
        var index = this.filterMapping.DistributorManagerServiceId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DistributorManagerServiceId.splice(index, 1);
          this.SelectedDistributorManagerService(true);
          if (this.distributorManagerServiceDDL) {
            this.distributorManagerServiceDDL.updateLabel();
          } else if (this.distributorManagerServiceDDLPinned) {
            this.distributorManagerServiceDDLPinned.updateLabel();
          }
        }
        break;
      }
    }
  }

  private getDistinct(list, key): any[] {

    let uniqueLists = [];
    let ids = [];
    if (key == "label") {
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element[key] !== null) {
          if (ids.indexOf(element[key].trim().toLowerCase())==-1) { 
            uniqueLists.push(element);
            ids.push(element[key].trim().toLowerCase());
          }
        }
      }
    } else {
      list.forEach(element => {
        if ((ids.indexOf(element[key])==-1) && element[key] !== null) {
          uniqueLists.push(element);
          ids.push(element[key]);
        }
      });
    }
    uniqueLists = uniqueLists.sort(function (a, b) {
      return a.label > b.label ? 1 : -1;
    })
    return uniqueLists;
  }

  private includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  private changeDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return (indexMonth + 1) +
      "/" + splitDate[0] +
      "/" + splitDate[2];
  }

  private changeDateFormate(date) {
    // Try and parse the passed value.
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }

  callErrorMsg(type, summary = '', detail = '') {
    this.successMsg = true;
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  applyCasecadingOnList(field = '') {

    let _this = this;
    return new Promise(resolve => {

      setTimeout(() => {

        this.famData = this.filterList('fam').map(x => { return { value: x.FAMID, label: x.FAMCode } });
        this.baData = this.filterList('ba').map(x => { return { value: x.BAID, label: x.BACode } });
        this.divData = this.filterList('div').map(x => { return { value: x.DivisionID, label: x.DivisionCode } });
        this.brandData = this.filterList('brand').map(x => { return { value: x.BrandID, label: x.BrandName } });
        this.countryData = this.filterList('country').map(x => { return { value: x.CountryID, label: x.CountryName } });

        this.operationalCountryData = this.filterList('operationalCountry').map(x => { return { value: x.CountryID, label: x.CountryName } });
        this.countinentData = this.filterList('continent').map(x => { return { value: x.ContinentID, label: x.ContinentName } });
        this.zoneData = this.filterList('zone').map(x => { return { value: x.ZoneCode, label: x.ZoneCode } });
        this.branchData = this.filterList('branch').map(x => { return { value: x.BranchCode, label: x.BranchCode } });
        _this.distributorData = this.filterList('distributor').map(x => { return { value: x.DistributorID, label: x.DistributorName + " (" + x.AccountNumber + ")", number: x.AccountNumber, EntityType:x.EntityType} });
        this.wholeDistributorData =  _this.distributorData;
        let surveyType =  this.survey
        if(surveyType === 'OEM' || surveyType === 'DIST') {
          _this.distributorData =  _this.distributorData.filter(data=> data.EntityType == surveyType);
        }
        this.distributorSegmentData = this.filterList('distributorsegment').map(x => { return { value: x.DistributorSegmentID, label: x.DistributorSegment } });
        this.industrySegmentData = this.filterList('industrysegment').map(x => { return { value: x.IndustrySegmentID, label: x.IndustrySegment } });
        this.distributorManagerSalesData = this.filterList('distributormanagersales').map(x => { return { value: x.DistributorManagerSalesId, label: x.ManagerName } });
        this.distributorManagerServiceData = this.filterList('distributormanagerservice').map(x => { return { value: x.DistributorManagerServiceId, label: x.ManagerName } });



        this.famData = this.getDistinct(this.famData, "value");
        this.baData = this.getDistinct(this.baData, "value");
        this.divData = this.getDistinct(this.divData, "value");
        this.brandData = this.getDistinct(this.brandData, "value");
        this.countryData = this.getDistinct(this.countryData, "value");
        this.operationalCountryData = this.getDistinct(this.operationalCountryData, "value");
        this.countinentData = this.getDistinct(this.countinentData, "value");
        this.zoneData = this.getDistinct(this.zoneData, "label");
        this.branchData = this.getDistinct(this.branchData, "label");
        this.distributorData = this.getDistinct(this.distributorData, "value");
        surveyType =  this.survey;
        this.wholeDistributorData =  this.distributorData;
        if(surveyType === 'OEM' || surveyType === 'DIST') {
          this.distributorData =  this.distributorData.filter(data=> data.EntityType == surveyType);
        }
        this.distributorSegmentData = this.getDistinct(this.distributorSegmentData, "value");
        this.industrySegmentData = this.getDistinct(this.industrySegmentData, "label")
        this.distributorManagerSalesData = this.getDistinct(this.distributorManagerSalesData, "value");
        this.distributorManagerServiceData = this.getDistinct(this.distributorManagerServiceData, "label");
        this.removeFakeClass();
       
        setTimeout(() => {
          this.applyfilterOnLabel()
        }, 5);
        resolve({ Status: "success" });
      }, 5);
    });
  }

  filterList(fieldName): Array<any> {

    var badivfamblList = JSON.parse(JSON.stringify(this.filterService.dataOriginal));
    var brandList = JSON.parse(JSON.stringify(this.filterService.brandOriginal));
    var zoneBranchList = JSON.parse(JSON.stringify(this.filterService.zoneBranchOriginal));
    var countryContinentList = JSON.parse(JSON.stringify(this.filterService.countryContinentOriginal));
    //var operationalCountryContinentList = (this.filterMapping.FAMId !== null && this.filterMapping.FAMId.length > 0) ? JSON.parse(JSON.stringify(this.filterService.operationalCountryContinentOriginal)) : [];
    var operationalCountryContinentList = JSON.parse(JSON.stringify(this.filterService.operationalCountryContinentOriginal));
    var tpList = JSON.parse(JSON.stringify(this.filterService.touchPointsOriginal));
    var distributorList = JSON.parse(JSON.stringify(this.filterService.distributorOriginal));
    var distributorSegmentList = JSON.parse(JSON.stringify(this.filterService.distributorSegmentOriginal));
    var industrySegmentList = JSON.parse(JSON.stringify(this.filterService.industrySegmentOriginal));
    var distributormanagersalesList = JSON.parse(JSON.stringify(this.filterService.distributorManagerSalesOriginal));
    var distributormanagerserviceList = JSON.parse(JSON.stringify(this.filterService.distributorManagerServiceOriginal));

    var listToReturn = [];


    if (this.filterMapping.FAMId !== null && this.filterMapping.FAMId.length > 0 && fieldName !== 'fam') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.FAMId.indexOf(ele.FAMID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributorList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributormanagerserviceList);
    }

    if (this.filterMapping.BAId !== null && this.filterMapping.BAId.length > 0 && fieldName !== 'ba') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.BAId.indexOf(ele.BAID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributorList);
      distributorSegmentList = this.filterBasedOnEligibility(false, false, true, badivfamblList, distributorSegmentList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributormanagerserviceList);
    }

    if (this.filterMapping.DIVId !== null && this.filterMapping.DIVId.length > 0 && fieldName !== 'div') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.DIVId.indexOf(ele.DivisionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributorList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, badivfamblList, distributormanagerserviceList);

    }
    if (this.filterMapping.BrandId !== null && this.filterMapping.BrandId.length > 0 && fieldName !== 'brand') {
      brandList = brandList.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.BrandID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, brandList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, brandList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, brandList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, brandList, countryContinentList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(false, false, true, brandList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, brandList, distributorList);
      distributorSegmentList = this.filterBasedOnEligibility(false, false, true, brandList, distributorSegmentList, true);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, brandList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, brandList, distributormanagerserviceList);
    }

    if (this.filterMapping.CountryId !== null && this.filterMapping.CountryId.length > 0 && fieldName !== 'country') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.CountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, operationalCountryContinentList);

      distributorList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributorList);
      distributormanagersalesList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributormanagerserviceList);
    }

    if (this.filterMapping.ContinentId !== null && this.filterMapping.ContinentId.length > 0 && fieldName !== 'continent') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.ContinentId.indexOf(ele.ContinentID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributorList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, operationalCountryContinentList);
      distributormanagersalesList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributormanagerserviceList);
    }

    if (this.filterMapping.DistributorSegmentId !== null && this.filterMapping.DistributorSegmentId.length > 0 && fieldName !== 'distributorsegment') {
      distributorSegmentList = distributorSegmentList.filter(ele => {
        if (this.filterMapping.DistributorSegmentId.indexOf(ele.DistributorSegmentID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, false, true, distributorSegmentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, false, true, distributorSegmentList, brandList, true);
      zoneBranchList = this.filterBasedOnEligibility(false, false, true, distributorList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, false, false, distributorList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, false, true, distributorSegmentList, distributorList);
      distributorSegmentList = this.filterBasedOnEligibility(false, false, true, distributorSegmentList, distributorSegmentList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, false, true, distributorList, operationalCountryContinentList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, false, true, distributorSegmentList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, false, true, distributorSegmentList, distributormanagerserviceList);
    }
    if (this.filterMapping.regionId !== null && this.filterMapping.regionId.length > 0 && fieldName !== 'region') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.regionId.indexOf(ele.RegionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributorList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, operationalCountryContinentList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, false, countryContinentList, distributormanagerserviceList);


    }

    if (this.filterMapping.ZoneId !== null && this.filterMapping.ZoneId.length > 0 && fieldName !== 'zone') {
      zoneBranchList = zoneBranchList.filter(ele => {
        if (this.filterMapping.ZoneId.indexOf(ele.ZoneCode) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, zoneBranchList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributorList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, operationalCountryContinentList);
      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributormanagerserviceList);

    }

    if (this.filterMapping.BranchId !== null && this.filterMapping.BranchId.length > 0 && fieldName !== 'branch') {
      zoneBranchList = zoneBranchList.filter(ele => {
        if (this.filterMapping.BranchId.indexOf(ele.BranchCode) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, countryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, zoneBranchList, tpList);
      distributorList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributorList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, operationalCountryContinentList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, distributormanagerserviceList);

    }

    if (this.filterMapping.DistributorId !== null && this.filterMapping.DistributorId.length > 0 && fieldName !== 'distributor') {
      distributorList = distributorList.filter(ele => {
        if (this.filterMapping.DistributorId.indexOf(ele.DistributorID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, distributorList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, distributorList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, distributorList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, distributorList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, true, distributorList, distributorList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, distributorList, operationalCountryContinentList, false, true);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, distributorList, distributormanagersalesList, false, true);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, distributorList, distributormanagerserviceList, false, true);
    }

    if (this.filterMapping.OperationalCountryId !== null && this.filterMapping.OperationalCountryId.length > 0 && fieldName !== 'operationalCountry') {
      operationalCountryContinentList = operationalCountryContinentList.filter(ele => {
        if (this.filterMapping.OperationalCountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, distributorList, false, true);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, operationalCountryContinentList);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, distributormanagersalesList, false, true);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, operationalCountryContinentList, distributormanagerserviceList, false, true);
    }

    if (this.filterMapping.DistributorManagerSalesId !== null && this.filterMapping.DistributorManagerSalesId.length > 0 && fieldName !== 'distributormanagersales') {
      distributormanagersalesList = distributormanagersalesList.filter(ele => {
        if (this.filterMapping.DistributorManagerSalesId.indexOf(ele.DistributorManagerSalesId) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, distributormanagersalesList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, distributorList, false, true);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, operationalCountryContinentList, false, true);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, distributormanagersalesList);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, distributormanagersalesList, distributormanagerserviceList, false, true);
    }

    if (this.filterMapping.DistributorManagerServiceId !== null && this.filterMapping.DistributorManagerServiceId.length > 0 && fieldName !== 'distributormanagerservice') {
      distributormanagerserviceList = distributormanagerserviceList.filter(ele => {
        if (this.filterMapping.DistributorManagerServiceId.indexOf(ele.DistributorManagerServiceId) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, distributormanagerserviceList, countryContinentList);
      distributorList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, distributorList, false, true);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, operationalCountryContinentList, false, true);

      distributormanagersalesList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, distributormanagersalesList, false, true);
      distributormanagerserviceList = this.filterBasedOnEligibility(false, true, true, distributormanagerserviceList, distributormanagerserviceList);
    }

    if (fieldName == 'fam' || fieldName == 'ba' || fieldName == 'div' || fieldName == 'bl') {
      listToReturn = badivfamblList;
    }
    else if (fieldName == 'branch' || fieldName == 'zone') {
      listToReturn = zoneBranchList;
    }
    else if (fieldName == 'country' || fieldName == 'continent' || fieldName == 'region') {
      listToReturn = countryContinentList;
    }

    else if (fieldName == 'operationalCountry') {
      listToReturn = operationalCountryContinentList;
    }
    else if (fieldName == 'continent' || fieldName == 'region') {
      listToReturn = countryContinentList.concat(operationalCountryContinentList);
    }

    else if (fieldName == 'brand') {
      listToReturn = brandList;
    }
    else if (fieldName == 'tp') {
      listToReturn = tpList;
    }
    else if (fieldName == 'distributor') {
      listToReturn = distributorList;
    }
    else if (fieldName == 'distributorsegment') {
      listToReturn = distributorSegmentList;
    }
    else if (fieldName == 'industrysegment') {
      listToReturn = industrySegmentList;
    }
    else if (fieldName == 'industrysegment') {
      listToReturn = industrySegmentList;
    }
    else if (fieldName == 'distributormanagersales') {
      listToReturn = distributormanagersalesList;
    }
    else if (fieldName == 'distributormanagerservice') {
      listToReturn = distributormanagerserviceList;
    }
    //console.log('listToReturn',listToReturn);
    return listToReturn;

  }

  filterBasedOnEligibility(divEligible, famEligible, baEligible, fromList, toList, brandEligible?: boolean, distributorEligible?: boolean, operationalcountryEligible?: boolean, distributormanagersalesEligible?: boolean, distributormanagerserviceEligible?: boolean): Array<any> {
    if (divEligible) {
      var divs = [];
      for (var i = 0; i < fromList.length; i++) {
        divs.push(fromList[i].DivisionID);
      };
      toList = this.filterByDIV(toList, divs);
    }
    if (famEligible) {
      var fams = [];
      for (var i = 0; i < fromList.length; i++) {
        fams.push(fromList[i].FAMID);
      };
      toList = this.filterByFAM(toList, fams);
    }
    if (baEligible) {
      var bas = [];
      for (var i = 0; i < fromList.length; i++) {
        bas.push(fromList[i].BAID);
      };
      toList = this.filterByBA(toList, bas);
    }
    if (brandEligible) {
      var brands = [];
      for (var i = 0; i < fromList.length; i++) {
        brands.push(fromList[i].BrandID);
      };
      toList = this.filterByBrand(toList, brands);
    }

    if (distributorEligible) {
      var distributor = [];
      for (var i = 0; i < fromList.length; i++) {
        distributor.push(fromList[i].DistributorID);
      };
      toList = this.filterByDistributor(toList, distributor);
    }

    if (operationalcountryEligible) {
      var operationalcountry = [];
      for (var i = 0; i < fromList.length; i++) {
        operationalcountry.push(fromList[i].CountryID);
      };
      toList = this.filterByoperationalCountry(toList, operationalcountry);
    }

    if (distributormanagersalesEligible) {
      var distributormanagersales = [];
      for (var i = 0; i < fromList.length; i++) {
        distributormanagersales.push(fromList[i].DistributorManagerSalesId);
      };
      toList = this.filterByDistributorManagerSales(toList, distributormanagersales);
    }
    if (distributormanagerserviceEligible) {
      var distributormanagerservice = [];
      for (var i = 0; i < fromList.length; i++) {
        distributormanagerservice.push(fromList[i].DistributorManagerServiceId);
      };
      toList = this.filterByDistributorManagerService(toList, distributormanagerservice);
    }
    return toList;
  }

  closeFilterPanel() {
    this.clearMessage();
    this.filterToggle = false;
    this.filterDateToggle = false;
  }

  @ViewChild('scrollPanel') private myScrollContainer;
  hideScroll: boolean = false;
  scrollPos(event) {
    this.hideScroll = true
  }
  removeTitle(){
    let elemList = Array.from(document.querySelectorAll('.ui-multiselect-label-container'));   
    elemList.forEach((elem)=>{
      elem.setAttribute('title', '');
    });    
  }
}
