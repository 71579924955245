import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as Rx from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CtlsSharedService {
  constructor(private http: HttpClient) {
  }

  /* CHART */
  chartToggleParamBehaviousSubject = new Rx.BehaviorSubject('Month');
  toggleFilter: BehaviorSubject<any> = new BehaviorSubject<any>({});


  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }
  getUserFAM() {
    return this.http
      .get<any>(`${environment.baseUrlCTLS}/CTLSConfiguration/GetFAMDetails`)
      .toPromise();
  }

  GetLanguages(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrlCTLS}/TaskListing/GetLanguages`);
  }

  updateChartToggleParameter(chartViewBy: string) {
    this.chartToggleParamBehaviousSubject.next(chartViewBy);
  }
  setToggleFilterSub(data: any) {
    this.toggleFilter.next({
      toggleFilter: data.toggleFilter,
      toggleSearch: data.toggleSearch,
      field: data.field
    });
  }
  getToggleFilterSub() {
    return this.toggleFilter.asObservable();
  }

}
