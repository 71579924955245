import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsCustomerMappingService {

  filterObj = {
    pageNumber: 1,
    pageSize: 10000,
    filterFields: {
      CompanyAccountNumber: '',
      CompanyName: '',
      StndrdAccName: '',
      GlobalAccountName: '',
      GlobalAccountCode: '',
      fAMIDs: [],
      bAIDs: [],
      dIVIDs: [],
      contientIDs: [],
      countryIDs: [],
      touchPointIDs: [],
      brandIDs: [],
      branchIDs: [],
      zoneIDs: [],
      influencers: [],
      regionIDs: [],
      salesEngineers: [],
      serviceTechnicians: [],
      customerNames: [],
      customerAccountNumbers: [],
      fromDate: '',
      toDate: ' ',
      periodType: 'Y',
      surveyNameID: null,
      surveyScheduleInstanceID: null,
      distributors: [],
      distributorSegment: [],
      distributorAccountNumber: [],
      surveyID: '',
      contactName: '',
      orderNo: '',
      nPSScore: '',
      nPSComment: '',
      influenceLevel: '',
      emailAddress: '',
      objective: '',
      event: '',
      category: '',
      distributorshipManagerSales: '',
      distributorshipManagerService: '',
      modifiedBy: '',
      comments: '',
      taskCount: '',
      cTLSEnabledDate: '',
      cTLSModifiedDate: '',
      feedbackSubmitDate: '',
      firstName: '',
      lastName: '',
      emailID: '',
      Status: '',
      designation: '',
      AccountSearch: ""
    },
    sortType: '',
    sortField: '',
    GlobalEntityID: null,
    StandardEntityID: null
  };

  private serviceUrl = environment.baseUrl;
  private ctlsServiceUrl = environment.baseUrlCTLS;

  filterObjForCustomerMapping = {
    StartDate: false,
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: false,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: false,
    Region: false,
    Zone: false,
    Branch: false,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: null,
    screenCode: 'CTLS_CUSTOMER_MAPPING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_CUSTOMER_MAPPING',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: false,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'NextStep',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Designation',
      'IsDeleteEnable',
      'Status',
    ],
  };

  constructor(private http: HttpClient) {}

  getCompanyAccount(filterObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetCompanyAccountRecords`,
      filterObj
    );
  }

  getCompanyList(filterObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetCompanyList`, filterObj
    );
  }
  getLazyLoadCompanyList(filterObj):Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetCompanyList`, filterObj
    );
  }
  getLazyLoadStandardList(filterObj):Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetStandardListMap`, filterObj
    );
  }
  getCompanyAccountCount(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetCompanyAccountRecordCount`,
      filterObj
    );
  }

  getStandardAccountCount(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetStandardAccountRecordCount`,
      filterObj
    );
  }
  getGlobalAccountCount(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetGlobalAccountRecordCount`,
      filterObj
    );
  }
  
  deleteStandard(StandardId: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.baseUrlCTLS}/Customer360/DeleteStandardAccountDetails?StandardId=${StandardId}`
    );
  }

  deleteGlobal(GlobalId: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.baseUrlCTLS}/Customer360/DeleteGlobalAccountDetails?GlobalId=${GlobalId}`
    );
  }

  getStandardAccount(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetStandardAccountRecords`,
      filterObj
    );
  }

  getGlobalAccount(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetGlobalAccountRecords`,
      filterObj
    );
  }
  getGlobalAccounts(filterObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetGlobalAccounts`,
      filterObj
    );
  }

  getAllStandardAccounts(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetStandardEntityList`,
      filterObj
    );
  }

  getGlobalAccountsList(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetGlobalEntityList`,
      filterObj
    );
  }

  getStandardAccountByID(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetStandardEntityDetailsById`,
      filterObj
    );
  }

  getGlobalAccountByID(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetGlobalEntityDetailsById`,
      filterObj
    );
  }

  saveStandardAccount(request): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/SaveStandardentity`,
      request
    );
  }

  getCompanyAccountListByStandardID(request): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetCompanyAccountListByStandardID`,
      request
    );
  }

  getContactListByStandardID(request): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/GetCompanyContactListByComapnyID`,
      request
    );
  }

  saveGlobalAccount(request): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/SaveGlobalentity`,
      request
    );
  }

  download() {
    return this.http.get(`${environment.baseUrlCTLS}/Master/ExportToExcelcustomer`, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  bulkUpload(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/Customerbulkupload`,
      dataObj
    );
  }

  addGlobalAccount(request): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Customer360/SaveGlobalentitywithoutmap`,
      request
    );
  }

  /**Service for get modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
        },
      }
    );
  }
}
