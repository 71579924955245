import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponsePaginated } from '../model/question.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateQuestionsService {

  constructor(private http: HttpClient) { }
  templateQuestionUrl = environment.baseUrl + "api/ManageQuestion/SurveyTemplate";
  translatedQuestionUrl = environment.baseUrl + "api/ManageQuestion/SurveyTemplate";
  getBaseLanguageQuestions(templateId) {
    return this.http.get(this.templateQuestionUrl + "/TemplateQuestions?templateId=" + templateId)
      .toPromise()
      .then((res: ApiResponsePaginated) => {
        res.Status = res.Status;
        return res;
      })
      .then(data => {
        return data;
      });
  }
  getTranslatedQuestions(templateId, langId, permission) {
    //MEthod to be modified to get translated version

    return this.http.get(this.translatedQuestionUrl + "/TemplateQuestionsTranslated?templateId=" + templateId + "&langId=" + langId + "&permission=" + permission + "&buster=" + new Date().getTime())
      .toPromise()
      .then((res: ApiResponsePaginated) => {
        return res;
      })
      .then(data => {
        return data;
      });
  }
  postEditedQuestions(questionSurveyTemplate) {
    return this.http.post(this.translatedQuestionUrl + "/EditSurveyTemplate", questionSurveyTemplate)
      .toPromise()
      .then(data => {
        return data;
      });
  }
  saveTemplateQuestions(data) {
    return this.http.post(this.translatedQuestionUrl + "/SaveSurveyTemplateQuestions", data)
      .toPromise()
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

}
