import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsAssigneeMappingService {
  filterObjForAssigneeMapping = {
    StartDate: false,
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: false,
    ServiceTechnician: false,
    Continent: true,
    Region: true,
    Zone: false,
    Branch: false,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: null,
    screenCode: 'CTLS_ASSIGNEE_MAPPING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_ASSIGNEE_MAPPING',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: false,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'NextStep',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'SurveyID',
      'IsFirstSave',
      'Designation',
      'IsDeleteEnable',
      'Status',
    ],
  };
  recordCount: number;
  /** Form wizard state */
  surveyID: number = 0;
  markforsurvey: string = '';

  instanceAccordionStatus: any = {};

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.recordCount = 0;
  }
  getUserFAM(): Observable<any> {
    return this.http
      .get<any>(`${environment.baseUrlCTLS}/CTLSConfiguration/GetFAMDetails`)
  }
  /**Service for load grid data in task survey feedback
   * @param dataObj
   */
  getAssigneeMapping(filterObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/GetAllAssigneeMappingUserDetails`,
      filterObj
    );
  }
  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
        },
      }
    );
  }
  /**Service for delete user/users
   * @param dataObj
   */
  DeleteUserMapping(dataObj) {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/DeleteUserMapping`,
      dataObj
    );
  }
  /**Service for load tasks
   * @param dataObj
   */
  loadTasks(AssigneeID) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/GetTasksAssignedToUser`,
      {
        params: {
          AssigneeID: AssigneeID ? AssigneeID : null,
        },
      }
    );
  }

  /**Service for enable or disable assignees
   * @param dataObj
   */
  saveStatus(dataObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/SetAssigneeStatus`,
      dataObj
    );
  }
  /**Service for enable or disable assignees
   * @param dataObj
   */
  saveAssigneeMappingDetails(dataObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/SaveAssigneeMappingDetails
      `,
      dataObj
    );
  }
  /**Service for getting all enabled assignees
   * @param dataObj
   */
  GetAllEnabledAssignees(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/GetAllEnabledAssignees`,
      {
        params: {
          FAMID: dataObj.FAMID,
          BAID: dataObj.BAID,
        },
      }
    );
  }
  /**Service for reassigning tasks
   * @param dataObj
   */
  ReassignTask(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/SaveReassignTask`,
      // dataObj
      {
        // params: {
          FAMID: dataObj.FAMID,
          BAID: dataObj.BAID,
          AssigneeID: dataObj.AssigneeID,
          AssigneeMappingID: dataObj.AssigneeMappingID,
          ReassignTaskAssigneeID:dataObj.ReassignTaskAssigneeID,
          ReassignEscalationAssigneeID:dataObj.ReassignEscalationAssigneeID,
          ReassignCustomerorDistributorAssigneeID:dataObj.ReassignCustomerorDistributorAssigneeID
        },
      // }
    );
  }
  /**Service for reassigning tasks
   * @param dataObj
   */
  CheckAssignedTaskExist(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/AssigneeMapping/CheckPendingTaskAvailable`,
      dataObj
    );
  }

   /**Service for saving status change
   * @param dataObj
   */
    getCustomers(dataObj): Observable<any> {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/SearchCustomerDistributor?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&Search=${dataObj.Search}`,
      );
    }

    geEscalationAssignees(dataObj): Observable<any> {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetAssigneeDetails`,
      );
    }
    
    getEscalation(dataObj) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetEscalationDetails?AssigneeMappingID=${dataObj.AssigneeMappingID}`,
        dataObj
      );
    }

    saveEscalation(dataObj) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/SaveEscalationDetails`,
        dataObj
      );
    }

    saveCustomerMapping(dataObj) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/SaveCustomerDistributorMapping`,
        dataObj
      );
    }

    getCDMapping(dataObj) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetCustomerDistributorMapping?AssigneeMappingID=${dataObj.AssigneeMappingID}`,
      );
    }
    getDeleteCustomerDistributorMapping(dataObj) {     
      return this.http.put<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/DeleteCustomerDistributorMapping?CDMappingID=${dataObj.CDMappingID}`,
        dataObj
      );
    }

  //Assignee mapping task visiblity and escalation
    getDivisionForAssignee(dataObj){
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetDivisionForAssignee`,
        dataObj
      );
    }

    getEscalationDetails(dataObj){
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetEscalationDetails?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&AssigneeMappingID=${dataObj.AssigneeMappingID}`,
        dataObj
      );
    }

    getCTLSTaskvisibility(dataObj){
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetCTLSTaskvisibility?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&AssigneeMappingID=${dataObj.AssigneeMappingID}`,
        dataObj
      );
    }

    getAllEnabledAssigneesById(dataObj) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetAllEnabledAssigneesById?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&AssigneeID=${dataObj.AssigneeID}`,
      );
    }

    saveTaskvisibilityEscalation(dataObj){
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/SaveTaskvisibilityEscalation`,
        dataObj
      );
    }

    getReassignementDetails(dataObj) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetReassignementDetails?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&AssigneeID=${dataObj.AssigneeID}&AssigneeMappingID=${dataObj.AssigneeMappingID}`,
      );
    }

    getAllEnabledUsers(dataObj) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/AssigneeMapping/GetAllEnabledUsers?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&AssigneeID=${dataObj.AssigneeID}`,
      );
    }
    // getAllEnabledAssigneesById(dataObj) {
    //   return this.http.get<any>(
    //     `${environment.baseUrlCTLS}/AssigneeMapping/GetAllEnabledAssigneesById?BAID=${dataObj.BAID}&FAMID=${dataObj.FAMID}&AssigneeID=${dataObj.AssigneeID}&AssigneeMappingID=${dataObj.AssigneeMappingID}`,
    //   );
    // }
    
}
