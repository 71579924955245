import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Survey } from '../../../../models/surveyItem';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateSurveyService {

  

  public baAndDivMaster:any = {};
  public famMaster:any = {};
  public brandMaster: any = {};
  public surveyId:number;
  public surveyDetailList = [];
  public selectedLineItem:any = null;

  constructor(private http: HttpClient, private sharedService: SharedService) {  }

  getSurveys(page, size, sortType, sortField, filterText: string) {

    return this.http.get(environment.baseUrl + "api/Survey/GetSurveys?"
      + "pageNo=" + page
      + "&pageSize=" + size
      + "&sortType=" + sortType
      + "&sortField=" + sortField
      + "&filterText=" + filterText +'&randomtextsurveygrid='+ Math.random())
      .toPromise().then((res: any) => {
        return res.Response
      });
  }

  getExpandedSurveys(filterText: string = '') {

    return this.http.get(environment.baseUrl + "api/Survey/GetAllExpandedSurvey?randomtextsurveygrid="+ Math.random())
      .toPromise().then((res: any) => {
        return res
      });
  }

  getSurveyTouchPointTemplates(touchPointCodes: string) {
    return this.http.get(environment.baseUrl + 'api/Survey/GetTouchPointTemplate/' + touchPointCodes + '?'+ Math.random())
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  getbaAndDivData() {
    return this.http.get(environment.baseUrlMDM + '/MDM/BusinessAreas' + '?'+ Math.random())
    .toPromise()
    .then((res: any) => this.baAndDivMaster = res)
  }

  getfamData() {
    return this.http.get(environment.baseUrl + 'api/survey/GetFamsForSurvey' + '?'+ Math.random())
    .toPromise()
    .then((res: any) => this.famMaster = res)
  }

  getBrandData() {
    return this.http.get(environment.baseUrl + 'api/survey/GetBrandsForSurvey' + '?'+ Math.random())
    .toPromise()
    .then((res: any) => this.brandMaster = res);
  }

  getSurveyDeatilsById() {
    return this.http.get( environment.baseUrl + "api/survey/GetSurveyById/"+ this.surveyId + '?'+ Math.random());
    //return this.http.get( this.tabServiceUrl );
  }
  getImpactCount(surveyid:number) {
    return this.http.get(environment.baseUrl + 'api/survey/GetImpactCountForSurvey/'+ surveyid + '?'+ Math.random())
    .toPromise()
    .then((res: any) => { return res } )
  }

  getMappedSurveyList(surveyid:number) {
    return this.http.get(environment.baseUrl + 'api/survey/GetMappedSurveys/'+ surveyid + '?'+ Math.random())
    .toPromise()
    .then((res: any) => { return res } )
  }

  getLanguagesBySurveyId(surveyId:number , baId:number ,divId:number ,fmaId:number ,brandId:number ,toucpointId:number ){
    return this.http.get(environment.baseUrl + 'api/survey/GetSurveyLanguage/'+ surveyId
    + '/'+ baId
    + '/'+ divId
    + '/'+ fmaId
    + '/'+ brandId
    + '/'+ toucpointId + '?'+ Math.random())
    .toPromise()
    .then((res: any) => { return res } )
  }
//Survey Readiness Api calss- start
  getPendingTemplateInfoForSurvey(surveyid:number, pageNo: Number, pageSize:number, lineItem) {

    var params = surveyid + '/'+ pageNo + '/'+ pageSize
    + '/'+ (lineItem != null ? lineItem.BaId : null)
    + '/'+ (lineItem != null ? lineItem.DivId : null)
    + '/'+ (lineItem != null ? lineItem.FamId : null)
    + '/'+ (lineItem != null ? lineItem.BrandId : null)
    + '/'+ (lineItem != null ? lineItem.TouchPointID : null)
    + '?'+ Math.random()

    return this.http.get(environment.baseUrl + 'api/survey/GetPendingTemplateInfoForSurvey/'+ params )
    .toPromise()
    .then((res: any) => { return res } )
  }
  getAuthorityTranslationPendingInfoForSurvey(surveyid:number, pageNo: Number, pageSize:number, lineItem) {
    var params = surveyid + '/'+ pageNo + '/'+ pageSize
    + '/'+ (lineItem != null ? lineItem.BaId : null)
    + '/'+ (lineItem != null ? lineItem.DivId : null)
    + '/'+ (lineItem != null ? lineItem.FamId : null)
    + '/'+ (lineItem != null ? lineItem.BrandId : null)
    + '/'+ (lineItem != null ? lineItem.TouchPointID : null)
    + '?'+ Math.random()

    return this.http.get(environment.baseUrl + 'api/survey/GetAuthorityTranslationPendingInfoForSurvey/'+ params)
    .toPromise()
    .then((res: any) => { return res } )
  }
  getAuthorityNotCreatedInfoForSurvey(surveyid:number, pageNo: Number, pageSize:number, lineItem) {
    var params = surveyid + '/'+ pageNo + '/'+ pageSize
    + '/'+ (lineItem != null ? lineItem.BaId : null)
    + '/'+ (lineItem != null ? lineItem.DivId : null)
    + '/'+ (lineItem != null ? lineItem.FamId : null)
    + '/'+ (lineItem != null ? lineItem.BrandId : null)
    + '/'+ (lineItem != null ? lineItem.TouchPointID : null)
    + '?'+ Math.random()

    return this.http.get(environment.baseUrl + 'api/survey/GetAuthorityNotCreatedInfoForSurvey/'+ params)
    .toPromise()
    .then((res: any) => { return res } )
  }
  getBrandDesignPendingInfoForSurvey(surveyid:number, pageNo: Number, pageSize:number, lineItem) {
    var params = surveyid + '/'+ pageNo + '/'+ pageSize
    + '/'+ (lineItem != null ? lineItem.BaId : null)
    + '/'+ (lineItem != null ? lineItem.DivId : null)
    + '/'+ (lineItem != null ? lineItem.FamId : null)
    + '/'+ (lineItem != null ? lineItem.BrandId : null)
    + '/'+ (lineItem != null ? lineItem.TouchPointID : null)
    + '?'+ Math.random()

    return this.http.get(environment.baseUrl + 'api/survey/GetBrandDesignPendingInfoForSurvey/'+ params)
    .toPromise()
    .then((res: any) => { return res } )
  }
  getEmailAndQuestionnaireContentTranslationPendingInfoForSurvey(surveyid:number, pageNo: Number, pageSize:number, lineItem) {
    var params = surveyid + '/'+ pageNo + '/'+ pageSize
    + '/'+ (lineItem != null ? lineItem.BaId : null)
    + '/'+ (lineItem != null ? lineItem.DivId : null)
    + '/'+ (lineItem != null ? lineItem.FamId : null)
    + '/'+ (lineItem != null ? lineItem.BrandId : null)
    + '/'+ (lineItem != null ? lineItem.TouchPointID : null)
    + '?'+ Math.random()

    return this.http.get(environment.baseUrl + 'api/survey/GetEmailAndQuestionnaireContentTranslationPendingInfoForSurvey/'+ params)
    .toPromise()
    .then((res: any) => { return res } )
  }
  getEmailAndQuestionnaireContentNotCreatedInfoForSurvey(surveyid:number, pageNo: Number, pageSize:number, lineItem) {
    var params = surveyid + '/'+ pageNo + '/'+ pageSize
    + '/'+ (lineItem != null ? lineItem.BaId : null)
    + '/'+ (lineItem != null ? lineItem.DivId : null)
    + '/'+ (lineItem != null ? lineItem.FamId : null)
    + '/'+ (lineItem != null ? lineItem.BrandId : null)
    + '/'+ (lineItem != null ? lineItem.TouchPointID : null)
    + '?'+ Math.random()

    return this.http.get(environment.baseUrl + 'api/survey/GetEmailAndQuestionnaireContentNotCreatedInfoForSurvey/'+ params)
    .toPromise()
    .then((res: any) => { return res } )
  }

//Survey Readiness Api calss- end
  skipInroPopup(isSkip) {
    return this.http.get(environment.baseUrl + 'api/survey/UpdateUserPreferenceCreateSurvey/' + isSkip + '?'+ Math.random())
    .toPromise()
    .then((res: any) =>{ return res });
  }

  userAccessGetDetails() {
    return this.http.get(environment.baseUrlMDM + '/UserAccess/GetDetails' + '?'+ Math.random())
      .toPromise()
      .then((res: any) => { return res;});
  }

  saveSurveyDetails(surveyObject) {
    return this.http.post(environment.baseUrl + 'api/Survey/AddSurvey', surveyObject)
      .toPromise()
      .then((res: any) => {
        if(res.Response.surveyid){
          this.surveyId = res.Response.surveyid;
        }
        return res
      });
  }

  saveDivisionsForSurvey(surveyObject) {
    return this.http.post(environment.baseUrl + 'api/survey/UpdateBADivForSurvey', surveyObject)
      .toPromise()
      .then((res: any) => {
        if(res.Response.surveyid){
          this.surveyId = res.Response.surveyid;
        }
        return res
      });
  }

  saveFamandBrandDetails(data){
    return this.http.post(environment.baseUrl + 'api/survey/UpdateFamAndBrandForSurvey', data)
      .toPromise()
      .then((res: any) => {
        if(res.Response.surveyid){
          this.surveyId = res.Response.surveyid;
        }
        return res
      });
  }

  saveBrandDetails(data){
    return this.http.post(environment.baseUrl + 'api/survey/UpdateBrandForSurvey', data)
      .toPromise()
      .then((res: any) => {
        if(res.Status == "Success" && res.Response !=null && res.Response.length > 0){
          this.surveyId = res.Response[0].SurveyId;
        }
        return res
      });
  }

  publishSurvey(surveyid:number, status:string){

    return this.http.post(environment.baseUrl + 'api/survey/PublishSurvey', { SurveyId: surveyid, Status:status} )
      .toPromise()
      .then((res: any) => {
        if(res.Response.surveyid){
          this.surveyId = res.Response.surveyid;
        }
        return res
      });
  }

  enableDisableSurvey(surveyid, status:boolean){


    return this.http.post(environment.baseUrl + 'api/survey/EnableDisableSurvey', { "SurveyId": surveyid , "IsActive": status } )
      .toPromise()
      .then((res: any) => {
        return res
      });
  }

  updateMappedTemplateForSurvey(searchCriteria){
    return this.http.post(environment.baseUrl + 'api/survey/UpdateMappedTemplateForSurvey', searchCriteria )
    .toPromise()
    .then((res: any) => {
      return res
    });
  }

  sendTestEmail(emailConfig){
    return this.http.post(environment.baseUrl + 'api/survey/SendTestEmailForSurvey', emailConfig )
    .toPromise()
    .then((res: any) => {
      return res
    });
  }

  getSurveyTemplate() {
    // return this.http.get<any>('http://www.json-generator.com/api/json/get/cqzyDqLqdK?indent=2')
    return this.http.get<any>('http://www.json-generator.com/api/json/get/cfradRfFKa?indent=2')
      .toPromise()
      .then(res => <Survey[]>res.Response)
      .then(Response => { return Response; });
    }

}
