import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CtlsNotificationService {

  constructor(private http: HttpClient) { }



  /**Service to get Notification records
  */
  GetNotifications(): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Notification/GetNotifications`,
      ''
    );
  }

  GetNotificationsCount(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/Notification/NotificationCount`,
    );
  }
  getNotificationMessages(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/Notification/NotificationMessages`,
    );
  }
    /**
   * Delete notification from notification page
   * @param NotificationId: array of nitificationid
   * @returns: Observable
   */
   getDismissBellNotifications(NotificationId): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/Notification/DismissBellNotifications`,
      NotificationId
    );
  }

  /**
  * Save Notification from Notification Listing page
  * @param saveObj: array of taskIds
  * @returns: Observable
  */

  saveNotifications(saveObj): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/Notification/SaveNotifications`,
      saveObj
    );
  }


}
