import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Rx from 'rxjs';
import { CtlsSharedService } from './ctls-shared.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CtlsFilterHeaderService {
  public GlobalDateChanged = true;
  is360Dashboard = new Rx.BehaviorSubject(false);
  filterObj = {
    TrendAnalysis: '',
    StartDate: '',
    EndDate: '',
    PeriodType: '',
    BACode: '',
    FAMCode: '',
    DivCode: '',
    BLCode: '',
    Country: '',
    OperationalCountry: '',
    Category: '',
    BrandName: '',
    SalesEngineer: '',
    Touchpoint: '',
    Influencer: '',
    ServiceTechnician: '',
    Continent: '',
    Region: '',
    Zone: '',
    Branch: '',
    PinnedField: '',
    ActionType: '',
    CustomerNameCode: '', //11
    CustomerAccountNumberCode: '', //22
    Distributors: '', //33
    DistributorSegment: '', //44
    DistributorAccountNumber: '', //55
  };

  selectedFilters = {
    baCount: [],
    divCount: [],
    famCount: [],
    blCount: [],
    countryCount: [],
    operationalCountryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    salesCount: [],
    serviceCount: [],
    touchpointCount: [],
    influencerCount: [],
    continentCount: [],
    regionCount: [],
    customerNameCount: [], //11
    customerAccountNumberCount: [],
    distributorsCount: [], //33
    distributorSegmentCount: [], //44
    distributorAccountNumberCount: [], //55
  };
  filterObjForGrid = [];
  globalFilterBehaviousSubject = new Rx.BehaviorSubject(this.filterObj);
  globalFilterSelectedFilterSubject = new Rx.BehaviorSubject(
    this.selectedFilters
  );

  constructor(
    private http: HttpClient,
    private sharedService: CtlsSharedService
  ) { }

  setFlag360Dashboard(flag: boolean) {
    this.is360Dashboard.next(flag)
  }
  getUserFAM(): Observable<any> {
    return this.http
      .get<any>(`${environment.baseUrlCTLS}/CTLSConfiguration/GetFAMDetails`)
  }

  getAllData() {
    return this.http.get<any>(
      environment.baseUrlCTLS + '/BusinessFilters/GetMasterFilter'
    ).toPromise();
  }

  saveUserFilter(filterObject, isDate: boolean) {
    return this.http
      .post(
        environment.baseUrlCTLS + `/BusinessFilters/SaveUserFilter`,
        filterObject
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getUserFilter(surveySubCategoryCode, screenCode): Observable<any> {
    return this.http.get<any>(
      environment.baseUrlCTLS +
      `/BusinessFilters/GetSavedUserFilter?surveySubCategoryCode=${surveySubCategoryCode}&screenCode=${screenCode}`
    );
  }
  setUserFilterData(res) {
    this.mapFilterObject(res, true);
    localStorage.setItem(
      //not needed to store
      'ctlsGlobalFilterObject',
      JSON.stringify(res)
    );
    this.globalFilterBehaviousSubject.next(this.filterObj);
  }

  setUserFilterData360(res) {
    this.mapFilterObject360(res, true);
  }
  clearUserFilter(surveySubCategoryCode, screenCode) {
    return this.http
      .post(
        environment.baseUrlCTLS +
        `/BusinessFilters/ClearUserFilter?surveySubCategoryCode=${surveySubCategoryCode}&screenCode=${screenCode}`,
        null
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  categoryOriginal = [];
  OpCountryContiRegionOriginal = [];
  brandOriginal = [];
  engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  customerNameOriginal = []; //11
  customerAccountNumberOriginal = [];
  distributorsOriginal = []; //33
  distributorSegmentOriginal = []; //44
  distributorAccountNumberOriginal = []; //55
  resetGlobalFilterData() {
    this.dataOriginal = [];
    this.zoneBranchOriginal = [];
    this.countryContinentOriginal = [];
    this.categoryOriginal = [];
    this.OpCountryContiRegionOriginal = [];
    this.brandOriginal = [];
    this.engineerAndTechnicianOriginal = [];
    this.touchPointsOriginal = [];
    this.influencersOriginal = [];
    this.customerNameOriginal = []; //11
    this.customerAccountNumberOriginal = [];
    this.distributorsOriginal = []; //33
    this.distributorSegmentOriginal = []; //44
    this.distributorAccountNumberOriginal = []; //55
  }
  mapFilterObject(obj, includeDateFields) {
    if (includeDateFields && !!obj) {
      this.filterObj.StartDate = obj.StartDate == null ? '' : obj.StartDate;
      this.filterObj.EndDate = obj.EndDate == null ? '' : obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType == null ? '' : obj.PeriodType;

      var dateObject = {
        StartDate: obj.StartDate == null ? '' : obj.StartDate,
        EndDate: obj.EndDate == null ? '' : obj.EndDate,
        PeriodType: obj.PeriodType == null ? '' : obj.PeriodType,
      };
      localStorage.setItem(
        'ctlsPeriodFilterObject',
        JSON.stringify(dateObject)
      );
    }
    if (!!obj) {
      this.filterObj.TrendAnalysis = obj.TrendAnalysis == null ? '' : obj.TrendAnalysis;
      this.filterObj.BACode = obj.BACode == null ? '' : obj.BACode;
      this.filterObj.FAMCode = obj.FAMCode == null ? '' : obj.FAMCode;
      this.filterObj.DivCode = obj.DivCode == null ? '' : obj.DivCode;
      this.filterObj.BLCode = obj.BLCode == null ? '' : obj.BLCode;
      this.filterObj.Country = obj.Country == null ? '' : obj.Country;
      this.filterObj.OperationalCountry = obj.OperationalCountry == null ? '' : obj.OperationalCountry;
      this.filterObj.Category = obj.Category == null ? '' : obj.Category;
      this.filterObj.BrandName = obj.BrandName == null ? '' : obj.BrandName;
      this.filterObj.Zone = obj.Zone == null ? '' : obj.Zone;
      this.filterObj.Branch = obj.Branch == null ? '' : obj.Branch;
      this.filterObj.SalesEngineer =
        obj.SalesEngineer == null ? '' : obj.SalesEngineer;
      this.filterObj.ServiceTechnician =
        obj.ServiceTechnician == null ? '' : obj.ServiceTechnician;
      this.filterObj.Touchpoint = obj.Touchpoint == null ? '' : obj.Touchpoint;
      this.filterObj.Influencer = obj.Influencer == null ? '' : obj.Influencer;
      this.filterObj.Continent = obj.Continent == null ? '' : obj.Continent;
      this.filterObj.Region = obj.Region == null ? '' : obj.Region;
      this.filterObj.PinnedField = obj.PinnedField == null ? '' : obj.PinnedField;
      this.filterObj.CustomerNameCode =
        obj.CustomerName == null ? '' : obj.CustomerName;
      this.filterObj.CustomerAccountNumberCode =
        obj.CustomerAccountNumber == null ? '' : obj.CustomerAccountNumber;
      this.filterObj.Distributors =
        obj.Distributors == null ? '' : obj.Distributors;
      this.filterObj.DistributorSegment =
        obj.DistributorSegment == null ? '' : obj.DistributorSegment;
      this.filterObj.DistributorAccountNumber =
        obj.DistributorAccountNumber == null ? '' : obj.DistributorAccountNumber;
    }
  }
  mapFilterObject360(obj, includeDateFields) {
    if (includeDateFields && !!obj) {
      this.filterObj.StartDate = obj.StartDate == null ? '' : obj.StartDate;
      this.filterObj.EndDate = obj.EndDate == null ? '' : obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType == null ? '' : obj.PeriodType;

      /* var dateObject = {
        StartDate: obj.StartDate == null ? '' : obj.StartDate,
        EndDate: obj.EndDate == null ? '' : obj.EndDate,
        PeriodType: obj.PeriodType == null ? '' : obj.PeriodType,
      };
      localStorage.setItem(
        'ctlsPeriodFilterObject',
        JSON.stringify(dateObject)
      ); */
    }
    if (!!obj) {
      this.filterObj.TrendAnalysis = obj.TrendAnalysis == null ? '' : obj.TrendAnalysis;
      this.filterObj.BACode = obj.BACode == null ? '' : obj.BACode;
      this.filterObj.FAMCode = obj.FAMCode == null ? '' : obj.FAMCode;
      this.filterObj.DivCode = obj.DivCode == null ? '' : obj.DivCode;
      this.filterObj.BLCode = obj.BLCode == null ? '' : obj.BLCode;
      this.filterObj.Country = obj.Country == null ? '' : obj.Country;
      this.filterObj.OperationalCountry = obj.OperationalCountry == null ? '' : obj.OperationalCountry;
      this.filterObj.Category = obj.Category == null ? '' : obj.Category;
      this.filterObj.BrandName = obj.BrandName == null ? '' : obj.BrandName;
      this.filterObj.Zone = obj.Zone == null ? '' : obj.Zone;
      this.filterObj.Branch = obj.Branch == null ? '' : obj.Branch;
      this.filterObj.SalesEngineer =
        obj.SalesEngineer == null ? '' : obj.SalesEngineer;
      this.filterObj.ServiceTechnician =
        obj.ServiceTechnician == null ? '' : obj.ServiceTechnician;
      this.filterObj.Touchpoint = obj.Touchpoint == null ? '' : obj.Touchpoint;
      this.filterObj.Influencer = obj.Influencer == null ? '' : obj.Influencer;
      this.filterObj.Continent = obj.Continent == null ? '' : obj.Continent;
      this.filterObj.Region = obj.Region == null ? '' : obj.Region;
      this.filterObj.PinnedField = obj.PinnedField == null ? '' : obj.PinnedField;
      this.filterObj.CustomerNameCode =
        obj.CustomerName == null ? '' : obj.CustomerName;
      this.filterObj.CustomerAccountNumberCode =
        obj.CustomerAccountNumber == null ? '' : obj.CustomerAccountNumber;
      this.filterObj.Distributors =
        obj.Distributors == null ? '' : obj.Distributors;
      this.filterObj.DistributorSegment =
        obj.DistributorSegment == null ? '' : obj.DistributorSegment;
      this.filterObj.DistributorAccountNumber =
        obj.DistributorAccountNumber == null ? '' : obj.DistributorAccountNumber;
    }
    
  }
  updateFilterObject(): any {
    var filterObjectString = localStorage.getItem('ctlsGlobalFilterObject');
    var periodFilterString = localStorage.getItem('ctlsPeriodFilterObject');
    if (filterObjectString != null) {
      var obj = JSON.parse(filterObjectString);
      this.mapFilterObject(obj, false);
    }
    if (periodFilterString != null) {
      var obj = JSON.parse(periodFilterString);
      this.filterObj.StartDate = obj.StartDate;
      this.filterObj.EndDate = obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType;
    }
    console.log("category update filter obj", this.filterObj)
    this.globalFilterBehaviousSubject.next(this.filterObj);
  }

  /**
   * Behaviour subject for id-text of selected filter
   */
  public updateSelectedFilter = (selectedFilters: any) => {
    this.globalFilterSelectedFilterSubject.next(
      JSON.parse(JSON.stringify(selectedFilters))
    );
  };
}
