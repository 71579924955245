import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsTaskSurveyFeedbackService {
  filterObjForTransactional = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true, //
    FAMCode: true, //
    DivCode: true, //
    BLCode: false,
    Country: true, //
    BrandName: true, //
    SalesEngineer: true, //
    Touchpoint: true, //
    Influencer: true, //
    ServiceTechnician: true, //
    Continent: true, //
    Region: true, //
    Zone: true, //
    Branch: true, //
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'CTLS_TASK_SURVEY_FEEDBACK', //SELECTED SCREEN
    sessionStorageName: 'CTLS_TRANS_TASK_SURVEY_FEEDBACK',
    savedFilterSessionStorageName: 'CTLS_TRANS_TASK_SURVEY_FEEDBACK_SAVED_FILTER',
    CustomerName: true, //11
    CustomerAccountNumber: true,
    Distributors: false, //33
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'Status',
      'NextStep',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'IsFirstSave',
      'SurveyResponseID',
      'InfluenceLevel',
    ],
  };
  filterObjForRelational = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: true,
    ServiceTechnician: false,
    Continent: true,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_TASK_SURVEY_FEEDBACK', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_TASK_SURVEY_FEEDBACK',
    savedFilterSessionStorageName: 'CTLS_DIST_TASK_SURVEY_FEEDBACK_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: true,
    DistributorSegment: true,
    DistributorAccountNumber: true,
    showDateFilter: true,
    //selceted field to search => remove fields form the filterObjForGrid, Then only display in grig select box
    filterObjForGrid: [
      'BA',
      'FAM',
      'DIV',
      'Brand',
      'SalesEngineer',
      'TouchPoint',
      'ServiceTechnician',
      'Zone',
      'Branch',
      'Customer',
      'CustomerAccountNo',
      'DistributorName',
      'DistributorAccountNo',
      'SurveyName',
      'SurveyInstanceID',
      'Status',
      'NextStep',
      'InstanceStartDate',
      'InstanceEndDate',
      'Instance',
      'SurveyInstance',
      'Country',
      'Division',
      'CTLSEnabledDate',
      'CTLSModifiedDate',
      'IsFirstSave',
      'InfluenceLevel',
      'DistributorSegment',
    ],
  };
  // filterObjForHistoricalData is for historical data popup, not needed if popup is not required
  filterObjForHistoricalData = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: true,
    ServiceTechnician: false,
    Continent: true,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_TASK_SURVEY_FEEDBACK', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_TASK_SURVEY_FEEDBACK',
    savedFilterSessionStorageName: 'CTLS_DIST_TASK_SURVEY_FEEDBACK_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: true,
    DistributorSegment: true,
    DistributorAccountNumber: true,
    showDateFilter: true,
    //selceted field to search => remove fields form the filterObjForGrid, Then only display in grig select box
    filterObjForGrid: [],
  };
  recordCount: number;
  /** Form wizard state */
  surveyID: number = 0;
  markforsurvey: string = '';

  instanceAccordionStatus: any = {};

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.recordCount = 0;
  }

  deleteSurvey(surveyID) {
    return this.http
      .post(
        environment.baseUrl + 'api/RelationshipSurveys/' + surveyID + '/Delete',
        {}
      )
      .toPromise()
      .then((res: any) => res)
      .then((data) => {
        return data;
      });
  }

  pinUnpin(SurveyId, IsPinned) {
    if (IsPinned) {
      return this.http
        .post(
          environment.baseUrl +
          'api/RelationshipSurveys/' +
          SurveyId +
          '/PinSurvey',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    } else {
      return this.http
        .post(
          environment.baseUrl +
          'api/RelationshipSurveys/' +
          SurveyId +
          '/unPinSurvey',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    }
  }

  /** Helpers */
  public getDateinPreferredFormat = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  /**Service for load grid data in task survey feedback
   * @param dataObj
   */
  getTaskSurveyFeedback(value, filterObj): Observable<any> {
    if (value === 1) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAllTaskForSurveyFeedbackTransactional`,
        filterObj
      );
    } else if (value === 2) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAllTaskForSurveyFeedbackRelationship`,
        filterObj
      );
    }
  }
  /**Service for load data for tabs in task survey feedback
   * @param value
   * @param filterObj
   */
  getCloseWithoutTaskTable(value, filterObj): Observable<any> {
    if (value === 1) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAllClosedTransactionalTasksDetails`,
        filterObj
      );
    } else if (value === 2) {
      return this.http.post<any>(
        `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAllClosedRelationshipTasksDetails`,
        filterObj
      );
    }
  }
  /**
   * Get create task popup dropdowns
   */
  getManualTaskFeedbackMasters(surveyType?, TaskID?): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetManualTaskFeedbackMasters?surveyType=${surveyType}&&TaskID=${TaskID}`
    );
  }
  /**
   * Get designations dropdowns
   */
  getDesignations(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetDesignations`,
      params
    );
  }
  /**
   * Get division dropdowns
   */
  getDivision(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetDivision`,
      params
    );
  }

  /**
   * Get assignee dropdowns
   */
  GetAssignee(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAssignee`,
      params
    );
  }
  /**
   * Get brand dropdowns
   */
  GetBrand(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetBrand`,
      params
    );
  }
  /**
   * Get CommentHistory
   */
  GetCommentHistory(CTLSTaskListID): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetCommentHistory?CTLSTaskListID=${CTLSTaskListID}`,
      {}
    );
  }
  /**
   * Get Category dropdowns
   */
  GetCategory(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetCategory`,
      {}
    );
  }
  /** Translate data
   * @param dataObj
   */
  getTranslateWithGoogle(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Translate/TranslateWithGoogle`,
      dataObj
    );
  }
  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }
  /**Service for save  task list
   * @param dataObj
   */
  SaveTaskList(dataObj): Observable<any> {
    let authenticatedHeader = new HttpHeaders();
    authenticatedHeader = authenticatedHeader.set(
      'Content-Type',
      'application/json'
    );
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/SaveTaskList`,
      dataObj
      // { headers: authenticatedHeader }
    );
  }
  /**
   * Create task
   * @param dataObj
   */
  SaveTask(dataObj): Observable<any> {
    return of([]);
    //TODO
    //  this.http.post<any>(
    //   `${environment.baseUrl}api/GridColumn/SaveModifyColumn`,
    //   dataObj
    // );
  }

  /**Service for get modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }

  /**Service for closed without create task
   * @param dataObj
   */
  closeWithoutCreation(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/CloseWithoutTasks`,
      dataObj
    );
  }

  /**
  * Get create task priority dropdown
  */
  GetSDARecommendedAction(CTLSConfigurationID?, CTLSSDAPriorityID?, CTLSTransType?): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetSDARecommendedAction?CTLSConfigurationID=${CTLSConfigurationID}&&CTLSSDAPriorityID=${CTLSSDAPriorityID}&&CTLSTransType=${CTLSTransType}`
    );
  }

  /**Service for activate and deactivate the survey
   * @param SurveyId
   * @param IsEnable
   */
  activateSurvey(SurveyId, IsEnable) {
    if (IsEnable) {
      return this.http
        .post(
          environment.baseUrl + 'api/RelationshipSurveys/' + SurveyId + '/Disable',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    } else {
      return this.http
        .post(
          environment.baseUrl + 'api/RelationshipSurveys/' + SurveyId + '/Enable',
          {}
        )
        .toPromise()
        .then((res: any) => res)
        .then((data) => {
          return data;
        });
    }
  }
  /**Service to get Historical Data
  * @param dataObj
  */
  GetHistoricalData(dataObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetHistoricalData`, dataObj);
  }
  /**Service to get Historical Data
  * @param dataObj
  */
  SaveHistoricalData(dataObj) {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/SaveHistoricalData`, dataObj);
  }

  /**Service to get Historical Data
* @param dataObj
*/
  GetBase64File(fileID) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetHistoricalDataFile?HistoricalDataFileID=${fileID}`);
  }
  /**Service to delete Historical Data
   * @param dataObj
   */
  DeleteHistoricalData(dataObj) {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/TaskListing/DeleteHistoricalData`, dataObj);
  }
  /**Service to get BA Data
    * @param dataObj
    */
  GetAllBAs() {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetBADetails`);
  }

  /**Service to get FAM Data
  * @param dataObj
  */
  GetAllFAMs(baID) {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetFAMDetails?BAID=${baID}`);
  }
  /**
   * Get create task recommanded action dropdowns
   */
  getAllRecommandedActionMasters(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetTaskRecommendedActions`,
      payload
    );
  }
}
