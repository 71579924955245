import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Rx from 'rxjs';
import { CtlsSharedService } from './ctls-shared.service';
import { CtlsFilterHeaderService } from './ctls-filter-header.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsLostbusinessCustomerService {
  filterObj1 = {
    StartDate: '',
    EndDate: '',
    PeriodType: '',
    BACode: this.globalfilterService.filterObj.BACode,
    FAMCode: this.globalfilterService.filterObj.FAMCode,
    DivCode: this.globalfilterService.filterObj.DivCode,
    BLCode: this.globalfilterService.filterObj.BLCode,
    Country: this.globalfilterService.filterObj.Country,
    BrandName: this.globalfilterService.filterObj.BrandName,
    SalesEngineer: this.globalfilterService.filterObj.SalesEngineer,
    Touchpoint: this.globalfilterService.filterObj.Touchpoint,
    Influencer: this.globalfilterService.filterObj.Influencer,
    ServiceTechnician: this.globalfilterService.filterObj.ServiceTechnician,
    Continent: this.globalfilterService.filterObj.Continent,
    Region: this.globalfilterService.filterObj.Region,
    Zone: this.globalfilterService.filterObj.Zone,
    Branch: this.globalfilterService.filterObj.Branch,
    PinnedField: this.globalfilterService.filterObj.PinnedField,
    CustomerNameCode: this.globalfilterService.filterObj.CustomerNameCode,
    CustomerAccountNumberCode: this.globalfilterService.filterObj
      .CustomerAccountNumberCode,
    Distributors: this.globalfilterService.filterObj.Distributors,
    DistributorSegment: this.globalfilterService.filterObj.DistributorSegment,
    DistributorAccountNumber: this.globalfilterService.filterObj
      .DistributorAccountNumber,
    // 'startBasePeriod':"2016-05-01T18:30:00Z",
    // 'endBasePeriod':"2017-05-01T18:30:00Z",
    startBasePeriod: '',
    endBasePeriod: '',
    CompPeriodStartDate: '',
    CompPeriodEndDate: '',
    FilterBy: null,
    FilterString: '',
    AccountNo: null,
    ViewedBy: null,
  };

  private readonly lostPeriodFilterObject = 'lostPeriodFilterObject';
  // TODO - take from API
  // 
  private serviceUrl = environment.baseUrl;

  constructor(
    private globalfilterService: CtlsFilterHeaderService,
    private http: HttpClient,
    private sharedService: CtlsSharedService,
    private globalFilter: CtlsFilterHeaderService
  ) {
    this.globalFilter.globalFilterBehaviousSubject.subscribe((val) => {
      if (this.globalfilterService.GlobalDateChanged) {
        this.filterObj1['CompPeriodStartDate'] = val['StartDate'];
        this.filterObj1['CompPeriodEndDate'] = val['EndDate'];
      }
    });
  }

  lostCountTextSubject = new Rx.Subject<any>();
  NoDataFoundSubject = new Rx.Subject<any>();
  globalFilterBehaviousSubject = new Rx.BehaviorSubject<any>(this.filterObj1);
  advancedSearchDateFilterSubject = new Rx.BehaviorSubject<any>(null);
  LostCountSubject = new Rx.BehaviorSubject<any>(null);
  lostFilterData = new Rx.BehaviorSubject<any>(null);
  /** API response for updated Lost business data */
  apiResponse = new Rx.BehaviorSubject<any | null>(null);
  /** Date update */
  lostBznesDateUpdate = new Rx.BehaviorSubject<Date | null>(null);

  getAllData(surveySubCategory: string) {
    return this.http
      .get(
          environment.baseUrlDashboard + '/Dashboard/GetMasterFilterData/' +
          surveySubCategory +
          '?' +
          Math.random()
      )
      .toPromise()
      .then((res: any) => res)
      .then((data) => {
        return data;
      });
  }

  saveUserFilter(filterObject, isDate: boolean, surveyType) {
    return this.http
      .post(
          environment.baseUrlDashboard + '/Dashboard/AddUpdateUserFilter/' +
          surveyType +
          '?IsDateParam=' +
          isDate,
        filterObject
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getUserFilter(surveyType): Promise<any> {
    return new Promise((resolve, reject) => {
      let filterData = JSON.parse(
        window.localStorage.getItem('lostFilterObjectRelationship')
      );
      let datefilterData = JSON.parse(
        window.localStorage.getItem('lostPeriodFilterObject')
      );

      if (!filterData) {
        this.http
          .get(
              environment.baseUrlDashboard + '/Dashboard/GetUserFilter/' +
              surveyType +
              '?' +
              Math.random()
          )
          .toPromise()
          .then((res: any) => {
            if (
              res != null &&
              res.Status == 'Success' &&
              res.Response != null
            ) {
              // var filterObjectString = localStorage.getItem("lostFilterObjectRelationship");
              // if (filterObjectString == null) {
              this.mapFilterObject(res.Response, true);
              localStorage.setItem(
                'lostFilterObjectRelationship',
                JSON.stringify(res.Response)
              );
              // }
            }

            return resolve(res);
          })
          .catch((e) => {
            return reject({});
          });
      }
      // else if(!datefilterData){
      //   var basefilterObjectStringDate = localStorage.getItem("lostBasePeriodFilterObject");
      //   if (basefilterObjectStringDate != null) {
      //     var dateFilters = JSON.parse(basefilterObjectStringDate);
      //     console.log(dateFilters,"dateFilters");
      //     this.filterObj1.startBasePeriod = dateFilters.startBasePeriod;
      //     this.filterObj1.endBasePeriod = dateFilters.endBasePeriod;
      //     }
      //     //this.mapBaseFilterObject(filterData, false);
      //     this.mapFilterObject(filterData, false);
      //     return resolve(this.filterObj1);
      // }
      else {
        var filterObjectStringDate = localStorage.getItem(
          'lostPeriodFilterObject'
        );
        var basefilterObjectStringDate = localStorage.getItem(
          'lostBasePeriodFilterObject'
        );
        if (filterObjectStringDate != null) {
          var dateFilters = JSON.parse(filterObjectStringDate);
          this.filterObj1.CompPeriodStartDate = dateFilters.StartDate;
          this.filterObj1.CompPeriodEndDate = dateFilters.EndDate;
          this.filterObj1.PeriodType = dateFilters.PeriodType;
        }
        if (basefilterObjectStringDate != null) {
          var dateFilters = JSON.parse(basefilterObjectStringDate);
          this.filterObj1.startBasePeriod = dateFilters.startBasePeriod;
          this.filterObj1.endBasePeriod = dateFilters.endBasePeriod;
        }
        this.mapFilterObject(filterData, false);
        return resolve(this.filterObj1);
      }
    });
  }

  clearUserFilter(surveyType) {
    return this.http
      .post(
        environment.baseUrlDashboard + '/Dashboard/ClearUserFilter/' + surveyType,
        null
      )
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  brandOriginal = [];
  //   engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  distributorSegmentOriginal = [];
  distributorOriginal = [];
  industrySegmentOriginal = [];
  mapFilterObject(obj, includeDateFields) {
    if (includeDateFields) {
      this.filterObj1.CompPeriodStartDate =
        obj.StartDate == null ? '' : obj.StartDate;
      this.filterObj1.CompPeriodEndDate =
        obj.EndDate == null ? '' : obj.EndDate;
      this.filterObj1.PeriodType = obj.PeriodType == null ? '' : obj.PeriodType;
      this.filterObj1.startBasePeriod =
        obj.startBasePeriod == null ? '' : obj.startBasePeriod;
      this.filterObj1.endBasePeriod =
        obj.endBasePeriod == null ? '' : obj.endBasePeriod;
      var dateObject = {
        StartDate: obj.StartDate == null ? '' : obj.StartDate,
        EndDate: obj.EndDate == null ? '' : obj.EndDate,
        PeriodType: obj.PeriodType == null ? '' : obj.PeriodType,
        startBasePeriod: obj.startBasePeriod == null ? '' : obj.startBasePeriod,
        endBasePeriod: obj.endBasePeriod == null ? '' : obj.endBasePeriod,
      };
      localStorage.setItem(
        'lostPeriodFilterObject',
        JSON.stringify(dateObject)
      );
      localStorage.setItem(
        'lostBasePeriodFilterObject',
        JSON.stringify(dateObject)
      );
    }
  }

  mapBaseFilterObject(obj, includeDateFields) {
    if (includeDateFields) {
      this.filterObj1.startBasePeriod =
        obj.startBasePeriod == null ? '' : obj.startBasePeriod;
      this.filterObj1.endBasePeriod =
        obj.endBasePeriod == null ? '' : obj.endBasePeriod;
      var dateObject = {
        startBasePeriod: obj.startBasePeriod == null ? '' : obj.startBasePeriod,
        endBasePeriod: obj.endBasePeriod == null ? '' : obj.endBasePeriod,
      };
      localStorage.setItem(
        'lostBasePeriodFilterObject',
        JSON.stringify(dateObject)
      );
    }
  }

  updateFilterObject(): any {
    var filterObjectString = localStorage.getItem(
      'lostFilterObjectRelationship'
    );
    var periodFilterString = localStorage.getItem('lostPeriodFilterObject');
    var basePeriodFilterstring = localStorage.getItem(
      'lostBasePeriodFilterObject'
    );

    if (filterObjectString != null) {
      var obj = JSON.parse(filterObjectString);
      this.mapFilterObject(obj, false);
    }
    if (periodFilterString != null) {
      var obj = JSON.parse(periodFilterString);
      this.filterObj1.CompPeriodStartDate = obj.StartDate;
      this.filterObj1.CompPeriodEndDate = obj.EndDate;
      this.filterObj1.PeriodType = obj.PeriodType;
      // this.filterObj1.startBasePeriod = obj.startBasePeriod;
      // this.filterObj1.endBasePeriod = obj.endBasePeriod;
    }
    if (basePeriodFilterstring != null) {
      var obj = JSON.parse(basePeriodFilterstring);
      this.filterObj1.startBasePeriod = obj.startBasePeriod;
      this.filterObj1.endBasePeriod = obj.endBasePeriod;
    }
    this.globalFilterBehaviousSubject.next(this.filterObj1);
  }

  updateLostBuznesDates() {
    this.lostBznesDateUpdate.next(new Date());
  }

  // saveFilterDate(startDate, endDate, periodType, CallFrom, ActionType) {
  //   let filterObject = {
  //     "StartDate": startDate,
  //     "EndDate": endDate,
  //     "PeriodType": periodType,
  //     CallFrom: CallFrom,
  //     ActionType: ActionType
  //   };
  //   this.updateFilterObject();
  //   this.filterObj1.FilterBy = '';
  //   this.filterObj1.FilterString = null;
  //   this.filterObj1.AccountNo = null;
  //   localStorage.setItem("lostPeriodFilterObject", JSON.stringify(filterObject));
  //   this.LostCountSubject.next(this.filterObj1);
  // }

  GetGlobalFilter(passDateToLostComponent) {
    this.lostFilterData.next(passDateToLostComponent);
  }
}
