import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsAddEditSDAService {

  constructor(private http: HttpClient) {
  }
  
  GetPriority(surveyType,SDAID): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetManualTaskFeedbackMasters?surveyType=${surveyType}&&CTLSSDAMasterID=${SDAID}`
    );
  }
  GetSurveyMappingDropdown(data): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/SDA/GetDropDownList`, data);
  }
  SaveSurveyMapping(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/SDA/SaveSDADetails`,
      dataObj
    );
  }
  validateSurveyMapping(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/SDA/SurveyMappingValidation`,
      dataObj
    );
  }
  deleteSurveyMapping(data): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/SDA/DeleteSurveyMapping`,
      data
    );
  }

  /**Service for getting GetSDADetailsID
  * @param id
  */
  GetSDADetailsID(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/SDA/GetSDADetailsById?CTLSSDAMasterID=${id}`
    );
  }
  GetOptions(value): Observable<any> {
    if (value === 1) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetTransactionalSurveys`
      );
    } else if (value === 2) {
      return this.http.get<any>(
        `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetRelationshipSurveys`
      );
    }
  }
}
