import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsUsageManagementService {
    private ctlsServiceUrl = environment.baseUrlCTLS;
    constructor(private http: HttpClient) {
    }
    getData() {
        return this.http.get<any>(
            `${environment.baseUrlCTLS}/Master/GetUsageManagement`
        );
    }
    save(dataObj){
        return this.http.post<any>(
            `${environment.baseUrlCTLS}/Master/SaveUsageManagement`,
            dataObj
        );
    }

}