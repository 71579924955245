import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsCommonFilterService {
  private masterData$ = new BehaviorSubject<any>({});
  private blnSuperUser$ = new BehaviorSubject<any>(false);
  private filterFields$ = new BehaviorSubject<any>({});
  constructor(
    private http: HttpClient,
  ) {
  }
  setMasterData() {
    this.http.get<any>(
      environment.baseUrlCTLS + '/BusinessFilters/GetMasterFilter'
    )
      .pipe(
        take(1),
        tap(response => {
          // notify all subscribers of new data
          if (!!response) {

            this.masterData$.next({ ...response });
            localStorage.setItem('MasterData', JSON.stringify({
              FamBADivBLFilterModel: [...response.FamBADivBLFilterModel],
              FamCountryContiRegionFilterModel: [...response.FamCountryContiRegionFilterModel],
              FamBABrandFilterModel: [...response.FamBABrandFilterModel],
              FamBADivZoneBranchFilterModel: [...response.FamBADivZoneBranchFilterModel]
            }));
          }
        })
      ).subscribe();
  }
  setUserData() {
    this.http.get<any>(
      environment.baseUrlCTLS + '/Master/CheckUserRole'
    )
      .pipe(
        take(1),
        tap(resp => {
          // notify all subscribers of new data
          if (resp.Status == 'SUCCESS') {
            this.blnSuperUser$.next(resp.Response);
          }
        })
      ).subscribe();
  }
  setFilterFields(data) {
    this.filterFields$.next(data);
  }
/*   setGlobalEntityId() {
    this.http.get<any>(
      environment.baseUrl + 'api/Customer360/GetTopGlobalAccountDetails'
    )
      .pipe(
        take(1),
        tap(resp => {
          // notify all subscribers of new data
          if (resp.Status == 'SUCCESS') {
            this.globalEntity$.next(resp.Response);
          }
        })
      ).subscribe();
  } */
  getMasterData(): Observable<boolean> {
    return this.masterData$.asObservable();
  }
  getSuperUserData(): Observable<boolean> {
    return this.blnSuperUser$.asObservable();
  }
  getFilterFields(): Observable<boolean> {
    return this.filterFields$.asObservable();
  }
  getTaskType() {
    return this.http.get<any>(`${environment.baseUrlCTLS}/TaskListing/GetTaskTrendchartFilter`);
  }
/*   getGlobalEntityId(): Observable<boolean> {
    return this.globalEntity$.asObservable();
  } */
}
