import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DDState } from '../chartlisting.component/models/chart-binding.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {
  refreshWidget$ = new Subject<any>();

  
  public drilldownSubject: BehaviorSubject<any> = new BehaviorSubject<any>({
    showGrid: false,
    status: null,
    offsetX: null,
    offsetY: null
  });
  
  private filterObj = {
    "StartDate": "2018-12-01",
    "EndDate": "2019-06-02",
    "UserID": 2,
    "PeriodType": "",
    "BACode": "",
    "FAMCode": "",
    "DivCode": "",
    "BLCode": "",
    "Country": "",
    "BrandName": "",
    "SalesEngineer": "",
    "Touchpoint": "",
    "ServiceTechnician": "",
    "ProjectManager":"",
    "Continent": "",
    "Region": "",
    "Zone": "",
    "Branch": "",
    "NPSScore": ""
  }

  public drillDownState: DDState = new DDState();
  public l2DrillableConfig = ['zone', 'branch'];
  public otherDDConfig = ['zone', 'branch', 'engineer', 'technician', 'operationalcountry','ProjectManager'];

  constructor(private http: HttpClient) {
    // this.drillDownState.setDropDownViewState(DD_STATE.L1);
  }

  getNpsTrendData(filterObj: any | null) {
    if (!filterObj)
      filterObj = this.filterObj;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetNPSTrend', filterObj)
  }
  getNpsRollingTrendData(filterObj: any | null) {
    if (!filterObj)
      filterObj = this.filterObj;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetNPSRollingTrend', filterObj)
  }

  getRRTrendData(filterObj: any | null, optionalReq: any = {}) {
    if(!filterObj)
      filterObj = this.filterObj;
    if (!(Object.keys(optionalReq) && Object.keys(optionalReq).length)) {
      return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRateTrend', filterObj)
    } else {
      return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRateTrend', {...filterObj, ...optionalReq})
    }
  }
  getRRRollingTrendData(filterObj: any | null) {
    if (!filterObj)
      filterObj = this.filterObj;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRateRollingTrend', filterObj)
  }

  // Surveys Sent vs Responses Received
  getSurveySentResponseRecievedChart(filterObj: any | null) {
    if (!filterObj)
      filterObj = this.filterObj;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSurveySentResponseRecievedChart', filterObj)
  }
     getSurveySentResponseRecievedTrendChart(filterObj: any | null) {
    if (!filterObj)
      filterObj = this.filterObj;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSurveySentResponseRecievedTrendChart', filterObj)
  }

  getPNDComparison(filterObj: any | null, compareTo: string, compareWith?: string,
    entityId?: number, isDrilldown?: boolean, l1DrillDown?: number, l2DrillDown?: number, l3DrillDown?: number) {
    if (!filterObj) {
      filterObj = this.filterObj;
    }
    let endpoint: string, ddparam1: string = '', ddparam2: string = '', ddparam3: string = '';
    if (isDrilldown) {
        if (l1DrillDown && l1DrillDown !== null) {
          ddparam1 = '/' + l1DrillDown;
        }
        if (l2DrillDown && l2DrillDown !== null) {
          ddparam2 = '/' + l2DrillDown;
        }
        if (l3DrillDown && l3DrillDown !== null) {
          ddparam3 = '/' + l3DrillDown;
        }
      endpoint = environment.baseUrlDashboard + '/Dashboard/GetPNDComparison/' + compareTo + '/' + entityId + '/drilldown/' + compareWith + ddparam1 + ddparam2 + ddparam3;
    } else {
      endpoint = environment.baseUrlDashboard + '/Dashboard/GetPNDComparison/' + compareTo;
    }

    return this.http.post(endpoint, filterObj)
  }

  getPNDRollingComparison(filterObj: any | null, compareTo: string, compareWith?: string,
    entityId?: number, isDrilldown?: boolean, l1DrillDown?: number, l2DrillDown?: number, l3DrillDown?: number) {
    if (!filterObj)
      filterObj = this.filterObj;

    let endpoint: string, ddparam1: string = '', ddparam2: string = '', ddparam3: string = '';
    if (isDrilldown) {
        if (l1DrillDown && l1DrillDown !== null) {
          ddparam1 = '/' + l1DrillDown;
        }
        if (l2DrillDown && l2DrillDown !== null) {
          ddparam2 = '/' + l2DrillDown;
        }
        if (l3DrillDown && l3DrillDown !== null) {
          ddparam3 = '/' + l3DrillDown;
        }
      endpoint = environment.baseUrlDashboard + '/Dashboard/GetPNDRollingComparison/' + compareTo + '/' + entityId + '/drilldown/' + compareWith + ddparam1 + ddparam2 + ddparam3;
    } else {
      endpoint = environment.baseUrlDashboard + '/Dashboard/GetPNDRollingComparison/' + compareTo;
    }

    return this.http.post(endpoint, filterObj)
  }

  getNoResponsebyRevenueData(filterObj: any | null) {
    if (!filterObj)
      filterObj = this.filterObj;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetNoResponsebyRevenue', filterObj)
  }
  setRefreshWidgetData(data) {
    this.refreshWidget$.next({...data});
  }
  getRefreshWidget() {
    return this.refreshWidget$.asObservable();
  }
  getRRTrendDataEmailActivity(filterObj: any | null, optionalReq: any = {}){
    if(!filterObj)
      filterObj = this.filterObj;
    if (!(Object.keys(optionalReq) && Object.keys(optionalReq).length)) {
      return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRateTrendEmailActivity' , filterObj)
    } else {
      return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRateTrendEmailActivity', {...filterObj, ...optionalReq})
    }
  }
  getRSCChartData(filterObj: any | null, npsFilter: any) {
    if (!filterObj)
      filterObj = this.filterObj;
    const npsScore = npsFilter.length > 0 ? npsFilter.map(item => item).join('|') : '';
    filterObj = { ...filterObj, NPSScore: npsScore };
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSentimentalAnalysisBubbleChart', filterObj)
  }
  getGcFeedback(filterObj: any | null, npsFilter: any) {
    if (!filterObj)
      filterObj = this.filterObj;
    const npsScore = npsFilter.length > 0 ? npsFilter.map(item => item).join('|') : '';
    filterObj = { ...filterObj, NPSScore: npsScore };
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSentimentalAnalysisGaugeChart', filterObj)
  }
  getSpiderWebchartData(filterObj: any | null, npsFilter: any) {
    if (!filterObj)
      filterObj = this.filterObj;
    const npsScore = npsFilter.length > 0 ? npsFilter.map(item => item).join('|') : '';
    filterObj = { ...filterObj, NPSScore: npsScore };
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSentimentalAnalysisSpiderChart', filterObj)
  }

  getwordCloud(filterObj: any | null,npsFilter: any) {
    if (!filterObj)
      filterObj = this.filterObj;
    const npsScore = npsFilter.length > 0 ? npsFilter.map(item => item).join('|') : '';
    filterObj = { ...filterObj, NPSScore: npsScore };
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSentimentalAnalysisWordCloudChart', filterObj)
  }

  getDualChart(filterObj: any | null,npsFilter: any) {
    if (!filterObj)
      filterObj = this.filterObj;
    const npsScore = npsFilter.length > 0 ? npsFilter.map(item => item).join('|') : '';
    filterObj = { ...filterObj, NPSScore: npsScore };
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetSentimentalAnalysisBarChart', filterObj)
  }


  
}
