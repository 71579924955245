import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FamMaster } from '../model/business-rule.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FamMasterService {

  public salesEngineersFilter: any = {};
  public projectManagersFilter: any = {};
  public serviceEngineersFilter: any = {};

  recordCount: number;

  constructor(private http: HttpClient) { this.recordCount = 0 }

  getFamMaster(page, size, sortField, sortType, filterBy) {

    return this.http.get(environment.baseUrlMDM + "/MDM/FAMMaster?pageNo=" + page + "&pageSize=" + size + "&sortField=" + sortField + "&sortType=" + sortType + "&filterBy=" + filterBy + "&buster=" + new Date().getTime())
      .toPromise()
      .then((res: FamMaster) => res)
      .then(data => {
        return data;
      });
  }
  AddFAMDivBL(data) {

    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/DivBlMapping", data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  AddBrandLanguage(data) {
    return this.http.post(environment.baseUrlMDM + '/MDM/FAMManagement/BrandsLanguages', data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  AddOtherDetails(data) {
    return this.http.post(environment.baseUrlMDM + '/MDM/FAMManagement/OtherDetails', data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  AddFamMaster(data) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMMaster/Add", data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      })
  }

  GetFAMMasterDataModel() {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMMaster/DropDownMasterData" + "?buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      })
  }
  getFAMMasterByID(id) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMMaster/" + id + "?buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  getDetails(FamId, BaId) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/Details?FAMId=" + FamId + " &BAId=" + BaId + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }
  //export FamData
  exportData(page, size, sortField, sortType, filterBy) {

    return this.http.get(environment.baseUrlMDM + '/MDM/FamMaster/Export?' + "pageNo=" + page + "&pageSize=" + size + "&sortField=" + sortField + "&sortType=" + sortType + "&filterBy=" + filterBy + "&buster=" + new Date().getTime(), { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  updateFamMaster(famMasterData) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMMaster/Update", famMasterData).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })

  }

  GetFamBaAuthorithy(famId, baId, pageNo, pageSize, sortField, sortType) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/Authorities?famId=" + famId + "&baId=" + baId + "&pageno=" + pageNo + "&pagesize=" + pageSize + "&sortfield=" + sortField + "&sorttype=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFamBaAuthorityID(AuthorityId) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/Authorities/" + AuthorityId + "?buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  SaveFamBaAuthority(authorityData) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/Authorities", authorityData).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAZoneBranchMapping(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/ZoneBranchMappings?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAServiceTechniciansDetails(famId, baId, pageNo, pageSize, sortField, sortType) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/ServiceTechnicians?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBASalesEngineersDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/SalesEngineers?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAProjectManagersDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/ProjectManagers?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetFAMBAZoneDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/Zones?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  GetViewSaleChannelException(pageNo,pageSize,sortType,sortField,FAMID,BAID){

    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/ViewSaleChannel?pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortfield=" + sortField + "&sortType=" + sortType + "&FAMID=" + FAMID + "&BAID=" + BAID+ "&buster=" + new Date().getTime()).
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    }).catch(error => {
      return error;
    })
  }

  GetViewServiceTypeException(pageNo,pageSize,sortType,sortField,FAMID,BAID){

    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/ViewServiceTypeException?pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortfield=" + sortField + "&sortType=" + sortType + "&FAMID=" + FAMID + "&BAID=" + BAID+ "&buster=" + new Date().getTime()).
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    }).catch(error => {
      return error;
    })
  }

  SaveFAMBAZone(model, Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/Zones/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAZone(Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/Zones/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  GetFAMBABranchDetails(famId, baId, pageNo, pageSize, sortType, sortField) {
    return this.http.get(environment.baseUrlMDM + "/MDM/FAMManagement/Branches?famId=" + famId + "&baId=" + baId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortField=" + sortField + "&sortType=" + sortType + "&buster=" + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      })
  }

  SaveEditAuthoritySignature(data) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/AuthoritySignature", data).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFamBaAuthority(authorityID) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/Authorities/" + authorityID + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })

  }

  SaveFAMBABranch(model, Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/Branches/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBABranch(Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/Branches/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBAZoneBranchMapping(model) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ZoneBranchMappings/", model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  EditFAMBAZoneBranchMapping(model) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ZoneBranchMappings/Edit", model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAZoneBranchMapping(famId, baId, divId, zoneId) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ZoneBranchMappings/Delete?famId=" + famId + "&baId=" + baId + "&divId=" + divId + "&zoneId=" + zoneId, null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBASalesEngineer(model, Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/SalesEngineers/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBAProjectManager(model, Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ProjectManagers/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBASalesEngineers(Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/SalesEngineers/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAProjectManagers(Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ProjectManagers/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  SaveFAMBAServiceTechnician(model, Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ServiceTechnicians/" + Id, model).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  DeleteFAMBAServiceTechnicians(Id) {
    return this.http.post(environment.baseUrlMDM + "/MDM/FAMManagement/ServiceTechnicians/" + Id + "/Delete", null).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data
      }).catch(error => {
        return error;
      })
  }

  exportZonesToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportZones?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportBranchesToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportBranches?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportZoneBranchMappingToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportZoneBranchMapping?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportZoneAndBranchesToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportZoneAndBranchesToExcel?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportSalesEngineerToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportSalesEngineer?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportProjectManagerToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportProjectManager?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportServiceTechnicianToExcel(pageNo, pageSize, SortField, SortType, famId, baId, filterText) {
    famId = parseInt(famId);
    return this.http.post(environment.baseUrlMDM + '/MDM/ExportServiceTechnician?pageNo=' + pageNo + "&pageSize=" + pageSize + "&SortField=" + SortField + "&SortType=" + SortType + "&famId=" + famId + "&baId=" + baId + "&filterText=" + filterText, null, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
}
