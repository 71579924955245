import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ConfirmationService, DialogService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/api';
import { SharedService } from '../app/shared/services/shared.service';
import { HttpCancelService } from './shared/services/http-cancel.service';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { StartupComponent } from './startup/startup.component';
import { SignoutCallbackOidcComponent } from './signout-callback-oidc/signout-callback-oidc.component';
import { AuthenticationModule } from './Modules/authentication/authentication.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HttpErrorInterceptor } from './errorHandling/http-error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AdvancedSearchComponent } from './Modules/Distributor_Survey/components/advanced-search/advanced-search.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { UserProfileComponent } from './components/profile/user-profile/user-profile.component';
import { DynamicTooltipInfluncerDirective } from './dynamic-tooltip/dynamic.tooltipInfluncer';
import { DynamicTooltipDistributorMasterDirective } from './dynamic-tooltip/dynamicTooltipDistributorMaster';
import { ResponsiveSliderDirective } from './dynamic-tooltip/responsive-slider-width';
import { AccessDeniedComponent } from './shared/components/Errors/access-denied/access-denied.component';
import { UnexpectedErrorComponent } from './shared/components/Errors/unexpected-error/unexpected-error.component';
import { HeaderCtlsComponent } from './shared/components/header-ctls/header-ctls.component';
import { HeaderFeedBackZoneComponent } from './shared/components/header-feedback-zone/header-feedback-zone.component';
import { HeaderUlItemComponent } from './shared/components/header-ul-item/header-ul-item.component';
import { HeaderUlLiItemComponent } from './shared/components/header-ul-li-item/header-ul-li-item.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { TopmenuComponent } from './shared/components/topmenu/topmenu.component';
import { UserFeedbackComponent } from './shared/components/user-feedback/user-feedback.component';
import { OnlineHelpDirective } from './shared/directives/online-help.directive';
import { TwoDigitDecimaNumberDirective } from './shared/directives/two-digit-decimal-number.directive';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { SharedModule, ToggleButtonModule } from 'primeng/primeng';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { CtlsSharedModule } from './Modules/ctls/ctls-shared/ctls-shared.module';
import { HeatMapModule } from './Modules/ctls/ctls-shared/heat-map/heat-map.module';
import { FalconSharedModule } from './Modules/falcon/falcon-shared/falcon-shared.module';
import { PrimengModule } from './primeng.module';
import { CurrencyMasterService } from './Modules/currency-master/Services/currency-master.service';
import { RevenueReportService } from './Modules/revenue-report/services/revenue-report.service';
import { ChartDataService } from './components/dashboard/service/chart-data-service.service';
import { FamMasterService } from './components/fam-management/services/fam-master.service';
import { CreateSurveyService } from './components/surveys/survey-library/services/create-survey.service';
import { DynamicComponentService } from './shared/services/dynamic-component.service';
import { ExportChartDataService } from './shared/services/export-chart-data.service';
import { ExportChartsService } from './shared/services/export-charts.service';

const appInitializerFn = (appConfig: SharedService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    SigninOidcComponent,
    StartupComponent,
    SignoutCallbackOidcComponent,
    HeaderComponent,
    HeaderUlItemComponent,
    HeaderUlLiItemComponent,
    HeaderFeedBackZoneComponent,
    HeaderCtlsComponent,
    TopmenuComponent,
    DynamicTooltipInfluncerDirective,
    ResponsiveSliderDirective,
    UserProfileComponent,
    AccessDeniedComponent,
    UnexpectedErrorComponent,
    UserFeedbackComponent,
    OnlineHelpDirective,
    DynamicTooltipDistributorMasterDirective,
    TwoDigitDecimaNumberDirective,
    AdvancedSearchComponent,
    NotificationComponent,
    MaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AuthenticationModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //BrowserAnimationsModule,
    //DashboardModule,
    ChartModule,
    // SurveyDesignModule,
    SharedModule,
    TableModule,
    PaginatorModule,
    DropdownModule,
    TooltipModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    ConfirmDialogModule,
    ScrollPanelModule,
    PrimengModule,
    MultiSelectModule,
    TreeModule,
    TreeTableModule,
    //CtlsModule,
    CtlsSharedModule,
    ToggleButtonModule,
    //FalconModule,
    FalconSharedModule,
    HeatMapModule,
    OAuthModule.forRoot(),
    MsalModule.forRoot({
      auth: {
        clientId: environment.activeDirectory.config.auth.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: environment.activeDirectory.config.auth.authority,
        validateAuthority: true,
        redirectUri: environment.loginUrl,
        postLogoutRedirectUri: environment.loginUrl,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        popUp: !isIE,
        consentScopes: [
          "user.read",
          "openid",
          "profile",
          "api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user"
        ],
        unprotectedResources: ["https://www.microsoft.com/en-us/"],
        protectedResourceMap,
        extraQueryParameters: {}
      }
    )
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
    HttpCancelService,
    SharedService,
    FamMasterService,
    ChartDataService,
    CreateSurveyService, 
    ConfirmationService,
    DynamicComponentService,
    ExportChartsService,
    ExportChartDataService,
    Title,
    CurrencyMasterService,
    RevenueReportService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [SharedService]
    },
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true 
    }
  ],
  entryComponents: [
    UserFeedbackComponent,
    AdvancedSearchComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
